import { Fade, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

const ControlledRadioGroup = (props) => {
  const { dataSet, id, value, handleChange, name, grouLabel, radioGroupProps, direction } = props;
  return (
    <FormControl id={id && id + '-control'}>
      <Fade in={!!grouLabel} unmountOnExit>
        <FormLabel id={id && id + '-label'}>{grouLabel}</FormLabel>
      </Fade>
      <RadioGroup
        aria-labelledby={id && id + '-label'}
        name={name ? name : id && id + '-name'}
        value={value}
        onChange={handleChange}
        row={direction === 'row'}
        {...(radioGroupProps || {})}
      >
        {!!dataSet?.map &&
          dataSet.map((radioOption, optionIndex) => (
            <FormControlLabel
              key={
                id
                  ? id + '-key-' + optionIndex
                  : 'radio-' + optionIndex + '-name-' + radioOption?.label
              }
              value={radioOption?.value}
              control={<Radio />}
              label={radioOption?.label}
              disabled={radioOption?.isDisabled}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

ControlledRadioGroup.defaultProps = {
  direction: 'row'
}

export default ControlledRadioGroup;
