import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import Box from '@mui/material/Box';

export const handleValueChange = (name, setFieldValue, setFieldTouched) => (val) => {
  setFieldTouched(name, true);
  setFieldValue(name, val.floatValue);
};

const CurrencyFieldText = ({ currencySymbol, ...props }) => {
  const [displayValue, setDisplayValue] = useState();
  return (
    <Box marginTop={1} width={'100%'}>
      <NumberFormat
        customInput={TextField}
        variant="outlined"
        isNumericString={true}
        thousandSeparator={true}
        value={displayValue}
        decimalScale={2}
        onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
        InputProps={{
          startAdornment: <span>{currencySymbol}</span>,
        }}
        fullWidth
        {...props}
      />
    </Box>
  );
};

CurrencyFieldText.defaultProps = {
  currencySymbol: '$',
};

export default CurrencyFieldText;
