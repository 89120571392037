import { Collapse, Grid, Grow, Paper, Typography } from '@mui/material';
import CurrencyFieldATM from 'common/formik/CurrencyFieldATM';
import CurrencyFieldText, { handleValueChange } from 'common/formik/CurrencyFieldText';
import FormikDatePicker from 'common/formik/FormikDatePicker';
import FormikRadioGroup from 'common/formik/FormikRadioGroup';
import FormikTextField from 'common/formik/FormikTextField';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';
// Decimal
// Percentage
// Amount
// Date
// Boolean
const MultiFormatAnswer = (props) => {
  const { questionData, questionnaireIndex, questionType, handleFieldBlur } = props;
  const answers =
    questionData?.answer?.[questionType === 'CLIENT' ? 'client' : 'partner']
      ?.multipleFormatAnswers || [];

  const formikContext = useFormikContext();
  return (
    <Collapse
      unmountOnExit
      sx={{ width: '100%' }}
      in={questionData?.fieldType === 'multipleFormat'}
    >
      <Grow in={questionData?.fieldType === 'multipleFormat'}>
        <Grid item xs={12}>
          <Paper sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Grid container spacing={gridSpacing}>
              {answers.map((answerDetail, answerIndex) => (
                <Grid item xs={12} key={`${answerIndex}-multi-format-answer-field-wrapper`}>
                  {answerDetail?.type === 'Text' ? (
                    <FormikTextField
                      name={`[${questionnaireIndex}].answer.[${
                        questionType === 'CLIENT' ? 'client' : 'partner'
                      }].multipleFormatAnswers[${answerIndex}].value`}
                      type="text"
                      label={answerDetail?.label}
                      placeholder={answerDetail?.label}
                      required={!!questionData?.isRequired}
                      fullWidth
                      disabled={formikContext?.isSubmitting}
                    />
                  ) : answerDetail?.type === 'Decimal' ? (
                    <FormikTextField
                      name={`[${questionnaireIndex}].answer.[${
                        questionType === 'CLIENT' ? 'client' : 'partner'
                      }].multipleFormatAnswers[${answerIndex}].value`}
                      type="number"
                      label={answerDetail?.label}
                      placeholder={answerDetail?.label}
                      required={!!questionData?.isRequired}
                      fullWidth
                      disabled={formikContext?.isSubmitting}
                    />
                  ) : answerDetail?.type === 'Percentage' ? (
                    <CurrencyFieldATM
                      label={answerDetail?.label}
                      placeholder={answerDetail?.label}
                      onChange={formikContext.handleChange}
                      name={`[${questionnaireIndex}].answer.[${
                        questionType === 'CLIENT' ? 'client' : 'partner'
                      }].multipleFormatAnswers[${answerIndex}].value`}
                      value={
                        formikContext.values?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value
                      }
                      error={
                        formikContext.errors?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value &&
                        formikContext.touched?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value
                      }
                      helperText={
                        formikContext.touched?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value &&
                        formikContext.errors?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value
                      }
                      disabled={formikContext?.isSubmitting}
                    />
                  ) : answerDetail?.type === 'Amount' ? (
                    <CurrencyFieldText
                      label={answerDetail?.label}
                      placeholder={answerDetail?.label}
                      name={`[${questionnaireIndex}].answer.[${
                        questionType === 'CLIENT' ? 'client' : 'partner'
                      }].multipleFormatAnswers[${answerIndex}].value`}
                      value={
                        formikContext.values?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value
                      }
                      error={
                        formikContext.errors?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value &&
                        formikContext.touched?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value
                      }
                      helperText={
                        formikContext.touched?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value &&
                        formikContext.errors?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value
                      }
                      onValueChange={handleValueChange(
                        `[${questionnaireIndex}].answer.[${
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        }].multipleFormatAnswers[${answerIndex}].value`,
                        formikContext.setFieldValue,
                        formikContext.setFieldTouched
                      )}
                      disabled={formikContext?.isSubmitting}
                    />
                  ) : answerDetail?.type === 'Date' ? (
                    <FormikDatePicker
                      name={`[${questionnaireIndex}].answer.[${
                        questionType === 'CLIENT' ? 'client' : 'partner'
                      }].multipleFormatAnswers[${answerIndex}].value`}
                      label={answerDetail?.label}
                      value={
                        formikContext.values?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value
                      }
                      setFieldValue={formikContext.setFieldValue}
                      error={
                        formikContext.errors?.[questionnaireIndex]?.answer?.[
                          questionType === 'CLIENT' ? 'client' : 'partner'
                        ]?.multipleFormatAnswers?.[answerIndex]?.value
                      }
                      disabled={formikContext?.isSubmitting}
                    />
                  ) : answerDetail?.type === 'Boolean' ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          sx={(theme) => ({
                            color: theme.palette.primary?.dark,
                          })}
                        >
                          {answerDetail?.label}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormikRadioGroup
                          name={`[${questionnaireIndex}].answer.[${
                            questionType === 'CLIENT' ? 'client' : 'partner'
                          }].multipleFormatAnswers[${answerIndex}].value`}
                          label={answerDetail?.label}
                          dataSet={[
                            {
                              value: true,
                              label: 'Yes',
                            },
                            {
                              value: false,
                              label: 'No',
                            },
                          ]}
                          row={true}
                          direction="row"
                          controlMargin="0 inherit !"
                          disabled={formikContext.isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grow>
    </Collapse>
  );
};

export default MultiFormatAnswer;
