import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Handle Generate URL for Upload
 * @param {object} requestBody
 * @param {Function} handleGenerateURLSuccessCallback
 * @param {Function} handleGenerateURLFailedCallback
 * @returns
 */
export const requestGenerateSignedURLForUpload =
  (requestBody, handleGenerateURLSuccessCallback, handleGenerateURLFailedCallback) =>
    async (dispatch, getState) => {
      dispatch({ type: 'postGenerateSignedURLForUploadRequest' });

      const requestData = {
        url: API_URL.files.POST_GENERATE_SIGNED_URL,
        method: 'POST',
        data: requestBody,
      };
      const successCallback = (response) => {
        dispatch({ type: 'postGenerateSignedURLForUploadSuccess', payload: response.data });
        handleGenerateURLSuccessCallback && handleGenerateURLSuccessCallback(response);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'postGenerateSignedURLForUploadFailed', payload: error });
        handleGenerateURLFailedCallback && handleGenerateURLFailedCallback(error);

        Store.dispatch(handleNotification('Failed to Request for File Upload ! ', 'error'));
      };
      http_request(requestData, successCallback, errorCallback);
    };

/**
 * Upload file request
 * @param {object} requestBody
 * @param {Function} handleSaveLeadSuccessCallback
 * @param {Function} handleSaveLeadFailedCallback
 * @returns
 */
export const requestUploadFile =
  (requestDetail, handleUploadFileSuccessCallback, handleUploadFileFailedCallback) =>
    async (dispatch, getState) => {
      dispatch({ type: 'putUploadFileRequest' });

      const { uploadUrl, requestBody } = requestDetail;

      const requestData = {
        url: uploadUrl,
        method: 'PUT',
        data: requestBody,
      };
      const successCallback = (response) => {
        dispatch({
          type: 'putUploadFileSuccess',
          payload: { ...(response?.data || {}), message: 'Uploaded Successfully !' },
        });
        handleUploadFileSuccessCallback && handleUploadFileSuccessCallback({ ...(requestDetail || {}), ...(response || {}) });

        Store.dispatch(handleNotification('File is Uploaded Successfully !', 'success'));
      };
      const errorCallback = (error) => {
        dispatch({ type: 'putUploadFileFailed', payload: error });
        handleUploadFileFailedCallback && handleUploadFileFailedCallback(error);

        Store.dispatch(handleNotification('File Upload is Failed !', 'error'));
      };
      http_request(requestData, successCallback, errorCallback, {
        headers: {
          'Content-Type': requestBody.type,
        },
        noToken: true,
      });
    };
/**
 * Request get all files sent by sender (Received Files)
 * @param {*} requestData
 * @param {*} handleGetAllFilesReceivedRequestSuccessCallback
 * @param {*} handleGetAllFilesReceivedRequestFailedCallback
 * @returns
 */
export const requestGetAllFilesReceived =
  (
    requestData,
    handleGetAllFilesReceivedRequestSuccessCallback = null,
    handleGetAllFilesReceivedRequestFailedCallback = null
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: 'getAllUploadedReceivedFilesRequest' });

      const { senderId } = requestData;

      const getAllFilesSentRequestData = {
        url: API_URL.files.GET_ALL_RECEIVED_FILES.replace('{senderId}', senderId),
        method: 'GET',
      };
      const successCallback = (response) => {
        dispatch({ type: 'getAllUploadedReceivedFilesRequestSuccess', payload: response?.data });
        handleGetAllFilesReceivedRequestSuccessCallback &&
          handleGetAllFilesReceivedRequestSuccessCallback(response?.data);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'getAllUploadedReceivedFilesRequestFailed', payload: error });
        handleGetAllFilesReceivedRequestFailedCallback &&
          handleGetAllFilesReceivedRequestFailedCallback(error);
      };

      http_request(getAllFilesSentRequestData, successCallback, errorCallback);
    };
/**
 * Request get all files sent by me (Sent Files)
 * @param {*} requestData
 * @param {*} handleGetAllFilesSentRequestSuccessCallback
 * @param {*} handleGetAllFilesSentRequestFailedCallback
 * @returns
 */
export const requestGetAllFilesSent =
  (
    requestData,
    handleGetAllFilesSentRequestSuccessCallback = null,
    handleGetAllFilesSentRequestFailedCallback = null
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: 'getAllUploadedSentFilesRequest' });

      const { receiverId } = requestData;

      const getAllFilesSentRequestData = {
        url: API_URL.files.GET_ALL_SENT_FILES.replace('{receiverId}', receiverId),
        method: 'GET',
      };
      const successCallback = (response) => {
        dispatch({ type: 'getAllUploadedSentFilesRequestSuccess', payload: response?.data });
        handleGetAllFilesSentRequestSuccessCallback &&
          handleGetAllFilesSentRequestSuccessCallback(response?.data);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'getAllUploadedSentFilesRequestFailed', payload: error });
        handleGetAllFilesSentRequestFailedCallback &&
          handleGetAllFilesSentRequestFailedCallback(error);
      };

      http_request(getAllFilesSentRequestData, successCallback, errorCallback);
    };
