//export to reducer/index.js
// import { CONSTANTS } from "../../actions";

import { createReducer } from '@reduxjs/toolkit';

let listID = 20;
let cardID = 50;
const initialState = [
  {
    title: 'Lead',
    id: `list-${0}`,
    cards: [
      {
        id: `card-${0}`,
        text: 'chrisbrown3@gmail.com',
        name: 'aaaa',
        textCard: 'Untought',
        price: '200',
      },
      {
        id: `card-${1}`,
        name: 'bbbbb',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
    ],
  },
  {
    title: 'Form Created',
    id: `list-${1}`,
    cards: [
      {
        id: `card-${2}`,
        name: 'cccc',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
      {
        id: `card-${3}`,
        name: 'dddd',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
      {
        id: `card-${4}`,
        name: 'eee',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
    ],
  },
  {
    title: 'Form Sent',
    id: `list-${2}`,
    cards: [
      {
        id: `card-${5}`,
        name: 'Lakshan Sampath',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
      {
        id: `card-${6}`,
        name: 'Lakshan Sampath',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
    ],
  },
  {
    title: 'Form Viwed',
    id: `list-${3}`,
    cards: [
      {
        id: `card-${7}`,
        name: 'Lakshan Sampath',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
      {
        id: `card-${8}`,
        name: 'Lakshan Sampath',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
    ],
  },
  {
    title: 'Form Completed',
    id: `list-${4}`,
    cards: [
      {
        id: `card-${9}`,
        name: 'Lakshan Sampath',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
      {
        id: `card-${10}`,
        name: 'Lakshan Sampath',
        text: 'chrisbrown3@gmail.com',
        textCard: 'Untought',
        price: '200',
      },
    ],
  },
];

export const digitalDocReducer = createReducer(initialState, {
  DRAG_HAPPENED: (state, action) => {
    const {
      // this coming from listAction
      droppableIdStart,
      droppableIdEnd,
      droppableIndexStart,
      droppableIndexEnd,
      draggableId,
      type,
    } = action.payload;
    const newState = [...state];

    //dragin list around
    if (type === 'list') {
      const list = newState.splice(droppableIndexStart, 1);
      newState.splice(droppableIndexEnd, 0, ...list);

      return newState;
    }

    //in the same list
    if (droppableIdStart === droppableIdEnd) {
      const list = state.find((list) => droppableIdStart === list.id);
      const card = list.cards.splice(droppableIndexStart, 1);
      list.cards.splice(droppableIndexEnd, 0, ...card);
      console.log('55555555555' + droppableIdEnd);
    }

    //in the other list
    if (droppableIdStart !== droppableIdEnd) {
      //find the list where drag happen
      const listStart = state.find((list) => droppableIdStart === list.id);

      //pull out the card from this list

      const card = listStart.cards.splice(droppableIndexStart, 1);

      // find the list where drag ended
      const listEnd = state.find((list) => droppableIdEnd === list.id);

      // put the card in the new list
      listEnd.cards.splice(droppableIndexEnd, 0, ...card);
    }

    return state;
  },
});
