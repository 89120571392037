import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
// const AuthLogin3 = Loadable(
//   lazy(() => import('views/pages/authentication/authentication3/Login3'))
// );
const AuthRegister3 = Loadable(
  lazy(() => import('views/pages/authentication/authentication3/Register3'))
);

const Authentication = Loadable(
  lazy(() => import('views/pages/authentication/authentication3/Authentication'))
);

const ResetPassword = Loadable(
  lazy(() => import('views/pages/authentication/authentication3/ResetPassword'))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <Authentication />,
    },
    {
      path: '/register',
      element: <AuthRegister3 />,
    },
    {
      path: '/password-reset',
      element: <ResetPassword />,
    },
  ],
};

export default AuthenticationRoutes;
