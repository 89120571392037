import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //Create New Lead
  saveGoalRequestLoading: false,
  saveGoalData: {},
  saveGoalFailed: {},

  isGoalsListLoading: false,
  goalsListData: null,
  goalsListFailed: null,

  isGoalUpdateRequestLoading: false,
  goalUpdateRequestSuccessData: {},
  goalUpdateRequestFailedData: {},

  isGoalDeleteRequestLoading: false,
  goalDeleteRequestSuccessData: {},
  goalDeleteRequestFailedData: {},
};

export const goalsReducer = createReducer(initialState, {
  //save goals
  postSaveGoalRequest: (state) => {
    state.saveGoalRequestLoading = true;
    state.saveGoalData = {};
    state.saveGoalFailed = {};
  },
  postSaveGoalSuccess: (state, action) => {
    state.saveGoalRequestLoading = false;
    state.saveGoalData = action.payload;
    state.saveGoalFailed = {};
  },
  postSaveGoalFailed: (state, action) => {
    state.saveGoalRequestLoading = false;
    state.saveGoalData = {};
    state.saveGoalFailed = action.payload;
  },

  //get all goals
  postGetGoalsRequest: (state) => {
    state.isGoalsListLoading = true;
    state.goalsListData = null;
    state.goalsListFailed = {};
  },
  postGetGoalsSuccess: (state, action) => {
    state.isGoalsListLoading = false;
    state.goalsListData = action.payload;
    state.goalsListFailed = {};
  },
  postGetGoalsFailed: (state, action) => {
    state.isGoalsListLoading = false;
    state.goalsListData = null;
    state.goalsListFailed = action.payload;
  },

  //update a goal
  patchUpdateGoalRequest: (state) => {
    state.isGoalUpdateRequestLoading = true;
    state.goalUpdateRequestSuccessData = [];
    state.goalUpdateRequestFailedData = {};
  },
  patchUpdateGoalRequestSuccess: (state, action) => {
    state.isGoalUpdateRequestLoading = false;
    state.goalUpdateRequestSuccessData = action.payload;
    state.goalUpdateRequestFailedData = {};
  },
  patchUpdateGoalRequestFailed: (state, action) => {
    state.isGoalUpdateRequestLoading = false;
    state.goalUpdateRequestSuccessData = [];
    state.goalUpdateRequestFailedData = action.payload;
  },

  //delete a goal

  deleteGoalRequest: (state) => {
    state.isGoalDeleteRequestLoading = true;
    state.goalDeleteRequestSuccessData = [];
    state.goalDeleteRequestFailedData = {};
  },
  deleteGoalRequestSuccess: (state, action) => {
    state.isGoalDeleteRequestLoading = false;
    state.goalDeleteRequestSuccessData = action.payload;
    state.goalDeleteRequestFailedData = {};
  },
  deleteGoalRequestFailed: (state, action) => {
    state.isGoalDeleteRequestLoading = false;
    state.goalDeleteRequestSuccessData = [];
    state.goalDeleteRequestFailedData = action.payload;
  },
});
