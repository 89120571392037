import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';

/**
 * Handle get all admin email templates
 * @param {*} requestSuccessCallback
 * @param {*} requestFailedCallback
 * @returns
 */
export const requestGetAllAdminEmailTemplates =
  (requestSuccessCallback = null, requestFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getAllAdminEmailTemplatesRequest' });

    const requestData = {
      url: API_URL.emailTemplates.GET_ALL_ADMIN_EMAIL_TEMPLATES,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getAllAdminEmailTemplatesRequestSuccess', payload: response.data });
      requestSuccessCallback && requestSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getAllAdminEmailTemplatesRequestFailed', payload: error });
      requestFailedCallback && requestFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
