import React from 'react';
import { Grid, Box, Typography, SvgIcon } from '@mui/material';

import { useFinancialInfoStyle } from '../FinancialInfoStyle';
import MainCard from 'ui-component/cards/MainCard';

const ActionIconButtonCard = (props) => {
  const { isSelected, actionLabel, actionType, actionIcon, handleSelectActionCard, count } = props;
  const { classes: financialInfoClasses } = useFinancialInfoStyle();

  return (
    <MainCard
      elevation={2}
      border
      content
      onClick={(e) => handleSelectActionCard && handleSelectActionCard(actionType)}
      className={
        isSelected
          ? financialInfoClasses?.selectedActionCardWrapper
          : financialInfoClasses?.defaultActionCardWrapper
      }
      contentClass={financialInfoClasses?.actionCardContendWrapper}
      sx={{
        cursor: !isSelected && 'pointer',
      }}
    >
      <Box
        sx={{
          paddingX: 2.25,
          paddingY: 1.5,
          flex: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography
              align="left"
              sx={(theme) => ({
                color: isSelected ? '#fff' : theme.palette.grey?.[825],
                fontWeight: 500,
                fontSize: theme.typography.pxToRem(18),
              })}
            >
              {actionLabel}
            </Typography>
          </Grid>
          {count ? (
            <Grid item xs="auto">
              <Typography
                align="left"
                sx={(theme) => ({
                  color: isSelected ? '#fff' : theme.palette.grey?.[825],
                  fontWeight: 500,
                  fontSize: theme.typography.pxToRem(25),
                })}
              >
                {count}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs="auto">
              <SvgIcon
                viewBox="0 0 32 32"
                sx={(theme) => ({
                  color: isSelected ? '#fff' : theme.palette.blue?.[750],
                })}
                component={actionIcon}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </MainCard>
  );
};
export default ActionIconButtonCard;
