import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import PropTypes from 'prop-types';
import { FormHelperText, Typography } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(option, dropDownValue, theme) {
  return {
    fontWeight:
      dropDownValue.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DropDown = (props) => {
  const {
    optionData,
    isMultiple,
    placeholder,
    dropdownValueKey,
    dropdownValueLabelKey,
    handleDropDownChangeCallback,
    isShrinkLabel,
    formLabel,
    formControlProps,
    ...otherProps
  } = props;
  const theme = useTheme();
  const [dropDownValue, setDropDownValue] = React.useState('');

  return (
    <FormControl
      id={(otherProps?.id || 'demo-multiple-name-formControl') + new Date().getMilliseconds()}
      sx={{ textAlign: 'left' }}
      {...(formControlProps || {})}
    >
      {(otherProps?.label || formLabel) && (
        <InputLabel
          shrink={isShrinkLabel}
          id={
            (otherProps?.id || 'demo-multiple-name-formControlLabel') +
            +new Date().getMilliseconds()
          }
          variant="outlined"
        >
          {otherProps?.label || formLabel}
        </InputLabel>
      )}
      <Select
        labelId={(otherProps?.id || 'demo-multiple-name') + '-label'}
        id={otherProps?.id || 'demo-multiple-name'}
        multiple={isMultiple || false}
        variant="outlined"
        MenuProps={MenuProps}
        native={false}
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return (
              <Typography sx={(theme) => ({ color: theme.palette.text.secondary })}>
                {placeholder || 'Please Select'}
              </Typography>
            );
          } else if (typeof selected === 'object' && typeof selected?.id !== 'undefined') {
            return selected?.[dropdownValueLabelKey || 'label'];
          } else {
            let renderLabel =
              selected &&
              optionData?.find &&
              optionData.find(
                (singleOption) => singleOption?.[dropdownValueKey || 'value'] === selected
              )?.name;
            return renderLabel || '';
          }
        }}
        sx={{ mt: formLabel && '1.25rem' }}
        {...otherProps}
        value={dropDownValue || otherProps?.value || ''} //fixes an issue with mui[initial selection not working]
      >
        {optionData?.map &&
          optionData.map((option, optionIndex) => (
            <MenuItem
              key={otherProps?.id + '' + option?.[dropdownValueKey || 'value']}
              value={option?.[dropdownValueKey || 'value']}
              style={getStyles(option?.[dropdownValueLabelKey || 'name'], dropDownValue, theme)}
            >
              {option?.name}
            </MenuItem>
          ))}
      </Select>
      {otherProps?.helperText && (
        <FormHelperText error={otherProps?.error}>{otherProps?.helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

DropDown.defaultProps = {
  dropDownValue: '',
  isShrinkLabel: true,
  formControlProps: {
    fullWidth: true,
  },
};
DropDown.propTypes = {
  optionData: PropTypes.array,
  isMultiple: PropTypes.bool,
  placeholder: PropTypes.string,
  dropdownValueKey: PropTypes.string,
  dropdownValueLabelKey: PropTypes.string,
  handleDropDownChangeCallback: PropTypes.func,
  isShrinkLabel: PropTypes.any,
  formLabel: PropTypes.string,
  formControlProps: PropTypes.object,
};

export default React.memo(DropDown);
