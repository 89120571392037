import React from 'react';
import PropTypes from 'prop-types';

import ActionButtonCard from './ActionButtonCard';
const TotalAssetsActionCard = (props) => {
  const { selectedTabButton, onSelectTabButton, value } = props;
  return (
    <ActionButtonCard
      actionLabel="Total Assets"
      actionType="assets"
      actionTypeStatistic={value}
      isSelected={selectedTabButton === 'assets'}
      handleSelectActionCard={onSelectTabButton}
    />
  );
};
TotalAssetsActionCard.propTypes = {
  selectedTabButton: PropTypes.string,
  onSelectTabButton: PropTypes.func,
};
export default TotalAssetsActionCard;
