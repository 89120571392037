import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { authenticationReducer } from './Reducers/authenticationReducer';
import { clientDocumentsReducer } from './Reducers/clientDocumentsReducer';
import { customization } from './Reducers/LayoutReducer';
import { notificationReducer } from './Reducers/handleNotificationReducer';
import { modalReducer } from './Reducers/modalReducer';
import { digitalDocReducer } from './Reducers/digitalDocReducer';
import { compositeDataReducer } from './Reducers/compositeDataReducer';
import { clientReducer } from './Reducers/clientReducer';
import { clientAssetReducer } from './Reducers/clientAssetReducer';
import { clientExpenseReducer } from './Reducers/clientExpenseReducer';
import { clientIncomeReducer } from './Reducers/clientIncomeReducer';
import { clientLiabilityReducer } from './Reducers/clientLiabilityReducer';
import { goalsReducer } from './Reducers/goalsReducer';
import { settingsReducer } from './Reducers/settingsReducer';
import { organizationSettingsReducer } from './Reducers/organizationSettingsReducer';
import { filesUploadReducer } from './Reducers/filesUploadReducer';
import { emailPostsReducer } from './Reducers/emailReducer';
import { familyAndLifestyleProtectionsReducer } from './Reducers/familyAndLifestyleProtectionsReducer';
import { paymentsReducer } from './Reducers/paymentsReducer';
import { teamMembersReducer } from './Reducers/teamMembersReducer';
import { clientQuestionnairesReducer } from './Reducers/clientQuestionnairesReducer';
import { financialPositionsReducer } from './Reducers/financialPositionsReducer';
import { questionnaireTemplateTagReducer } from './Reducers/questionnaireTemplateTagReducer';
import { emailTemplateReducer } from './Reducers/emailTemplateReducer';

const authenticationPersistConfig = {
  key: 'authenticationReducer',
  storage: storageSession,
};
//app reducer
const appReducer = combineReducers({
  authenticationReducer: persistReducer(authenticationPersistConfig, authenticationReducer),
  clientDocumentsReducer: clientDocumentsReducer,
  customization: customization,
  notificationReducer: notificationReducer,
  modalReducer: modalReducer,
  digitalDocReducer: digitalDocReducer,
  compositeDataReducer: compositeDataReducer,
  clientReducer: clientReducer,
  clientAssetReducer: clientAssetReducer,
  clientExpenseReducer: clientExpenseReducer,
  clientIncomeReducer: clientIncomeReducer,
  clientLiabilityReducer: clientLiabilityReducer,
  goalsReducer: goalsReducer,
  settingsReducer: settingsReducer,
  organizationSettingsReducer: organizationSettingsReducer,
  filesUploadReducer: filesUploadReducer,
  emailPostsReducer: emailPostsReducer,
  familyAndLifestyleProtectionsReducer: familyAndLifestyleProtectionsReducer,
  paymentsReducer: paymentsReducer,
  teamMembersReducer: teamMembersReducer,
  clientQuestionnairesReducer: clientQuestionnairesReducer,
  financialPositionsReducer: financialPositionsReducer,
  questionnaireTemplateTagReducer: questionnaireTemplateTagReducer,
  emailTemplateReducer: emailTemplateReducer,
});
//root reducer
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_SESSION') state = undefined;

  return appReducer(state, action);
};

const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
export default Store;
