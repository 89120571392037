import { Collapse, Grid, Grow, Paper } from '@mui/material';

import FormikTextField from 'common/formik/FormikTextField';

const TextAnswer = (props) => {
  const { questionData, questionnaireIndex, questionType } = props;

  return (
    <Collapse unmountOnExit sx={{ width: '100%' }} in={questionData?.fieldType === 'text'}>
      <Grow in={questionData?.fieldType === 'text'}>
        <Grid item xs={12}>
          <Paper sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormikTextField
              placeholder="Enter your answer"
              name={`[${questionnaireIndex}].answer.[${
                questionType === 'CLIENT' ? 'client' : 'partner'
              }].stringAnswer`}
              type="text"
              label={''}
              required={!!questionData?.isRequired}
              fullWidth
            />
          </Paper>
        </Grid>
      </Grow>
    </Collapse>
  );
};

export default TextAnswer;
