import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';


/**
 * Get All Team members
 * @param {*} handleGetTeamMembersSuccessCallback
 * @param {*} handleGetTeamMembersFailedCallback
 * @returns
 */
export const requestGetAllTeamMembers =
  (handleGetTeamMembersSuccessCallback = null, handleGetTeamMembersFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postGetTeamMembersRequest' });

    const requestData = {
      url: API_URL.teamMembers.GET_ALL_TEAM_MEMBERS,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'postGetTeamMembersSuccess', payload: response.data });
      handleGetTeamMembersSuccessCallback && handleGetTeamMembersSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postGetTeamMembersFailed', payload: error });
      let message = 'Please Check your Connection and Try Again..';
      if (error?.response?.status === 500 && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleGetTeamMembersFailedCallback && handleGetTeamMembersFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Handle Delete Team Member Request
 * @param {*} deleteTeamMemberData
 * @param {*} handleDeleteTeamMemberSuccessCallback
 * @param {*} handleDeleteTeamMemberFailedCallback
 * @returns
 */
export const requestDeleteTeamMember =
  (
    deleteTeamMemberData,
    handleDeleteTeamMemberSuccessCallback = null,
    handleDeleteTeamMemberFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteTeamMemberRequest' });

    const requestData = {
      url: API_URL.teamMembers.DELETE_TEAM_MEMBER_BY_ID.replace(
        '{teamMemberId}',
        deleteTeamMemberData?.teamMemberId
      ),
      method: 'DELETE',
    };
    const successCallback = (response) => {
      dispatch({ type: 'deleteTeamMemberSuccess', payload: response.data });
      const successMessage = response.data?.message || `Team Member is Deleted Successfully !`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleDeleteTeamMemberSuccessCallback && handleDeleteTeamMemberSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'deleteTeamMemberFailed', payload: error });
      let message = 'Please Check your Connection and Try Again..';
      if (error?.response?.status === 500 && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteTeamMemberFailedCallback && handleDeleteTeamMemberFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
/**
 * Handle Update Team member request
 * @param {*} editTeamMemberData
 * @param {*} handleEditTeamMemberSuccessCallback
 * @param {*} handleEditTeamMemberErrorCallback
 * @returns
 */
export const requestEditTeamMember =
  (
    editTeamMemberData,
    handleEditTeamMemberSuccessCallback = null,
    handleEditTeamMemberErrorCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'updateTeamMemberRequest' });

    const requestData = {
      url: API_URL.teamMembers.PUT_UPDATE_TEAM_MEMBER.replace(
        '{teamMemberId}',
        editTeamMemberData?.teamMemberId
      ),
      method: 'PUT',
      data: editTeamMemberData?.requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'updateTeamMemberSuccess', payload: response.data });
      const successMessage = response.data?.message || `Team Member is Updated Successfully !`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleEditTeamMemberSuccessCallback && handleEditTeamMemberSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'updateTeamMemberFailed', payload: error });
      let message = 'Please Check your Connection and Try Again..';
      if (error?.response?.status === 500 && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }
      Store.dispatch(handleNotification(message, 'error'));
      handleEditTeamMemberErrorCallback && handleEditTeamMemberErrorCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
