import { createReducer } from '@reduxjs/toolkit';

const clientQuestionnairesInitialState = {
  //get client Questionnaires
  isGetClientQuestionnairesLoading: false,
  clientQuestionnairesSuccessData: {},
  clientQuestionnairesFailedData: {},

  //update client questionnaires
  isUpdateClientQuestionnaireLoading: false,
  updateClientQuestionnaireSuccessData: {},
  updateClientQuestionnaireFailedData: {},

  //delete client questionnaires
  isDeleteClientQuestionnaireQuestionRequestLoading: false,
  deleteClientQuestionnaireQuestionRequestSuccessData: {},
  deleteClientQuestionnaireQuestionRequestFailedData: {},

  //filter client questionnaires
  isFilterClientQuestionnaireRequestLoading: false,
  filterQuestionnaireRequestSuccessData: {},
  filterQuestionnaireRequestFailedData: {},

  //status questionnaires
  isGetStatusQuestionnaireRequestLoading: false,
  getStatusQuestionnaireRequestSuccessData: {},
  getStatusQuestionnaireRequestFailedData: {},
};

//client questionnaires reducer
export const clientQuestionnairesReducer = createReducer(clientQuestionnairesInitialState, {
  //get client questionnaires
  getClientQuestionnaireRequest: (state) => {
    state.isGetClientQuestionnairesLoading = true;
    state.clientQuestionnairesSuccessData = {};
    state.clientQuestionnairesFailedData = {};
  },
  getClientQuestionnaireRequestSuccess: (state, action) => {
    state.isGetClientQuestionnairesLoading = false;
    state.clientQuestionnairesSuccessData = action.payload;
    state.clientQuestionnairesFailedData = {};
  },
  getClientQuestionnaireRequestFailed: (state, action) => {
    state.isGetClientQuestionnairesLoading = false;
    state.clientQuestionnairesSuccessData = {};
    state.clientQuestionnairesFailedData = action.payload;
  },

  //update client questionnaires
  updateClientQuestionnaireRequest: (state) => {
    state.isUpdateClientQuestionnaireLoading = true;
    state.updateClientQuestionnaireSuccessData = {};
    state.updateClientQuestionnaireFailedData = {};
  },
  updateClientQuestionnaireRequestSuccess: (state, action) => {
    state.isUpdateClientQuestionnaireLoading = false;
    state.updateClientQuestionnaireSuccessData = action.payload;
    state.updateClientQuestionnaireFailedData = {};
  },
  updateClientQuestionnaireRequestFailed: (state, action) => {
    state.isUpdateClientQuestionnaireLoading = false;
    state.updateClientQuestionnaireSuccessData = {};
    state.updateClientQuestionnaireFailedData = action.payload;
  },

  deleteClientQuestionnaireQuestionRequest: (state) => {
    state.isDeleteClientQuestionnaireQuestionRequestLoading = true;
    state.deleteClientQuestionnaireQuestionRequestSuccessData = {};
    state.deleteClientQuestionnaireQuestionRequestFailedData = {};
  },
  deleteClientQuestionnaireQuestionRequestSuccess: (state, action) => {
    state.isDeleteClientQuestionnaireQuestionRequestLoading = false;
    state.deleteClientQuestionnaireQuestionRequestSuccessData = action.payload;
    state.deleteClientQuestionnaireQuestionRequestFailedData = {};
  },
  deleteClientQuestionnaireQuestionRequestFailed: (state, action) => {
    state.isDeleteClientQuestionnaireQuestionRequestLoading = false;
    state.deleteClientQuestionnaireQuestionRequestSuccessData = {};
    state.deleteClientQuestionnaireQuestionRequestFailedData = action.payload;
  },

  //_filter client questionnaires
  postFilterClientQuestionnairesRequest: (state) => {
    state.isFilterClientQuestionnaireRequestLoading = true;
    state.filterQuestionnaireRequestSuccessData = {};
    state.filterQuestionnaireRequestFailedData = {};
  },
  postFilterClientQuestionnairesRequestSuccess: (state, action) => {
    state.isFilterClientQuestionnaireRequestLoading = false;
    state.filterQuestionnaireRequestSuccessData = action.payload;
    state.filterQuestionnaireRequestFailedData = {};
  },
  postFilterClientQuestionnairesRequestFailed: (state, action) => {
    state.isFilterClientQuestionnaireRequestLoading = false;
    state.filterQuestionnaireRequestSuccessData = {};
    state.filterQuestionnaireRequestFailedData = action.payload;
  },

  //status client questionnaires
  getStatusQuestionnaireRequest: (state) => {
    state.isGetStatusQuestionnaireRequestLoading = true;
    state.getStatusQuestionnaireRequestSuccessData = {};
    state.getStatusQuestionnaireRequestFailedData = {};
  },
  getStatusQuestionnaireRequestSuccess: (state, action) => {
    state.isGetStatusQuestionnaireRequestLoading = false;
    state.getStatusQuestionnaireRequestSuccessData = action.payload;
    state.getStatusQuestionnaireRequestFailedData = {};
  },
  getStatusQuestionnaireRequestFailed: (state, action) => {
    state.isGetStatusQuestionnaireRequestLoading = false;
    state.getStatusQuestionnaireRequestSuccessData = {};
    state.getStatusQuestionnaireRequestFailedData = action.payload;
  },
});
