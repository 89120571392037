import { useMemo, useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Fade,
  Grid,
  Grow,
  IconButton,
  MobileStepper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Save,
} from '@mui/icons-material';

import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as R from 'ramda';
import { TransitionGroup } from 'react-transition-group';

import { gridSpacing } from 'store/constant';
import { useCommonStyles } from 'styles/styles';
import QuestionnaireQuestionAndAnswer from './QuestionnaireQuestionAndAnswer';
import QuestionSectionContentSkeleton from './skeleton/QuestionSectionContentSkeleton';

const QuestionSection = (props) => {
  const {
    activeQuestionSectionData,
    questionnaireSectionsData,
    setQuestionnaireSectionData,
    sectionIndex,
    handleAnswerFormikSubmission,
    questionAnswerFormik,
    questionnaireDetail,
  } = props;
  const {
    classes: { lightActionBtn, saveBtn },
    theme,
  } = useCommonStyles();
  // client store
  const clientReducer = useSelector((state) => state.clientReducer);
  const selectedClientDetail = clientReducer?.selectedClientDetail;

  const clientQuestionnairesReducer = useSelector((state) => state?.clientQuestionnairesReducer);
  const isGetClientQuestionnairesLoading =
    clientQuestionnairesReducer?.isGetClientQuestionnairesLoading;
  const isUpdateClientQuestionnaireLoading =
    clientQuestionnairesReducer?.isUpdateClientQuestionnaireLoading;

  const isLastSection = sectionIndex === questionnaireSectionsData?.sections?.length - 1;
  const initialValues = [...(activeQuestionSectionData?.sectionQuestions || [])];

  const [questionnaireSectionCollapseData, setQuestionnaireSectionCollapseData] = useState({
    clientAnswersIn: true,
    partnerAnswersIn: true,
  });
  /**
   * Is Data Loading
   */
  const isDataLoading = useMemo(() => {
    const isNotLoading =
      activeQuestionSectionData?.sectionHeader &&
      !isGetClientQuestionnairesLoading &&
      !isUpdateClientQuestionnaireLoading;
    return !isNotLoading;
  }, [
    activeQuestionSectionData?.sectionHeader,
    isGetClientQuestionnairesLoading,
    isUpdateClientQuestionnaireLoading,
  ]);
  //_memoed client questions
  const clientQuestions = useMemo(
    () =>
      (activeQuestionSectionData?.sectionQuestions?.length &&
        activeQuestionSectionData.sectionQuestions.filter(
          (questionData) =>
            questionData?.personType === 'BOTH' || questionData?.personType === 'CLIENT'
        )) ||
      [],
    [activeQuestionSectionData]
  );
  //_memoed partner questions
  const partnerQuestions = useMemo(
    () =>
      (activeQuestionSectionData?.sectionQuestions?.length &&
        activeQuestionSectionData.sectionQuestions.filter(
          (questionData) =>
            questionData?.personType === 'BOTH' || questionData?.personType === 'PARTNER'
        )) ||
      [],
    [activeQuestionSectionData]
  );

  /**
   * Answer required validator for the Questionnaire Field
   */
  Yup.addMethod(Yup.string, 'validateStringAnswer', function (message) {
    return this.test('validateStringAnswer', message, function (value, ctx) {
      const {
        path,
        createError,
        parent,
        options: { from },
      } = this;

      if (value) {
        return true;
      }
      let errorMessage = '';
      let parentFormData = from?.[(+from?.length || 1) - 1]?.value;
      let fieldPathPersonType = path?.split('.')?.[2];
      let capitalizedPathPersonType =
        typeof fieldPathPersonType === 'string' && fieldPathPersonType.toUpperCase();
      if (
        parentFormData?.isRequired &&
        (parentFormData?.personType === 'BOTH' ||
          parentFormData?.personType === capitalizedPathPersonType) &&
        (capitalizedPathPersonType === 'PARTNER' ? selectedClientDetail?.hasPartner : true) &&
        parentFormData?.component === 'input' &&
        (parentFormData?.fieldType === 'text' || parentFormData?.fieldType === 'booleanAndText') &&
        (!fieldPathPersonType ||
          (typeof fieldPathPersonType === 'string' &&
            (fieldPathPersonType.toUpperCase() === parentFormData?.personType ||
              parentFormData?.personType === 'BOTH')))
      ) {
        const personBooleanAnswer = !!parent?.booleanAnswer;
        if (
          (!value && parentFormData?.fieldType === 'text') ||
          (!value && personBooleanAnswer && parentFormData?.fieldType === 'booleanAndText')
        ) {
          errorMessage = message;
        } else {
          errorMessage = '';
        }
      }
      if (errorMessage) {
        // if (isNotification) {
        //   dispatch(handleNotification(notificationMessage, 'error'));
        // }
        return createError({
          path: path,
          message: errorMessage,
        });
      } else {
        return true;
      }
    });
  });
  //validates radio answer
  Yup.addMethod(Yup.boolean, 'validateYesNoAnswer', function (message) {
    return this.test('validateYesNoAnswer', message, function (value, ctx) {
      const {
        path,
        createError,
        parent,
        options: { from },
      } = this;

      let errorMessage = '';
      let parentFormData = from?.[(+from?.length || 1) - 1]?.value;
      let fieldPathPersonType = path?.split('.')?.[2];
      let capitalizedPathPersonType =
        typeof fieldPathPersonType === 'string' && fieldPathPersonType.toUpperCase();
      if (typeof value === 'boolean') {
        return true;
      } else if (
        parentFormData?.isRequired &&
        (parentFormData?.fieldType === 'booleanAndText' ||
          parentFormData?.fieldType === 'boolean') &&
        (parentFormData?.personType === 'BOTH' ||
          parentFormData?.personType === capitalizedPathPersonType) &&
        (capitalizedPathPersonType === 'PARTNER' ? selectedClientDetail?.hasPartner : true)
      ) {
        errorMessage = message;
      } else {
        return true;
      }

      if (errorMessage) {
        // if (isNotification) {
        //   dispatch(handleNotification(notificationMessage, 'error'));
        // }
        return createError({
          path: path,
          message: errorMessage,
        });
      } else {
        return true;
      }
    });
  });
  //validates table answer
  Yup.addMethod(Yup.array, 'validateTableAnswer', function (message) {
    return this.test('validateTableAnswer', message, function (value, ctx) {
      const {
        path,
        createError,
        parent,
        options: { from },
      } = this;

      let errorMessage = '';
      let parentFormData = from?.[(+from?.length || 1) - 1]?.value;
      let fieldPathPersonType = path?.split('.')?.[2];
      let capitalizedPathPersonType =
        typeof fieldPathPersonType === 'string' && fieldPathPersonType.toUpperCase();
      if (Array.isArray(value) && Array.isArray(value?.[1]) && value?.[1]?.length) {
        return true;
      } else if (
        parentFormData?.isRequired &&
        parentFormData?.fieldType === 'multipleTabular' &&
        (parentFormData?.personType === 'BOTH' ||
          parentFormData?.personType === capitalizedPathPersonType) &&
        (capitalizedPathPersonType === 'PARTNER' ? selectedClientDetail?.hasPartner : true)
      ) {
        errorMessage = message;
      } else {
        return true;
      }

      if (errorMessage) {
        // if (isNotification) {
        //   dispatch(handleNotification(notificationMessage, 'error'));
        // }
        return createError({
          path: path,
          message: errorMessage,
        });
      } else {
        return true;
      }
    });
  });

  //validates signature answer
  Yup.addMethod(Yup.string, 'validateSignatureAnswer', function (message) {
    return this.test('validateSignatureAnswer', message, function (value, ctx) {
      const {
        path,
        createError,
        parent,
        options: { from },
      } = this;
      let errorMessage = '';
      let parentFormData = from?.[(+from?.length || 1) - 1]?.value;
      let fieldPathPersonType = path?.split('.')?.[2];
      let capitalizedPathPersonType =
        typeof fieldPathPersonType === 'string' && fieldPathPersonType.toUpperCase();
      if (value !== '' && value !== '[]' && !!value) {
        return true;
      } else if (
        parentFormData?.isRequired &&
        parentFormData?.fieldType === 'signatureAgreement' &&
        (parentFormData?.personType === 'BOTH' ||
          parentFormData?.personType === capitalizedPathPersonType) &&
        (capitalizedPathPersonType === 'PARTNER' ? selectedClientDetail?.hasPartner : true)
      ) {
        errorMessage = message;
      } else {
        return true;
      }

      if (errorMessage) {
        // if (isNotification) {
        //   dispatch(handleNotification(notificationMessage, 'error'));
        // }
        return createError({
          path: path,
          message: errorMessage,
        });
      } else {
        return true;
      }
    });
  });

  const validationSchema = Yup.array().of(
    Yup.object().shape({
      component: Yup.string().nullable(),
      personType: Yup.string().nullable(),
      answerFieldKeys: Yup.array().nullable(),
      headerTitle: Yup.string().nullable(),
      headerType: Yup.string().nullable(),
      isRequired: Yup.boolean().nullable(),
      fieldType: Yup.string().nullable(),
      question: Yup.string().nullable(),
      questionCaption: Yup.string().nullable(),
      answer: Yup.object().when('component', {
        is: (component) => component === 'input',
        then: Yup.object()
          .shape({
            client: Yup.object()
              .shape({
                booleanAnswer: Yup.boolean().validateYesNoAnswer('Required !').nullable(),
                stringAnswer: Yup.string().validateStringAnswer('Required !').nullable(),
                multipleTabularAnswers: Yup.array().validateTableAnswer('Required !').nullable(),
                multipleFormatAnswers: Yup.array().nullable(),
                signatureAgreementAnswers: Yup.object()
                  .shape({
                    name: Yup.string().nullable(),
                    date: Yup.date().nullable(),
                    signaturePoints: Yup.string().validateSignatureAnswer('Required!').nullable(),
                  })
                  .nullable(),
              })
              .nullable(),
            partner: Yup.object()
              .shape({
                booleanAnswer: Yup.boolean().validateYesNoAnswer('Required !').nullable(),
                stringAnswer: Yup.string().validateStringAnswer('Required !').nullable(),
                multipleTabularAnswers: Yup.array().validateTableAnswer('Required !').nullable(),
                multipleFormatAnswers: Yup.array().nullable(),
                signatureAgreementAnswers: Yup.object()
                  .shape({
                    name: Yup.string().nullable(),
                    date: Yup.date().nullable(),
                    signaturePoints: Yup.string().validateSignatureAnswer('Required!').nullable(),
                  })
                  .nullable(),
              })
              .nullable(),
          })
          .nullable(),
        otherwise: Yup.object().notRequired().nullable(),
      }),
    })
  );
  /**
   * Handle go to next section with save to questionnaire
   */
  const handleGoToNextSection = (values, submitProps, isSubmitForm = false, event = null) => {
    submitProps?.setSubmitting && submitProps.setSubmitting(true);

    let sections = questionnaireSectionsData?.sections || [];
    if (sections?.[sectionIndex]) {
      sections[sectionIndex] = {
        ...(sections?.[sectionIndex] || {}),
        sectionQuestions: values,
      };
    }

    const questionnaires = questionAnswerFormik?.values?.questionnaires || [];
    const setFieldValue = questionAnswerFormik?.setFieldValue;
    let questionnaireData = [...questionnaires];
    const spliceStartIndex = sections?.[sectionIndex]?.questionnaireHeaderKey + 1;

    const replaceArray = values;
    const numberOfElementsToDeleteAndReplace = values?.length;
    questionnaireData.splice(spliceStartIndex, numberOfElementsToDeleteAndReplace, ...replaceArray);
    setFieldValue && setFieldValue('questionnaires', questionnaireData);
    setQuestionnaireSectionData((prev) => ({
      ...prev,
      activeSectionIndex: !isSubmitForm ? prev?.activeSectionIndex + 1 : 0,
      sections,
    }));

    submitProps?.setSubmitting && submitProps.setSubmitting(false);
    if (isSubmitForm) {
      handleAnswerFormikSubmission && handleAnswerFormikSubmission(event);
    }
  };
  /**
   * Handle go back section
   */
  const handleStepBack = () => {
    setQuestionnaireSectionData((prev) => ({
      ...prev,
      activeSectionIndex: prev?.activeSectionIndex - 1,
    }));
  };

  /**
   * Handle collapse client, partner answers
   * @param {*} event
   */
  const handleCollapseClientAnswers = (event) => {
    setQuestionnaireSectionCollapseData((prev) => ({
      ...prev,
      clientAnswersIn: !prev?.clientAnswersIn,
    }));
  };
  /**
   * Handle collapse client, partner answers
   * @param {*} event
   */
  const handleCollapsePartnerAnswers = (event) => {
    setQuestionnaireSectionCollapseData((prev) => ({
      ...prev,
      partnerAnswersIn: !prev?.partnerAnswersIn,
    }));
  };
  /**
   * Handle Generate Nested Touched value for Error Obj
   * @param {*} mainErrorKeyOrObject
   * @param {*} prevTouchedData
   * @returns
   */
  const handleNestedTouchEvents = (mainErrorKeyOrObject, prevTouchedData = {}) => {
    let touchedData = { ...prevTouchedData };
    Object.keys(mainErrorKeyOrObject).forEach((singleErrorKey) => {
      if (typeof mainErrorKeyOrObject[singleErrorKey] === 'object') {
        touchedData[singleErrorKey] = {};
        touchedData[singleErrorKey] = handleNestedTouchEvents(
          mainErrorKeyOrObject[singleErrorKey],
          touchedData[singleErrorKey]
        );
      } else if (typeof mainErrorKeyOrObject[singleErrorKey] === 'string') {
        touchedData[singleErrorKey] = !!mainErrorKeyOrObject[singleErrorKey];
      }
    });

    return touchedData;
  };
  /**
   * Get question count
   * @returns {string} padded number string
   */
  const getQuestionsCount = () => {
    let count = Number(activeQuestionSectionData?.sectionQuestions?.length)
      .toString()
      .padStart(2, '0');

    const leadsQuestionCount =
      (activeQuestionSectionData?.sectionQuestions &&
        activeQuestionSectionData?.sectionQuestions?.filter(
          (questionData) =>
            questionData?.personType === 'CLIENT' || questionData?.personType === 'BOTH'
        )?.length) ||
      0;
    const partnerQuestionCount = selectedClientDetail?.hasPartner
      ? (activeQuestionSectionData?.sectionQuestions &&
          activeQuestionSectionData?.sectionQuestions?.filter(
            (questionData) =>
              questionData?.personType === 'PARTNER' || questionData?.personType === 'BOTH'
          )?.length) ||
        0
      : 0;
    count = leadsQuestionCount + partnerQuestionCount;

    return Number(count).toString().padStart(2, '0');
  };
  /**
   *
   * @param {*} event
   * @param {*} formik
   */
  const handleAnswerSubmission = (event, formik) => {
    formik
      .validateForm(formik.values)
      .then((errorObj) => {
        let tempErrorObj = { ...(errorObj || {}) };
        let tempTouched = { ...(formik.touched || {}) };
        Object.keys(tempErrorObj).forEach((singleKey) => {
          if (typeof tempErrorObj[singleKey] === 'object') {
            try {
              tempTouched[singleKey] = handleNestedTouchEvents(tempErrorObj[singleKey]);
            } catch (err) {}
          } else {
            tempTouched[singleKey] = !!tempErrorObj[singleKey];
          }
        });
        let isError = typeof tempErrorObj === 'object' && !!Object.keys(tempErrorObj)?.length;
        if (isError) {
          formik.setTouched(tempTouched, true);
        } else {
          handleGoToNextSection(formik?.values, formik, true, event);
        }
      })
      .catch((error) => {})
      .finally((value) => {});
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleGoToNextSection}
    >
      {(formik) => (
        <section
          style={{
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
          }}
        >
          <Grid item xs={12} sx={{ flex: 1, display: 'flex', height: '100%' }}>
            <Card
              elevation={0}
              sx={(theme) => ({
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                border: '1px solid',
                borderColor: theme.palette.primary[200] + 75,
              })}
            >
              <CardHeader
                sx={{
                  backgroundColor: theme.palette.blue?.[350],
                  color: theme.palette?.background?.paper,
                }}
                title={
                  !isDataLoading ? (
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette?.background?.paper,
                        fontWeight: theme.typography.fontWeightMedium,
                        fontSize: '1rem',
                      }}
                    >
                      {activeQuestionSectionData?.sectionHeader}
                    </Typography>
                  ) : (
                    <Skeleton
                      variant="text"
                      sx={{ width: '10ch', backgroundColor: theme?.palette?.grey?.[400] }}
                    />
                  )
                }
                subheader={
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      fontWeight: theme.typography.fontWeightLight,
                      color: theme.palette.grey?.[200],
                    }}
                  >
                    {!isDataLoading ? (
                      activeQuestionSectionData?.sectionQuestions?.length ? (
                        `You have ${getQuestionsCount()} questions to answer in this section.`
                      ) : (
                        'You have no questions to answer in this section!'
                      )
                    ) : (
                      <Skeleton
                        variant="text"
                        sx={{ width: '20ch', backgroundColor: theme?.palette?.grey?.[200] }}
                      />
                    )}
                  </Typography>
                }
              />

              <CardContent
                sx={{
                  flex: 1,
                  padding: '0 !important',
                  display: 'flex',
                  height: '100%',
                  alignItems: 'flex-start',
                  maxWidth: 'calc(100vw - 4rem)',
                  overflowX: 'auto',
                }}
              >
                <Fade in={!isDataLoading} unmountOnExit>
                  <Grid
                    container
                    spacing={gridSpacing}
                    alignItems="flex-start"
                    flex={1}
                    margin="0 !important"
                  >
                    <Grid item xs={12}>
                      <Collapse in={!!clientQuestions?.length}>
                        <Grow in={!!clientQuestions?.length}>
                          <Fade in={!!clientQuestions?.length}>
                            <Grid
                              container
                              sx={{
                                marginTop: '2rem',
                              }}
                            >
                              <Grid item xs={12}>
                                <Stack
                                  direction="row"
                                  spacing={gridSpacing}
                                  alignItems="center"
                                  sx={{ userSelect: 'none' }}
                                >
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      color: theme.palette.text.primary,
                                      fontWeight: theme.typography.fontWeightMedium,
                                      cursor: 'pointer',
                                      userSelect: 'none',
                                    }}
                                    onClick={handleCollapseClientAnswers}
                                  >
                                    Questions for you
                                  </Typography>
                                  <Divider
                                    variant="fullWidth"
                                    orientation="horizontal"
                                    flexItem
                                    sx={{
                                      flex: 1,
                                      alignSelf: 'center',
                                      borderWidth: '0.1rem',
                                      borderRadius: '0.1rem',
                                    }}
                                  />

                                  <IconButton onClick={handleCollapseClientAnswers}>
                                    <KeyboardArrowDown
                                      sx={{
                                        transition:
                                          'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 500ms ease',
                                        transform: questionnaireSectionCollapseData?.clientAnswersIn
                                          ? 'scaleY(1)'
                                          : 'scaleY(-1)',
                                      }}
                                    />
                                  </IconButton>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Fade>
                        </Grow>
                      </Collapse>
                    </Grid>
                    <Collapse
                      in={questionnaireSectionCollapseData?.clientAnswersIn}
                      sx={{ width: '100%' }}
                    >
                      <Grow
                        style={{ transformOrigin: '0 0 0' }}
                        in={questionnaireSectionCollapseData?.clientAnswersIn}
                      >
                        <Fade in={questionnaireSectionCollapseData?.clientAnswersIn}>
                          <Grid item xs={12} sx={{ overflowY: 'auto' }}>
                            <TransitionGroup>
                              {clientQuestions.map((questionData, questionIndex) => (
                                <Collapse key={questionIndex + '-questionCollapse-Client'}>
                                  <Grow>
                                    <>
                                      <Grow in={!!questionIndex}>
                                        <Divider variant="fullWidth" orientation="horizontal" />
                                      </Grow>
                                      <QuestionnaireQuestionAndAnswer
                                        sectionData={questionnaireSectionsData}
                                        sectionIndex={sectionIndex}
                                        questionData={questionData}
                                        questionnaireIndex={questionData?.questionSectionKey}
                                        questionType={'CLIENT'}
                                        handleFieldBlur={questionAnswerFormik?.handleBlur}
                                      />
                                    </>
                                  </Grow>
                                </Collapse>
                              ))}
                            </TransitionGroup>
                          </Grid>
                        </Fade>
                      </Grow>
                    </Collapse>
                    <Collapse
                      in={selectedClientDetail?.hasPartner && !!partnerQuestions?.length}
                      sx={{ width: '100%' }}
                    >
                      <Grow in={selectedClientDetail?.hasPartner && !!partnerQuestions?.length}>
                        <Fade in={selectedClientDetail?.hasPartner && !!partnerQuestions?.length}>
                          <Grid container item xs={12} spacing={gridSpacing}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                marginTop: '2rem',
                                marginLeft: '1rem',
                              }}
                            >
                              <Stack
                                direction="row"
                                spacing={gridSpacing}
                                alignItems="center"
                                sx={{ userSelect: 'none' }}
                              >
                                <Typography
                                  variant="h4"
                                  sx={{
                                    color: theme.palette.text.primary,
                                    fontWeight: theme.typography.fontWeightMedium,
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                  }}
                                  onClick={handleCollapsePartnerAnswers}
                                >
                                  Questions for your partner
                                </Typography>
                                <Divider
                                  variant="fullWidth"
                                  orientation="horizontal"
                                  flexItem
                                  sx={{
                                    flex: 1,
                                    alignSelf: 'center',
                                    borderWidth: '0.1rem',
                                    borderRadius: '0.1rem',
                                  }}
                                />
                                <IconButton onClick={handleCollapsePartnerAnswers}>
                                  <KeyboardArrowDown
                                    sx={{
                                      transition:
                                        'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 500ms ease',
                                      transform: questionnaireSectionCollapseData?.partnerAnswersIn
                                        ? 'scaleY(1)'
                                        : 'scaleY(-1)',
                                    }}
                                  />
                                </IconButton>
                              </Stack>
                            </Grid>
                            <Collapse
                              in={questionnaireSectionCollapseData?.partnerAnswersIn}
                              sx={{ width: '100%' }}
                            >
                              <Grow
                                style={{ transformOrigin: '0 0 0' }}
                                in={questionnaireSectionCollapseData?.partnerAnswersIn}
                              >
                                <Fade in={questionnaireSectionCollapseData?.partnerAnswersIn}>
                                  <Grid item xs={12} sx={{ overflowY: 'auto' }}>
                                    <TransitionGroup>
                                      {partnerQuestions.map((questionData, questionIndex) => (
                                        <Collapse key={questionIndex + '-questionCollapse-Partner'}>
                                          <Grow>
                                            <>
                                              <Grow in={!!questionIndex}>
                                                <Divider
                                                  variant="fullWidth"
                                                  orientation="horizontal"
                                                />
                                              </Grow>
                                              <QuestionnaireQuestionAndAnswer
                                                sectionData={questionnaireSectionsData}
                                                sectionIndex={sectionIndex}
                                                questionData={questionData}
                                                questionnaireIndex={
                                                  questionData?.questionSectionKey
                                                }
                                                questionType={'PARTNER'}
                                                handleFieldBlur={questionAnswerFormik?.handleBlur}
                                              />
                                            </>
                                          </Grow>
                                        </Collapse>
                                      ))}
                                    </TransitionGroup>
                                  </Grid>
                                </Fade>
                              </Grow>
                            </Collapse>
                          </Grid>
                        </Fade>
                      </Grow>
                    </Collapse>
                  </Grid>
                </Fade>
                <Fade in={isDataLoading} unmountOnExit>
                  <Grid
                    container
                    spacing={gridSpacing}
                    alignItems="flex-start"
                    flex={1}
                    margin="0 !important"
                  >
                    <QuestionSectionContentSkeleton />
                  </Grid>
                </Fade>
              </CardContent>
              <CardContent sx={{ padding: '0 !important' }}>
                <Grid item xs={12} sx={{ flex: '0 !important' }}>
                  <MobileStepper
                    variant="text"
                    position="static"
                    color="primary"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.background.paper,
                      borderRadius: `0 0 ${theme.shape.globalBorderRadius}px ${theme.shape.globalBorderRadius}px`,
                      // '& .MuiLinearProgress-root': {
                      //   backgroundColor
                      // }
                      padding: '1rem 1rem 2rem 1rem !important',
                    }}
                    steps={questionnaireSectionsData?.sections?.length || 1}
                    activeStep={questionnaireSectionsData?.activeSectionIndex}
                    nextButton={
                      isLastSection ? (
                        <LoadingButton
                          id={'questionnaire-answer-save-btn'}
                          disableElevation
                          disabled={
                            formik?.isSubmitting ||
                            isDataLoading ||
                            questionnaireDetail?.status === 'Reviewed'
                          }
                          size="medium"
                          sx={(theme) => ({
                            minWidth: '10ch',
                            color: theme.palette.primary.main + '!important',
                            backgroundColor: theme.palette.background.paper + ' !important',
                            '&:hover': {
                              color: theme.palette.primary.main + '!important',
                              backgroundColor: theme.palette.background.paper + ' !important',
                            },
                            '&.Mui-disabled': {
                              color: theme.palette.text.primary + '!important',
                              backgroundColor: theme.palette.grey?.[500] + ' !important',
                            },
                          })}
                          onClick={(event) => {
                            handleAnswerSubmission(event, formik);
                          }}
                          variant="contained"
                          className={saveBtn}
                          loadingPosition="end"
                          loading={formik?.isSubmitting}
                          endIcon={<Save />}
                        >
                          Save
                        </LoadingButton>
                      ) : (
                        <Button
                          size="medium"
                          onClick={(event) => formik?.handleSubmit && formik.handleSubmit(event)}
                          disabled={
                            questionnaireSectionsData?.activeSectionIndex ===
                              questionnaireSectionsData?.sections?.length - 1 || isDataLoading
                          }
                          variant="outlined"
                          className={lightActionBtn}
                          disable={isDataLoading}
                        >
                          Next
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      )
                    }
                    backButton={
                      <Button
                        size="medium"
                        onClick={handleStepBack}
                        disabled={
                          questionnaireSectionsData?.activeSectionIndex === 0 || isDataLoading
                        }
                        variant="outlined"
                        className={lightActionBtn}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Back
                      </Button>
                    }
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </section>
      )}
    </Formik>
  );
};

export default QuestionSection;
