import { isEmpty, isNil } from 'ramda';

/**
 * Get Dynamic Base from ENV
 * @returns {object} {Base URL, Current Environment}
 */
const handleGetBaseAndEnv = (customModule = null) => {
  let baseURL,
    currentEnv,
    currentPortal = null;

  //* DEFAULT ADVISOR_PORTAL BASE PATHS
  let DEV_BASE = 'https://lf4g5xr70b.execute-api.ap-southeast-2.amazonaws.com/';
  let QA_BASE = 'https://olv31xba27.execute-api.ap-southeast-2.amazonaws.com/';
  let UAT_BASE = 'https://c3h7ys0bea.execute-api.ap-southeast-2.amazonaws.com/';
  let LOCAL_BASE = 'http://localhost:4005/';
  let PROD_BASE = 'https://4sx6aiqch6.execute-api.ap-southeast-2.amazonaws.com/';

  let nodeProcessCurrentEnv = process.env?.['REACT_APP_ENV'];
  let nodeProcessCurrentPortal = process.env?.['REACT_APP_PORTAL'];

  currentEnv = nodeProcessCurrentEnv || 'dev';
  currentPortal = nodeProcessCurrentPortal || '';

  if (currentEnv === 'dev') {
    baseURL = DEV_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://6gg78zl6xh.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://r9adqr1ii7.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://lf4g5xr70b.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://35m5yggfqc.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://4uxjknbwd9.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://xprzs25820.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://jpzxtj2w59.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://aw95h1vvc5.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'qa') {
    baseURL = QA_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://oc8jqvm6rh.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://3t64hxdis2.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://qrsu7plbnl.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://uw2mar3mi0.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://gf8cs1dfef.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://qftlp3qjob.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://fkzi5q0wbj.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://afriywlf67.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'uat') {
    baseURL = UAT_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://plfen6k3ij.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://n1f9ojvem8.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://xzauwyu9gd.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://9echz69v12.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://mpyn6nhfpf.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://j09qrb6yj9.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://xkx44x1m0b.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://75h9zuriz7.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'prod') {
    baseURL = PROD_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://k43y8ljsf1.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://8axoexlcpd.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://e0ihwc9755.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://byhglthinj.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://f981rsuilb.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://ddiplxum8f.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://63duoc1d1a.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://20di3qmlcg.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'local') {
    baseURL = LOCAL_BASE; //4005 httpPort
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'http://localhost:4001/';
      } else if (customModule === 'AUTH') {
        baseURL = 'http://localhost:4002/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'http://localhost:4003/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'http://localhost:4004/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'http://localhost:4006/';
      } else if (customModule === 'SMSF') {
        baseURL = 'http://localhost:4007/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'http://localhost:4008/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'http://localhost:4009/';
      }
    }
  }

  return { baseURL, currentEnv, currentPortal };
};

const { baseURL, currentEnv, currentPortal } = handleGetBaseAndEnv();

const basePath = baseURL + currentEnv;

//module base paths
const AuthHModuleBasePath = `${handleGetBaseAndEnv('AUTH')?.baseURL}${
  handleGetBaseAndEnv('AUTH')?.currentEnv
}`;
const UtilsBasePath = `${handleGetBaseAndEnv('UTILS')?.baseURL}${
  handleGetBaseAndEnv('UTILS')?.currentEnv
}`;
const SettingsModuleBasePath = `${handleGetBaseAndEnv('SETTINGS')?.baseURL}${
  handleGetBaseAndEnv('SETTINGS')?.currentEnv
}`;
const PaymentsModuleBasePath = `${handleGetBaseAndEnv('PAYMENTS')?.baseURL}${
  handleGetBaseAndEnv('PAYMENTS')?.currentEnv
}`;
const ClientModuleBasePath = `${handleGetBaseAndEnv('CLIENT_PORTAL')?.baseURL}${
  handleGetBaseAndEnv('CLIENT_PORTAL')?.currentEnv
}`;

const API_URL = {
  baseURL,
  basePath,
  currentEnv,
  currentPortal,
  auth: {
    moduleVariable: 'AUTH',
    moduleBasePath: AuthHModuleBasePath,
    authentication: {
      POST_SIGN_UP: AuthHModuleBasePath + '/signup',
      POST_SIGN_IN: AuthHModuleBasePath + '/client-login',
      POST_SEND_PASSWORD_RESET_LINK: AuthHModuleBasePath + '/password-reset-client',
      POST_RESET_USER_PASSWORD: AuthHModuleBasePath + '/password-reset/{userId}/{requestToken}',
      PUT_UPDATE_PASSWORD: AuthHModuleBasePath + '/update-password',
    },
    registration: {
      POST_CREATE_ADVISOR: AuthHModuleBasePath + '/advisors',
    },
  },
  client: {
    hello: basePath + '/hello',
    clientProfile: {
      PATCH_UPDATE_CLIENT_PROFILE_BY_ID: basePath + '/clients/{clientId}',
      GET_FIND_CLIENT_BY_CLIENT_ID: basePath + '/clients/{clientId}',
      GET_FIND_CLIENT_STRENGTH_BY_CLIENT_ID: basePath + '/clients/client-strength/{clientId}',
    },
    clientList: {
      GET_ALL_CLIENTS: basePath + '/clients',
      POST_CREATE_NEW_CLIENT: basePath + '/clients',
    },
    clientInvitation: {
      POST_INVITE_CLIENT: basePath + '/clients/invites',
      GET_CLIENT_INVITATION_TEMPLATE: basePath + '/client-documents/invite',
    },
    clientDocuments: {
      GET_CLIENT_DOCUMENTS: basePath + '/client-documents/client/{clientId}',
    },
    financial: {
      moduleVariable: 'CLIENT_PORTAL',
      moduleBasePath: ClientModuleBasePath,
      financialPosition: {
        GET_FINANCIAL_POSITION_SUMMARY:
          ClientModuleBasePath +
          '/client/financial/getActiveFinancialPositionSummary/financialPosition/{financialPositionId}',
      },
      expense: {
        CREATE:
          ClientModuleBasePath +
          '/client/financial/createExpenses/{clientId}/{financialPositionId}',
        UPDATE:
          ClientModuleBasePath +
          '/client/financial/updateExpenses/{clientId}/{financialPositionId}/{clientExpenseId}/{expensesId}',
        DELETE:
          ClientModuleBasePath +
          '/client/financial/deleteExpenses/{clientId}/{financialPositionId}/{clientExpenseId}/{expensesId}',
        GET:
          ClientModuleBasePath + '/client/financial/getExpenses/{clientId}/{financialPositionId}',
      },
      liability: {
        CREATE:
          ClientModuleBasePath +
          '/client/financial/createLiability/{clientId}/{financialPositionId}',
        UPDATE:
          ClientModuleBasePath +
          '/client/financial/updateLiability/{clientId}/{financialPositionId}/{clientLiabilityId}/{liabilityId}',
        DELETE:
          ClientModuleBasePath +
          '/client/financial/deleteLiability/{clientId}/{financialPositionId}/{clientLiabilityId}/{liabilityId}',
        GET:
          ClientModuleBasePath + '/client/financial/getLiability/{clientId}/{financialPositionId}',
      },
      asset: {
        CREATE:
          ClientModuleBasePath + '/client/financial/createAsset/{clientId}/{financialPositionId}',
        UPDATE:
          ClientModuleBasePath +
          '/client/financial/updateAsset/{clientId}/{financialPositionId}/{clientAssetId}/{assetId}',
        DELETE:
          ClientModuleBasePath +
          '/client/financial/deleteAsset/{clientId}/{financialPositionId}/{clientAssetId}/{assetId}',
        GET: ClientModuleBasePath + '/client/financial/getAsset/{clientId}/{financialPositionId}',
      },
      income: {
        CREATE:
          ClientModuleBasePath + '/client/financial/createIncome/{clientId}/{financialPositionId}',
        UPDATE:
          ClientModuleBasePath +
          '/client/financial/updateIncome/{clientId}/{financialPositionId}/{clientIncomeId}/{incomeId}',
        DELETE:
          ClientModuleBasePath +
          '/client/financial/deleteIncome/{clientId}/{financialPositionId}/{clientIncomeId}/{incomeId}',
        GET: ClientModuleBasePath + '/client/financial/getIncome/{clientId}/{financialPositionId}',
      },
      financialProtections: {
        GET_ALL_SUPERANNUATION_FUND_OWNERS:
          ClientModuleBasePath +
          '/client/financial/getSuperannuationFundAsset/client/{clientId}/financialPosition/{financialPositionId}',
      },
      familyAndLifestyleProtection: {
        POST_SAVE_FAMILY_AND_LIFESTYLE_PROTECTION:
          ClientModuleBasePath + '/client/createInsurance/financialPosition/{financialPositionId}',
        GET_ALL_FAMILY_AND_LIFESTYLE_PROTECTIONS_BY_CLIENT_ID:
          ClientModuleBasePath +
          '/client/getAllInsurance/client/{clientId}/financialPosition/{financialPositionId}',
        //todo
        GET_INSURANCE_PROTECTION_DETAIL_BY_PROTECTION_DETAIL_ID:
          ClientModuleBasePath + '/client/getInsurance/insurance/{insuranceDetailId}',
        PATCH_UPDATE_INSURANCE_PROTECTION_DETAIL:
          ClientModuleBasePath +
          '/client/updateInsurance/financialPosition/{financialPositionId}/insurance/{insuranceDetailId}',
        DELETE_FAMILY_AND_LIFESTYLE_PROTECTION_BY_INSURANCE_DETAIL_ID:
          ClientModuleBasePath +
          '/client/deleteInsurance/financialPosition/{financialPositionId}/insurance/{insuranceDetailId}',
      },
    },
    clientQuestionnaire: {
      GET_CLIENT_QUESTIONNAIRES: basePath + '/questionnaire/{questionnaireId}',
      PUT_CREATE_OR_UPDATE_CLIENT_QUESTIONNAIRE:
        basePath + '/createClientQuestionnaires/questionnaires/{clientId}',
      PATCH_UPDATE_CLIENT_QUESTIONNAIRE:
        basePath + '/createQuestionnaires/questionnaires/{questionnaireDataId}', // all questionnaires with answers is submitted
      DELETE_CLIENT_QUESTIONNAIRE_QUESTION_BY_ID:
        basePath +
        '/deleteQuestionnaires/questionnaires/{questionnaireDataId}/question/{questionId}',

      POST_FILTER_CLIENT_QUESTIONNAIRES: basePath + '/questionnaires/filter',
      PATCH_UPDATE_QUESTIONNAIRE: basePath + '/questionnaire/update/{questionnaireId}',
      GET_STATUS_QUESTIONNAIRE: basePath + '/getQuestionnairesStatus/client/{clientId}',
    },
  },
  lead: {
    leadProfile: {
      GET_FIND_LEAD_BY_ID: basePath + '/leads/{leadId}',
      PUT_UPDATE_LEAD: '//!UPDATE LEAD API NOT THERE',
      DELETE_LEAD_BY_LEAD_ID: basePath + '/leads/{leadId}',
    },
    leadList: {
      GET_ALL_LEADS: basePath + '/leads',
      POST_SAVE_LEAD: basePath + '/leads',
    },
  },
  files: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsBasePath,
    POST_GENERATE_SIGNED_URL: UtilsBasePath + '/files',
    //generated url is called for http put upload request
    GET_ALL_SENT_FILES: UtilsBasePath + '/files/sent/{receiverId}',
    GET_ALL_RECEIVED_FILES: UtilsBasePath + '/files/received/{senderId}',
  },
  tasks: {
    POST_SAVE_TASKS: basePath + '/tasks',
    GET_ALL_TASKS: basePath + '/tasks',
    PUT_UPDATE_TASK: basePath + '/tasks/{taskId}',
    GET_FIND_TASK_BY_ID: basePath + '/tasks/{taskId}',
    DELETE_TASK_BY_TASK_ID: basePath + '/tasks/{taskId}',
  },
  composites: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsBasePath,
    GET_ALL_ENUMS: UtilsBasePath + '/enums',
  },
  goals: {
    POST_SAVE_GOAL: basePath + '/clients/{clientId}/goals',
    PATCH_UPDATE_CLIENT_GOAL: basePath + '/clients/{clientId}/goals/{goalId}',
    GET_ALL_GOALS: basePath + '/clients/{clientId}/goals',
    DELETE_GOAL: basePath + '/clients/{clientId}/goals/{goalId}',
  },
  teamMembers: {
    POST_SAVE_TEAM_MEMBER: basePath + '/team-members',
    GET_ALL_TEAM_MEMBERS: basePath + '/team-members',
    GET_TEAM_MEMBER_FOR_ACCOUNT_OWNER_BY_ID: basePath + '/team-members/{accountOwnerId}',
    PUT_UPDATE_TEAM_MEMBER: basePath + '/team-members/{teamMemberId}',
    DELETE_TEAM_MEMBER_BY_ID: basePath + '/team-members/{teamMemberId}',
    POST_UPLOAD_TEAM_MEMBER_PROFILE_PIC: basePath + '/team-members/{teamMemberId}',
  },
  email: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsBasePath,
    POST_SEND_EMAIL: UtilsBasePath + '/emails',
    GET_SENT_EMAILS: UtilsBasePath + '/emails/message',
  },
  emailTemplates: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsBasePath,

    // admin email templates
    GET_ALL_ADMIN_EMAIL_TEMPLATES: UtilsBasePath + '/adminEmailTemplates',
  },
  investorProfile: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_INVESTMENT_INVESTOR_PROFILE: SettingsModuleBasePath + '/settings/investorProfile',
    POST_CREATE_ASSET_CLASS_INVESTOR_PROFILE: SettingsModuleBasePath + '/settings/investorProfile',
    GET_INVESTOR_PROFILE: SettingsModuleBasePath + '/settings/investorProfile',
    PATCH_UPDATE_INVESTOR_PROFILE:
      SettingsModuleBasePath + '/settings/investorProfile/{investorProfileId}',
    DELETE_INVESTOR_PROFILE:
      SettingsModuleBasePath + '/settings/investorProfile/{investorProfileId}',
  },
  superannuationModelingSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_SUPERANNUATION_DEFAULT_SETTING:
      SettingsModuleBasePath + '/settings/superannuationModellingSettings',
    GET_SUPERANNUATION_DEFAULT_SETTING:
      SettingsModuleBasePath + '/settings/superannuationModellingSettings',
  },
  emailNotificationSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_EMAIL_NOTIFICATION_SETTINGS:
      SettingsModuleBasePath + '/settings/emailNotifications',
    GET_EMAIL_NOTIFICATION_SETTINGS: SettingsModuleBasePath + '/settings/emailNotifications',
    PATCH_UPDATE_EMAIL_NOTIFICATION_SETTINGS:
      SettingsModuleBasePath + '/settings/emailNotifications/{emailNotificationSettingId}',
  },
  insuranceModelingSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_INSURANCE_MODELING_SETTINGS:
      SettingsModuleBasePath + '/settings/insuranceModellingSettings',
    GET_INSURANCE_MODELING_SETTINGS:
      SettingsModuleBasePath + '/settings/insuranceModellingSettings',
    PATCH_UPDATE_INSURANCE_MODELING_SETTINGS:
      SettingsModuleBasePath + '/settings/insuranceModellingSettings/{insuranceModelingSettingsId}',
  },
  organizationSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_ORGANIZATION_SETTINGS: SettingsModuleBasePath + '/settings/organization',
    GET_ORGANIZATION_SETTINGS: SettingsModuleBasePath + '/settings/organization',
    PATCH_UPDATE_ORGANIZATION_SETTINGS:
      SettingsModuleBasePath + '/settings/organization/{organizationSettingsId}',
    PUT_UPLOAD_COMPANY_LOGO: '',
  },
  calculationSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    economicVariables: {
      POST_CREATE_ECONOMIC_VARIABLE_SETTING: SettingsModuleBasePath + '/settings/economicVariables',
      GET_ECONOMIC_VARIABLE_SETTINGS: SettingsModuleBasePath + '/settings/economicVariables',
      PATCH_UPDATE_ECONOMIC_VARIABLE_SETTINGS:
        SettingsModuleBasePath + '/settings/economicVariables/{economicVariablesSettingId}',
    },
  },
  clientAppSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_CLIENT_APP_SETTING: SettingsModuleBasePath + '/settings/clientApp',
    GET_CLIENT_APP_SETTING: SettingsModuleBasePath + '/settings/clientApp',
    PATCH_UPDATE_CLIENT_APP_SETTING:
      SettingsModuleBasePath + '/settings/clientApp/{clientAppSettingId}',
  },
  webFormSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_WEB_FORM_SETTING: SettingsModuleBasePath + '/settings/onboardingInformation',
    GET_WEB_FORM_SETTING: SettingsModuleBasePath + '/settings/onboardingInformation',
    PATCH_UPDATE_WEB_FORM_SETTING:
      SettingsModuleBasePath + '/settings/onboardingInformation/{onboardingInformationId}',
  },
  advisorProfiles: {
    GET_ADVISOR_PROFILE_DATA: basePath + '/profile',
    PUT_UPDATE_PROFILE: basePath + '/profile',
    POST_CREATE_PROFILE: basePath + '/profile',
    PUT_CHANGE_MY_PROFILE_PASSWORD: basePath + '/password',
  },
  fileNotes: {
    POST_SAVE_FILE_NOTE: basePath + '/client/fileNote/client/{clientId}',
    GET_ALL_FILE_NOTES: basePath + '/client/fileNote/client/{clientId}',
    PATCH_UPDATE_FILE_NOTE: basePath + '/client/fileNote/client/{clientId}/fileNote/{fileNoteId}',
    DELETE_FILE_NOTE: basePath + '/client/fileNote/client/{clientId}/fileNote/{fileNoteId}',
  },

  settings: {
    GET_PROFILE_SETTINGS: basePath + '/profile',
  },
  payments: {
    moduleVariable: 'PAYMENTS',
    moduleBasePath: PaymentsModuleBasePath,
    POST_SAVE_PAYMENT_INFORMATION: PaymentsModuleBasePath + '/payments',
    PUT_APPROVE_OR_REJECT_INCOMING_PAYMENT: PaymentsModuleBasePath + '/payments',
    GET_ALL_PAYMENTS_OR_FILTERED_PAYMENTS:
      PaymentsModuleBasePath + '/payments?status={status}&payerId={payerId}&payeeId={payeeId}',
  },
  questionnaireTemplate: {
    template: {
      POST_FILTER_QUESTIONNAIRE_TEMPLATES: basePath + '/questionnaire-template/template/filter',
      GET_FILTER_ASSIGNED_MEMBERS:
        basePath + '/questionnaire-template/{templateId}/assigned-members',
    },
    tag: {
      POST_FILTER_QUESTIONNAIRE_TEMPLATES_TAGS:
        basePath + '/questionnaire-template/template-tag/filter',
    },
  },
};

export { API_URL };
