import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import ClientAdvisorCard from './cards/ClientAdvisorCard';
import ClientAdvisorFirmCard from './cards/ClientAdvisorFirmCard';
import ClientDocumentsCard from './cards/ClientSentFilesCard';
import ClientFilesCard from './cards/ClientReceivedFilesCard';
import FinancialInformationDashboard from './cards/FinancialInformationDashboard';

import {
  requestGetAllFilesReceived,
  requestGetAllFilesSent,
} from 'redux/Actions/filesUploadAction';
import { requestGetAllEmailsSentByUser } from 'redux/Actions/emailAction';
const Dashboard = () => {
  /**
   * Redux integration
   */
  const dispatch = useDispatch();

  //client reducer
  const clientReducer = useSelector((state) => state.clientReducer);
  const { selectedClientDetail } = clientReducer;
  /**
   * Request get all sent files by user
   */
  const handleRequestGetSentFiles = () => {
    const rootUser = selectedClientDetail?.rootUser;

    const fileRequestData = {
      receiverId: rootUser,
    };

    selectedClientDetail?.rootUser && dispatch(requestGetAllFilesSent(fileRequestData));
  };
  const handleRequestGetReceivedFiles = () => {
    const rootUser = selectedClientDetail?.rootUser;

    const fileRequestData = {
      senderId: rootUser,
    };

    selectedClientDetail?.rootUser && dispatch(requestGetAllFilesReceived(fileRequestData));
  };
  /**
   * Handle get all emails sent by user
   */
  const handleGetAllEmailsSentByUser = () => {
    selectedClientDetail._id && dispatch(requestGetAllEmailsSentByUser());
  };

  // effect on mount
  useEffect(() => {
    handleGetAllEmailsSentByUser();
    handleRequestGetSentFiles();
    handleRequestGetReceivedFiles();
  }, [selectedClientDetail]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <ClientAdvisorCard />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <ClientAdvisorFirmCard />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <ClientDocumentsCard />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <ClientFilesCard />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FinancialInformationDashboard />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
