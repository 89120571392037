import { useEffect } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Signature from '@uiw/react-signature';
import TextError from './formik/TextError';

const SignatureField = (props) => {
  const { signatureFieldRef, error, helperText, defaultPoints, ...restProps } = props;
  /**
   * Uses app theme
   */
  const theme = useTheme();
  /**
   * Effect on default point
   */
  useEffect(() => {
    if(Array.isArray(defaultPoints)){
      signatureFieldRef?.current?.dispatch?.(Object.assign({}, (defaultPoints || [])));
      //FIXES - defaultPoints not rerendered on change
    }
  }, [defaultPoints, signatureFieldRef]);

  return (
    <Box>
      <Signature
        ref={signatureFieldRef}
        width={345}
        height={168}
        style={{
          width: 345,
          height: 168,
          border:
            '1px solid ' + (error ? theme?.palette?.error?.main : theme?.palette?.grey?.[400]),
          borderRadius: theme?.shape?.globalBorderRadius,
        }}
        {...restProps}
      />
      <TextError>{(error && helperText) || ''}</TextError>
    </Box>
  );
};

export default SignatureField;
