import React from 'react';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  SvgIcon,
} from '@mui/material';

// project imports
// import { MENU_OPEN, SET_MENU } from 'store/actions';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  /**
   * Uses app theme
   */
  const theme = useTheme();
  /**
   * Uses react-router-dom location
   */
  const location = useLocation();
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  //layout store
  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

  const clientQuestionnairesReducer = useSelector((state) => state.clientQuestionnairesReducer);

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    item?.iconViewBox ? (
      <SvgIcon viewBox={item?.iconViewBox}>
        <Icon stroke={1.5} size="1.3rem" />
      </SvgIcon>
    ) : (
      <Icon stroke={1.5} size="1.3rem" />
    )
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: customization.isOpen.findIndex((url) => url === item?.url) > -1 ? 8 : 6,
        height: customization.isOpen.findIndex((url) => url === item?.url) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (url) => {
    // dispatch({ type: MENU_OPEN, url });
    dispatch({ type: 'customization_MENU_OPEN', url });
    if (matchesSM) {
      dispatch({ type: 'customization_SET_MENU', opened: false });
      // dispatch({ type: SET_MENU, opened: false });
    }
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((url) => url === item.url);
    if (currentIndex > -1) {
      // dispatch({ type: MENU_OPEN, url: item.url });
      dispatch({ type: 'customization_MENU_OPEN', url: item.url });
    }
    // eslint-disable-next-line
  }, []);
  /**
   * Effect on Path changed
   */
  useEffect(() => {
    if (
      !(customization.isOpen.findIndex((url) => url === location?.pathname) > -1) &&
      item.url === location?.pathname
    ) {
      dispatch({ type: 'customization_MENU_OPEN', url: location?.pathname });
    }
  }, [location?.pathname]);

  const getCustomChipLabel = () => {
    return clientQuestionnairesReducer?.getStatusQuestionnaireRequestSuccessData?.filter?.(
      (data) =>
        data.status !== 'Submitted' &&
        data.status !== 'Reviewed' &&
        data.questionnaireTitle !== 'Main Questionnaire' &&
        data.questionnaireTemplate
    )?.length;
  };

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 0.8 ? 0.8 : 0.8,
        pl: `${level * 24}px`,
      }}
      selected={customization.isOpen.findIndex((url) => url === item.url) > -1}
      onClick={() => itemHandler(item.url)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36, mr: 2 }}>
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={
              customization.isOpen.findIndex((url) => url === item.url) > -1 ? 'h5' : 'body1'
            }
            color="inherit"
            sx={{
              fontWeight: 500,
              '&:hover': {
                fontWeight: 500,
              },
            }}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.subMenuCaption }}
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={getCustomChipLabel()}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
