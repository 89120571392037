import { useMemo } from 'react';

import { Button, Collapse, Fade, Grid, Grow, Paper, Typography } from '@mui/material';

import { useFormikContext } from 'formik';

import { gridSpacing } from 'store/constant';

import FormikTextField from 'common/formik/FormikTextField';
import ControlledRadioGroup from 'common/material/ControlledRadioGroup';

const BooleanAndTextAnswer = (props) => {
  const { questionData, questionnaireIndex, questionType, sectionIndex, handleFieldBlur } = props;
  /**
   * Uses formik context
   */
  const {
    values: questionnaireValues,
    setFieldValue,
    setFieldTouched,
    errors: sectionErrors,
    touched: sectionTouched,
  } = useFormikContext();

  const booleanAnswer =
    questionnaireValues?.[questionnaireIndex]?.answer?.[
      questionType === 'CLIENT' ? 'client' : 'partner'
    ]?.booleanAnswer;
  const booleanAnswerError =
    sectionTouched?.[questionnaireIndex]?.answer?.[questionType === 'CLIENT' ? 'client' : 'partner']
      ?.booleanAnswer &&
    sectionErrors?.[questionnaireIndex]?.answer?.[questionType === 'CLIENT' ? 'client' : 'partner']
      ?.booleanAnswer;
  //_boolean and text answer memo
  const isBooleanAndTextAnswerYes = useMemo(() => {
    let isYes = false;
    if (questionData?.fieldType === 'booleanAndText' && booleanAnswer) {
      isYes = !!booleanAnswer;
    }
    return isYes;
  }, [booleanAnswer, questionData?.fieldType]);

  /**
   * Handle clear radio answer
   * @param {*} event
   * @param {*} questionIndex
   */
  const handleClearRadioAnswer = (event, questionIndex) => {
    const radioValue = null;
    const textValue = '';
    setFieldValue &&
      setFieldValue(
        `[${questionIndex}].answer.[${
          questionType === 'CLIENT' ? 'client' : 'partner'
        }].booleanAnswer`,
        radioValue,
        true
      );
    setFieldValue &&
      setFieldValue(
        `[${questionIndex}].answer.[${
          questionType === 'CLIENT' ? 'client' : 'partner'
        }].stringAnswer`,
        textValue,
        true
      );
    setTimeout(() => {
      setFieldTouched &&
        setFieldTouched(
          `[${questionIndex}].answer.[${
            questionType === 'CLIENT' ? 'client' : 'partner'
          }].booleanAnswer`,
          true,
          true
        );
      setFieldTouched &&
        setFieldTouched(
          `[${questionIndex}].answer.[${
            questionType === 'CLIENT' ? 'client' : 'partner'
          }].stringAnswer`,
          true,
          true
        );
      handleFieldBlur && handleFieldBlur(event);
    }, 200);
  };
  /**
   * Handle change radio answer
   * @param {*} event
   * @param {*} questionIndex
   */
  const handleRadioChange = (event, questionIndex) => {
    const radioValue = event.target.value === 'Yes';
    setFieldValue &&
      setFieldValue(
        `[${questionIndex}].answer.[${
          questionType === 'CLIENT' ? 'client' : 'partner'
        }].booleanAnswer`,
        radioValue,
        true
      );
    setTimeout(() => {
      setFieldTouched &&
        setFieldTouched(
          `[${questionIndex}].answer.[${
            questionType === 'CLIENT' ? 'client' : 'partner'
          }].booleanAnswer`,
          true,
          true
        );
      handleFieldBlur && handleFieldBlur(event);
    }, 200);
  };
  return (
    <Collapse
      unmountOnExit
      sx={{ width: '100%' }}
      in={questionData?.fieldType === 'booleanAndText'}
    >
      <Grow in={questionData?.fieldType === 'booleanAndText'}>
        <Grid container spacing={gridSpacing} justifyContent="flex-start">
          <Grid item xs={'auto'}>
            <Paper sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
              <ControlledRadioGroup
                radioGroupProps={{
                  name: `[${questionnaireIndex}].answer.[${
                    questionType === 'CLIENT' ? 'client' : 'partner'
                  }].booleanAnswer`,
                  onBlur: handleFieldBlur,
                }}
                id={
                  'booleanAndText-booleanAnswer-' +
                  questionnaireIndex +
                  '-section-' +
                  sectionIndex +
                  '-person-' +
                  questionData?.personType
                }
                groupLabel=""
                required={!!questionData?.isRequired}
                dataSet={[
                  {
                    value: 'Yes',
                    label: 'Yes',
                  },
                  {
                    value: 'No',
                    label: 'No',
                  },
                ]}
                value={booleanAnswer ? 'Yes' : booleanAnswer === false ? 'No' : null}
                handleChange={(event) => handleRadioChange(event, questionnaireIndex)}
                direction="row"
              />
              <Collapse in={typeof booleanAnswer === 'boolean'}>
                <Grow in={typeof booleanAnswer === 'boolean'}>
                  <Fade in={typeof booleanAnswer === 'boolean'}>
                    <Button onClick={(event) => handleClearRadioAnswer(event, questionnaireIndex)}>
                      Clear Answer
                    </Button>
                  </Fade>
                </Grow>
              </Collapse>
              <Collapse sx={{ width: '100%' }} in={!!booleanAnswerError} unmountOnExit>
                <Grow in={!!booleanAnswerError}>
                  <Fade in={!!booleanAnswerError}>
                    <Grid item xs={12}>
                      <Typography color="error">
                        Answer is required! Please select one of Yes/No to proceed.
                      </Typography>
                    </Grid>
                  </Fade>
                </Grow>
              </Collapse>
            </Paper>
          </Grid>
          <Grid item xs={12} sx={{ padding: '0 !important' }} />
          <Collapse in={isBooleanAndTextAnswerYes} sx={{ width: '100%', padding: '0 0 0 1rem' }}>
            <Grow in={isBooleanAndTextAnswerYes}>
              <Fade in={isBooleanAndTextAnswerYes}>
                <Grid container justifyContent="flex-start" spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <FormikTextField
                      placeholder="Enter your answer"
                      name={`[${questionnaireIndex}].answer.[${
                        questionType === 'CLIENT' ? 'client' : 'partner'
                      }].stringAnswer`}
                      type="text"
                      label={''}
                      required={!!questionData?.isRequired}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Fade>
            </Grow>
          </Collapse>
        </Grid>
      </Grow>
    </Collapse>
  );
};

export default BooleanAndTextAnswer;
