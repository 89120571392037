import React from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { currencyFormat } from 'utils/currencyFormat';

function titleCase(str) {
  let splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

const CustomTooltip = ({ active, payload, label, percent }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          boxShadow: '0px 0px 15px  grey',
          zIndex: 900,
          minWidth: '10rem',
          borderRadius: '0.5rem 0.5rem 0.5rem 0.5rem',
          backgroundColor: '#FFFFFF',
          opacity: 0.9,
          padding: '0.1rem 1rem 0.1rem 1rem',
        }}
      >
        <p style={{ textAlign: 'center' }}>
          {titleCase(payload[0].name)}
          &nbsp; - &nbsp;
          {currencyFormat(payload[0]['value'])}
        </p>
      </div>
    );
  }

  return null;
};

const PieChartMain = (props) => {
  const { data, value, COLORS } = props;

  PieChartMain.defaultProps = {
    value: 'value',
  };

  return (
    <ResponsiveContainer width="100%" height={100}>
      <PieChart width="100%" height="100%">
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius="50"
          fill="#8884d8"
          paddingAngle={0}
          dataKey={value}
        >
          {data &&
            data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key={index} />)}
        </Pie>
        <Tooltip content={<CustomTooltip />} />

        {/* <Label>any string or number</Label> */}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartMain;
