import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isDocumentRequestLoading: false,
  documentRequestSuccessData: [],
  documentRequestFailedData: null,
};

export const clientDocumentsReducer = createReducer(initialState, {
  //_get documents
  clientDocumentsGetRequest: (state) => {
    state.isDocumentRequestLoading = true;
    state.documentRequestSuccessData = [];
    state.documentRequestFailedData = null
  },
  clientDocumentsGetRequestSuccess: (state, action) => {
    state.isDocumentRequestLoading = false;
    state.documentRequestSuccessData = action.payload;
    state.documentRequestFailedData = null
  },
  clientDocumentsGetRequestFailed: (state, action) => {
    state.isDocumentRequestLoading = false;
    state.documentRequestSuccessData = [];
    state.documentRequestFailedData = action.payload
  },

});
