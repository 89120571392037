import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

const FormikTextField = (props) => {
  const {
    label,
    type,
    name,
    error,
    shrink,
    helperText,
    disabled,
    maxDate,
    minDate,
    multiline,
    rows,
    language,
    direction,
    required,
    maxlength,
    maxValue,
    variant,
    placeholder,
    backColor,
    hasLabel,
    hasError,
    ...rest
  } = props;

  return (
    <Field
      component={TextField}
      label={label}
      name={name}
      id={name}
      variant={variant || 'outlined'}
      fullWidth
      margin="dense"
      placeholder={placeholder || 'Please Enter'}
      type={type}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      InputLabelProps={{
        shrink: shrink,
      }}
      {...rest}
    />
  );
};

export default FormikTextField;
