import React from 'react';
import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import { useLottie } from 'lottie-react';

import * as pageNotFoundAnimationData from 'assets/animation/page-not-found.json';
import MainCard from './cards/MainCard';
import { Home, HomeRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { IconArrowBackUp } from '@tabler/icons';
import { gridSpacing } from 'store/constant';

const PageNotFound = (props) => {
  const { height } = props;
  /**
   * React router dom navigation
   */
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pageNotFoundAnimationData,
  };
  const style = {
    minHeight: 200,
    minWidth: 200,
    height: height || 'calc(50vh - 4rem)',
    width: 'calc(50vw - 4rem)',
  };
  const { View } = useLottie(defaultOptions, style);
  /**
   * Navigate back to login
   */
  const handleNavigateToHome = () => {
    navigate('/');
  };
  /**
   * Navigate to back page
   */
  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <MainCard
      sx={{
        height: 'calc(100vh - 2rem)',
        width: 'calc(100vw - 2rem)',
        margin: '1rem',
        padding: '1rem',
        position: 'relative',
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={gridSpacing}
        sx={(theme) => ({
          position: 'fixed',
          top: '1rem',
          right: '1rem',
          zIndex: theme.zIndex.fab,
          padding: gridSpacing,
        })}
      >
        <IconButton
          id="notFoundPageNavigateToBackBtn"
          onClick={handleNavigateBack}
          sx={{ fontSize: '5rem' }}
        >
          <Icon sx={{ fontSize: '5rem' }}>
            <IconArrowBackUp width="5rem" height="5rem" />
          </Icon>
        </IconButton>
        <IconButton id="notFoundPageNavigateToHomeBtn" onClick={handleNavigateToHome}>
          <HomeRounded sx={{ fontSize: '5rem' }} />
        </IconButton>
      </Stack>
      <Box
        sx={{
          height: height ? height : 'calc(100vh - 4rem)',
          width: 'calc(100vw - 4rem)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {View}
      </Box>

      <Stack
        direction="row"
        sx={(theme) => ({
          position: 'fixed',
          bottom: '1rem',
          left: '0',
          zIndex: theme.zIndex.fab,
          width: '100%',
        })}
      >
        <Typography
          variant="caption"
          sx={(theme) => ({
            width: '100%',
            fontSize: '3rem !important',
            textAlign: 'center',
            fontFamily: 'monospace',
            color: `${theme.palette?.grey?.[400]} !important`,
          })}
        >
          Page Not Found !
        </Typography>
      </Stack>
    </MainCard>
  );
};

export default PageNotFound;
