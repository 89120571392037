import React from 'react';
import ActionIconButtonCard from './ActionIconButtonCard';

import { ReactComponent as FamilyProtectionsIcon } from 'assets/images/icons/shield.svg';

const FamilyAndLifetimeActionCard = (props) => {
  const { selectedTabButton, onSelectTabButton } = props;
  return (
    <ActionIconButtonCard
      actionLabel="Family and Lifetime Protections"
      actionType="protection"
      actionIcon={FamilyProtectionsIcon}
      isSelected={selectedTabButton === 'protection'}
      handleSelectActionCard={onSelectTabButton}
    />
  );
};
export default FamilyAndLifetimeActionCard;
