import {
  Box,
  Button,
  Collapse,
  Fade,
  Grid,
  Grow,
  Icon,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import FormikTextField from 'common/formik/FormikTextField';
import { useFormikContext } from 'formik';
import { memo, useCallback, useMemo, useRef } from 'react';
import { gridSpacing } from 'store/constant';

import { useTheme } from '@mui/material/styles';
import SignatureField from 'common/SignatureField';
import FormikDatePicker from 'common/formik/FormikDatePicker';
import { IconEraser } from '@tabler/icons';
import { DragHandleRounded } from '@mui/icons-material';

const SignatureAnswer = (props) => {
  const { questionData, questionnaireIndex, questionType } = props;

  const formikContext = useFormikContext();

  const theme = useTheme();
  //_ signature ref
  const signatureFieldRef = useRef(null);

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const dragHolderMatch = useMediaQuery('(max-width:500px)');

  /**
   * Signature points memoized
   */
  const signaturePoints = useMemo(() => {
    const formikValues = formikContext.values;
    let points = '[]';
    if (
      typeof formikValues?.[questionnaireIndex]?.answer?.[
        questionType === 'CLIENT' ? 'client' : 'partner'
      ]?.signatureAgreementAnswers?.signaturePoints === 'string'
    ) {
      points =
        formikValues?.[questionnaireIndex]?.answer?.[
          questionType === 'CLIENT' ? 'client' : 'partner'
        ]?.signatureAgreementAnswers?.signaturePoints;
    }
    return points;
  }, [formikContext?.values]);
  /**
   * Handle change signature pointer
   */
  const handleOnPointerChanged = useCallback(
    (value, isReset = false) => {
      let newPoints = '[]';
      if (!isReset) {
        const prevPointsString =
          formikContext?.values?.[questionnaireIndex]?.answer?.[
            questionType === 'CLIENT' ? 'client' : 'partner'
          ]?.signatureAgreementAnswers?.signaturePoints || '[]';
        let parsedPoints = [];
        try {
          parsedPoints = JSON.parse(prevPointsString);
        } catch (e) {}
        newPoints = [
          ...(Array.isArray(parsedPoints) ? parsedPoints : []),
          ...(value?.length ? [value] : []),
        ].filter((arr) => arr?.length);
      }

      const stringifiedPoints = JSON.stringify(newPoints);
      formikContext.setFieldValue(
        `[${questionnaireIndex}].answer.${
          questionType === 'CLIENT' ? 'client' : 'partner'
        }.signatureAgreementAnswers.signaturePoints`,
        stringifiedPoints
      );
      formikContext.setFieldTouched(
        `[${questionnaireIndex}].answer.${
          questionType === 'CLIENT' ? 'client' : 'partner'
        }.signatureAgreementAnswers.signaturePoints`,
        true
      );
    },
    [questionnaireIndex, formikContext]
  );
  /**
   * handle clear sign
   */
  const handleClearSign = () => {
    signatureFieldRef?.current?.clear?.();
    handleOnPointerChanged([], true);
  };
  /**
   * Signature default points
   */
  const defaultPoints = useMemo(() => {
    let points = {
      client: [],
      partner: [],
    };
    if (signaturePoints) {
      try {
        let clientPoints = JSON.parse(signaturePoints);
        if (Array.isArray(clientPoints)) {
          points = {
            ...points,
            [questionType === 'CLIENT' ? 'client' : 'partner']: [...clientPoints],
          };
        }
      } catch (error) {}
    }

    return points;
  }, [signaturePoints]);
  return (
    <Collapse
      unmountOnExit
      sx={{ width: '100%' }}
      in={questionData?.fieldType === 'signatureAgreement'}
    >
      <Grow in={questionData?.fieldType === 'signatureAgreement'}>
        <Paper sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Grid container spacing={gridSpacing} justifyContent="space-between">
            <Grid
              item
              xs
              md={12}
              lg="auto"
              sx={{
                overflowX: 'auto',
                display: 'flex',
                justifyContent: 'center',
                maxWidth: matchesXs ? 'calc(100vw - 8rem)' : undefined,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  flex: 1,
                  flexGrow: 0,
                  maxWidth: 'inherit',
                  width: 345,
                }}
              >
                <SignatureField
                  fill={theme?.palette?.primary?.dark}
                  id={'partner-signature-' + questionnaireIndex}
                  defaultPoints={
                    defaultPoints?.[questionType === 'CLIENT' ? 'client' : 'partner'] || []
                  }
                  onPointer={(value) => handleOnPointerChanged(value)}
                  signatureFieldRef={signatureFieldRef}
                  error={
                    formikContext?.touched?.[questionnaireIndex]?.answer?.[
                      questionType === 'CLIENT' ? 'client' : 'partner'
                    ]?.signatureAgreementAnswers?.signaturePoints &&
                    formikContext?.errors?.[questionnaireIndex]?.answer?.[
                      questionType === 'CLIENT' ? 'client' : 'partner'
                    ]?.signatureAgreementAnswers?.signaturePoints
                  }
                  helperText={
                    formikContext?.errors?.[questionnaireIndex]?.answer?.[
                      questionType === 'CLIENT' ? 'client' : 'partner'
                    ]?.signatureAgreementAnswers?.signaturePoints
                  }
                />
                <Button
                  endIcon={<IconEraser />}
                  variant="outlined"
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: '0.5rem',
                    left: '0.5rem',
                  }}
                  onClick={handleClearSign}
                >
                  Clear
                </Button>
                <Fade
                  in={!defaultPoints?.[questionType === 'CLIENT' ? 'client' : 'partner']?.length}
                  unmountOnExit
                >
                  <Typography
                    sx={{
                      width: 345,
                      position: 'absolute',
                      top: '40%',
                      textAlign: 'center',
                      fontSize: '1.5rem',
                      fontWeight: theme?.typography?.fontWeightMedium,
                      color: theme?.palette?.grey?.[300],

                      '-webkit-touch-callout': 'none' /* iOS Safari */,
                      '-webkit-user-select': 'none' /* Safari */,
                      '-khtml-user-select': 'none' /* Konqueror HTML */,
                      '-moz-user-select': 'none' /* Old versions of Firefox */,
                      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
                      'user-select': 'none' /* Non-prefixed version, currently
                                              supported by Chrome, Edge, Opera and Firefox */,
                      pointerEvents: 'none',
                    }}
                  >
                    Signature
                  </Typography>
                </Fade>
                <Fade in={dragHolderMatch} unmountOnExit>
                  <Paper
                    sx={{
                      backgroundColor: theme?.palette?.grey?.[200],
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 345,
                    }}
                    variant="elevation"
                  >
                    <Icon sx={{ cursor: 'grab' }}>
                      <DragHandleRounded />
                    </Icon>
                  </Paper>
                </Fade>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Grid container alignItems="center" spacing={gridSpacing} justifyContent="flex-end">
                <Grid item xs={12} sx={{ marginTop: '0.875rem' }}>
                  <FormikTextField
                    name={`[${questionnaireIndex}].answer.${
                      questionType === 'CLIENT' ? 'client' : 'partner'
                    }.signatureAgreementAnswers.name`}
                    label="Name"
                    placeholder="Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikDatePicker
                    name={`[${questionnaireIndex}].answer.${
                      questionType === 'CLIENT' ? 'client' : 'partner'
                    }.signatureAgreementAnswers.date`}
                    label="Date"
                    placeholder="Signed Date"
                    value={
                      formikContext?.values?.[questionnaireIndex]?.answer?.[
                        questionType === 'CLIENT' ? 'client' : 'partner'
                      ]?.signatureAgreementAnswers?.date
                    }
                    setFieldValue={formikContext?.setFieldValue}
                    error={
                      formikContext?.errors?.[questionnaireIndex]?.answer?.[
                        questionType === 'CLIENT' ? 'client' : 'partner'
                      ]?.signatureAgreementAnswers?.date
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grow>
    </Collapse>
  );
};

export default memo(SignatureAnswer);
