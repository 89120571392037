import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';

/**
 * Get Organization Setting Action Request
 * @param {*} handleGetOrganizationSettingSuccessCallback
 * @param {*} handleGetOrganizationSettingFailedCallback
 * @returns
 */
export const requestGetOrganizationSetting =
  (handleGetOrganizationSettingSuccessCallback, handleGetOrganizationSettingFailedCallback) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getOrganizationSettingRequest' });
    const organizationSettingGetRequestURL = API_URL.organizationSetting.GET_ORGANIZATION_SETTINGS;
    const requestData = {
      url: organizationSettingGetRequestURL,
      method: 'GET',
    };
    const successCallback = (response) => {
      dispatch({ type: 'getOrganizationSettingRequestSuccess', payload: response });
      handleGetOrganizationSettingSuccessCallback &&
        handleGetOrganizationSettingSuccessCallback(response);
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getOrganizationSettingRequestFailed', payload: error });
      handleGetOrganizationSettingFailedCallback &&
        handleGetOrganizationSettingFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
  };
