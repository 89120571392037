import React, { useEffect, useMemo, useRef } from 'react';
import {
  HtmlEditor,
  Image,
  Video,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  FileManager,
  Count,
  Table,
  Resize,
  ToolbarType,
} from '@syncfusion/ej2-react-richtexteditor';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
// import classNames from 'classnames';
// import Store from 'redux/Store';

import { useCommonStyles } from 'styles/styles';
// import { API_URL } from 'shared/API_URLS';

/**
 * Default Settings fro File Manager
 * * Only Advisor Portal has ability to access the file manager
 */
const defaultFileManagerSettings = {
  enable: true,
  // path: '/Pictures/Food',
  ajaxSettings: {
    url: '',
    getImageUrl: '',
    uploadUrl: '',
    downloadUrl: '',
  },
};
const SyncFusionEditor = (props) => {
  const {
    error,
    fileManagerCustomSettings,
    isResizableEditor,
    label,
    name,
    editorId,
    editorContent,
    editorPlaceholder,
    injectProps,
    editorValue,
    editorValueTemplate,
    onEditorChange,
    bindDropListener,
    isAutoSaveOnIdle,
    saveOnMillisecondsInterval,
    handleEditorCreationCallback,
    disabled,
    ...otherProps
  } = props;
  /**
   * Uses common styles
   */
  const commonClasses = useCommonStyles();
  /**
   * File manager settings
   */
  const fileManagerSettings = defaultFileManagerSettings || fileManagerCustomSettings;

  const toolbarSettings = {
    items: [
      'Undo',
      'Redo',
      '|',
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Indent',
      'Outdent',
      '|',
      'CreateLink',
      // 'FileManager',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      //
      // '|',
      // 'Video',
    ],
    type: ToolbarType.Expand,
    // pasteCleanupSettings: {
    //     deniedTags: ['iframe', 'embed'],
    //     keepFormat: true,
    //     plainText: true,
    //     html: true,
    //     prompt: true
    // }
  };
  /**
   * Editor reference
   */
  const localEditorRef = useRef();
  /**
   * Editor content data
   */
  const editorContentData = useMemo(() => {
    let tempEditorContentData = <></>;
    if (editorContent) {
      tempEditorContentData = editorContent;
    }
    return tempEditorContentData;
  }, [editorContent]);

  /**
   * Editor Created Event bindings
   * @param {*} args
   */
  const handleOnEditorCreated = (args) => {
    let editor = localEditorRef?.current;
    if (typeof bindDropListener === 'function') {
      if (editor?.addEventListener) {
        editor.addEventListener('drop', bindDropListener);
      }
    }
    // editor creation callback
    handleEditorCreationCallback && handleEditorCreationCallback(args, editor);
  };
  /**
   * Effect on Editor Creation
   */
  useEffect(() => {
    let editorNode = localEditorRef?.current;
    if (editorNode) {
      handleOnEditorCreated(editorNode);
    }
  }, [localEditorRef?.current]);
  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={!!error}
      sx={{
        '& .e-richtexteditor':
          (error && {
            borderColor: (theme) => theme.palette.error.main + ' !important',
            '& .e-rte-content': {
              borderBottomColor: (theme) => theme.palette.error.main + ' !important',
            },
          }) ||
          {},

        '& .e-richtexteditor.e-rte-tb-expand .e-rte-toolbar .e-toolbar-extended.e-popup-open': {
          width: '100% !important',
          left: '0 !important',
        },
      }}
    >
      {label && (
        <InputLabel
          sx={(theme) => ({
            backgroundColor: '#fafafa',
            borderRadius: '5px',
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
            padding: '1px',
          })}
          shrink
          htmlFor={editorId || 'editor_field'}
          error={!!error}
        >
          {label}
        </InputLabel>
      )}
      <RichTextEditorComponent
        id={editorId || 'editor_field'}
        fileManagerSettings={fileManagerSettings}
        toolbarSettings={toolbarSettings}
        placeholder={editorPlaceholder || null}
        showCharCount={true}
        value={editorValue || null}
        valueTemplate={editorValueTemplate || null}
        height={450}
        enableResize={true}
        ref={localEditorRef}
        created={handleOnEditorCreated}
        change={onEditorChange}
        autoSaveOnIdle={isAutoSaveOnIdle}
        saveInterval={1000}
        className={
          disabled
            ? commonClasses?.classes?.richTextEditorDisabledClass
            : commonClasses?.classes?.richTextEditorDefaultClass
        }
        htmlAttributes={{ name: name || editorId }}
        readonly={!!disabled}
        {...otherProps}
      >
        {editorContentData}
        <Inject
          services={[
            Toolbar,
            Image,
            Link,
            HtmlEditor,
            QuickToolbar,
            FileManager,
            Count,
            Table,
            Resize,
            Video,
          ]}
          {...(injectProps || {})}
        />
      </RichTextEditorComponent>
      <FormHelperText
        id={'error-helperText-' + (editorId || 'editor_field')}
        sx={(theme) => ({
          color: theme.palette.error.main,
        })}
        error={!!error}
      >
        {error || ' '}
      </FormHelperText>
    </FormControl>
  );
};

SyncFusionEditor.defaultProps = {
  isAutoSaveOnIdle: true,
  saveOnMillisecondsInterval: 1000,
};

export default SyncFusionEditor;
