import React, { useState } from 'react';
import { Avatar, Box, Button, Divider, Grid, SvgIcon, Typography } from '@mui/material';
// commons
import MainCard from 'ui-component/cards/MainCard';
// assets
// icons
import { ReactComponent as UserMale } from 'assets/images/users/user_male.svg';
import ContactAdvisorModal from '../modals/ContactAdvisorModal';
import { useSelector } from 'react-redux';

const ClientAdvisorCard = (props) => {
  const [isNewMessageModalOpen, setIsNewMessageModalOpen] = useState(false);

  //client store
  const clientReducer = useSelector((state) => state?.clientReducer);
  const selectedClientDetail = clientReducer?.selectedClientDetail;
  const readAndWriteAccess = selectedClientDetail?.readAndWriteAccess;
  //Email Post Store
  const emailPostsReducer = useSelector((state) => state?.emailPostsReducer);
  // const isGetAllEmailsSentLoading = emailPostsReducer?.isGetAllEmailsSentLoading;
  const emailsSentByUserSuccessData = emailPostsReducer?.emailsSentByUserSuccessData;

  const messageCount =
    (emailsSentByUserSuccessData?.filter &&
      emailsSentByUserSuccessData.filter(
        (singleEmailSent) => singleEmailSent?.emailType === 'message'
      )?.length) ||
    0;
  const meetingRequestCount =
    (emailsSentByUserSuccessData?.filter &&
      emailsSentByUserSuccessData.filter(
        (singleEmailSent) => singleEmailSent?.emailType === 'meetingRequest'
      )?.length) ||
    0;
  const handleNewMessageModalOpen = () => {
    setIsNewMessageModalOpen(true);
  };

  const handleNewMessageModalClose = () => {
    setIsNewMessageModalOpen(false);
  };
  /**
   * Format statistic
   * @param {*} value
   * @returns
   */
  const formatStatistic = (value) => {
    return (value.toString && value.toString().padStart(2, '0')) || '00';
  };
  return (
    <MainCard content={false} sx={{ height: '100%', paddingY: 1 }}>
      <ContactAdvisorModal
        isOpened={isNewMessageModalOpen}
        handleClose={handleNewMessageModalClose}
        readAndWriteAccess={readAndWriteAccess}
      />
      <Grid container paddingX={0.25}>
        <Grid item xs={4}>
          <Box sx={{ paddingX: 2.25 }}>
            <Grid container justifyContent="center">
              <Typography
                sx={(theme) => ({
                  color: theme?.palette?.grey?.[750],
                  fontSize: '1.25rem',
                  fontWeight: 500,
                })}
              >
                Your Advisor
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Avatar
                variant="circular"
                sx={{
                  color: '#fff',
                  m: 1,
                  width: '5rem',
                  height: '5rem',
                }}
              >
                <SvgIcon
                  viewBox="0 0 75 75"
                  sx={{ width: '5rem', height: '5rem' }}
                  component={UserMale}
                />
              </Avatar>
            </Grid>
            <Grid container justifyContent="center">
              <Typography
                sx={(theme) => ({
                  color: theme?.palette?.primary.dark + '!important',
                })}
              >
                {selectedClientDetail?.adviceRelationship?.currentAdvisor || ''}
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Divider flexItem variant="fullWidth" orientation="vertical" />
        <Grid item xs>
          <Box sx={{ paddingX: 2.25 }}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid container item xs>
                <Grid container alignItems="center" justifyItems="center" justifyContent="center">
                  <Typography noWrap sx={(theme) => ({ color: theme.palette.grey?.[750] })}>
                    Message
                  </Typography>
                </Grid>
                <Grid container alignItems="center" justifyItems="center" justifyContent="center">
                  <Typography
                    variant="h1"
                    sx={(theme) => ({ color: theme.palette.blue[600] })}
                    noWrap
                  >
                    {formatStatistic(messageCount)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs>
                <Grid container alignItems="center" justifyItems="center" justifyContent="center">
                  <Typography noWrap sx={(theme) => ({ color: theme.palette.grey?.[750] })}>
                    Req. Meeting
                  </Typography>
                </Grid>
                <Grid container alignItems="center" justifyItems="center" justifyContent="center">
                  <Typography
                    variant="h1"
                    sx={(theme) => ({ color: theme.palette?.yellow?.[300] })}
                    noWrap
                  >
                    {formatStatistic(meetingRequestCount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                sx={(theme) => ({
                  mt: 3,
                  mb: 2,
                  ...theme.typography.primaryButton,
                })}
                onClick={(e) => {
                  handleNewMessageModalOpen && handleNewMessageModalOpen();
                }}
                disabled={!readAndWriteAccess}
              >
                Contact Advisor
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </MainCard>
  );
};
export default ClientAdvisorCard;
