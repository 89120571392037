import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router';

import LoadingSpinner from 'ui-component/LoadingSpinner';
import NoData from 'ui-component/NoData';
import MainCard from 'ui-component/cards/MainCard';
import QuestionSection from './QuestionSection';

import { gridSpacing } from 'store/constant';

import {
  requestGetClientQuestionnaire,
  requestGetStatusQuestionnaire,
  requestUpdateClientQuestionnaire,
} from 'redux/Actions/clientQuestionnairesAction';

const QuestionnaireForm = () => {
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  /**
   * Uses react-router-dom navigation
   */
  const navigate = useNavigate();
  /**
   * Uses react-router-dom search params
   */
  let { questionnaireId } = useParams();

  const loginUser = useSelector((state) => state.authenticationReducer?.loginData?.user);

  //questionnaire store
  const clientQuestionnairesReducer = useSelector((state) => state?.clientQuestionnairesReducer);
  const isGetClientQuestionnairesLoading =
    clientQuestionnairesReducer?.isGetClientQuestionnairesLoading;
  const isUpdateClientQuestionnaireLoading =
    clientQuestionnairesReducer?.isUpdateClientQuestionnaireLoading;

  //_refs
  const questionnaireRef = useRef({}); //to track changes and invoke the render effect
  //_states
  const [questionnaireSectionsData, setQuestionnaireSectionData] = useState({
    sections: [],
    isLoading: false,
    activeSectionIndex: 0,
    errorSectionIndexes: [],
  });
  const [leadDetails, setLeadDetails] = useState({
    data: {},
  });

  //Questionnaire Data
  const questionnairesInitialValues = useMemo(() => {
    return [...(leadDetails?.data?.questionnaires || [])];
  }, [leadDetails, questionnaireRef]);
  //Formik Initial Values
  const initialQuestionnaireValues = {
    questionnaires: questionnairesInitialValues,
  };
  /**
   * Active section data
   */
  const activeQuestionSectionData = useMemo(() => {
    let sectionData =
      questionnaireSectionsData?.activeSectionIndex >= 0 &&
      questionnaireSectionsData?.activeSectionIndex <=
        questionnaireSectionsData?.sections?.length - 1
        ? questionnaireSectionsData.sections?.[questionnaireSectionsData?.activeSectionIndex]
        : null;
    return sectionData;
  }, [questionnaireSectionsData?.activeSectionIndex, questionnaireSectionsData.sections]);

  /**
   * Handle get questionnaire sections
   */
  const handleGetQuestionnaireSectionData = useCallback(() => {
    const questionnairesArrayData = questionnairesInitialValues || [];
    let questionnaireCurrentSectionIndex = 0;
    let questionnaireSectionEndIndex = 0;

    // let questionnaireSections = [...(questionnaireSectionsData?.sections || [])];
    let questionnaireSections = [];
    if (
      !isGetClientQuestionnairesLoading &&
      Array.isArray(questionnairesArrayData) &&
      questionnaireRef
    ) {
      questionnairesArrayData.forEach((questionnaire, questionnaireIndex) => {
        const questionnaireSection = {
          sectionQuestions: [],
          sectionHeader: questionnaire?.headerTitle,
          personType: questionnaire?.personType,
          questionnaireHeaderKey: 0,
          questionnaireHeaderNextKey: 0,
          component: questionnaire?.component,
          headerType: questionnaire?.headerType,
        };
        if (questionnaire?.component === 'header' && questionnaire?.headerType === 'section') {
          questionnaireCurrentSectionIndex = questionnaireIndex;
          const nextFoundQuestionnaireSectionHeaderIndex = questionnairesArrayData
            .slice(questionnaireCurrentSectionIndex + 1, questionnairesArrayData?.length - 1)
            .findIndex(
              (questionnaireAfterSection) =>
                questionnaireAfterSection?.component === 'header' &&
                questionnaireAfterSection?.headerType === 'section'
            );
          if (nextFoundQuestionnaireSectionHeaderIndex > 0) {
            questionnaireSectionEndIndex =
              nextFoundQuestionnaireSectionHeaderIndex + questionnaireCurrentSectionIndex;
          } else if (
            questionnairesArrayData?.[questionnaireCurrentSectionIndex + 1]?.component === 'header'
          ) {
            questionnaireSectionEndIndex = questionnaireCurrentSectionIndex;
          } else {
            questionnaireSectionEndIndex = questionnairesArrayData?.length - 1;
          }
          questionnaireSection.questionnaireHeaderKey = questionnaireIndex;
          questionnaireSection.questionnaireHeaderNextKey = questionnaireSectionEndIndex + 1;
          const sectionStart = questionnaireCurrentSectionIndex;
          const sectionEnd = questionnaireSectionEndIndex;
          questionnaireSection.sectionQuestions = questionnairesArrayData
            .slice(sectionStart + 1, sectionEnd + 1)
            .map((questionnaire, questionIndex) => ({
              ...questionnaire,
              questionnaireKey: sectionStart + 1 + questionIndex,
              questionSectionKey: questionIndex,
            }));
          questionnaireSection.sectionIndexKey = questionnaireSections?.length || 0;
          questionnaireSections = [...questionnaireSections, questionnaireSection];
        }
      });
    }
    return questionnaireSections;
  }, [isGetClientQuestionnairesLoading, questionnaireRef, questionnairesInitialValues]);

  /**
   * Get all questionnaire data
   * @param {*} handleOnSuccess
   */
  const handleGetAllQuestionnaireData = (handleOnSuccess = null) => {
    const requestDetails = {
      questionnaireId,
    };
    const handleGetQuestionnaireRequestSuccessCallback = (response) => {
      handleOnSuccess && handleOnSuccess();
      let tempQuestionnaires =
        (Array.isArray(response?.questionnaires) && response.questionnaires) || [];

      setLeadDetails((prev) => ({
        data: {
          ...(response || {}),
          questionnaires: tempQuestionnaires.map((singleQuestionDetail) => ({
            ...singleQuestionDetail,
          })),
        },
      }));
    };
    const handleGetQuestionnaireRequestFailedCallback = (error) => {};
    dispatch(
      requestGetClientQuestionnaire(
        requestDetails,
        handleGetQuestionnaireRequestSuccessCallback,
        handleGetQuestionnaireRequestFailedCallback
      )
    );
  };
  /**
   * Handle formik submission
   * @param {*} values
   * @param {*} submitProps
   */
  const handleQuestionnaireFormSubmit = (values, submitProps) => {
    submitProps?.setSubmitting && submitProps.setSubmitting(true);
    const questionnaireAnswerRequestDetails = {
      requestBody: {
        questionnaires: values.questionnaires || [],
        status: 'Submitted',
        clientComments: '',
        advisorComments: '',
      },
      questionnaireId,
    };
    const handleUpdateSuccessCallback = (response) => {
      submitProps?.setSubmitting && submitProps.setSubmitting(false);
      // handleGetAllQuestionnaireData && handleGetAllQuestionnaireData();

      const clientDetailRequestData = {
        clientId: loginUser?._id,
      };
      dispatch(requestGetStatusQuestionnaire(clientDetailRequestData));

      navigate?.(-1);
    };
    const handleUpdateFailedCallback = (error) => {
      submitProps?.setSubmitting && submitProps.setSubmitting(false);
    };
    dispatch(
      requestUpdateClientQuestionnaire(
        questionnaireAnswerRequestDetails,
        handleUpdateSuccessCallback,
        handleUpdateFailedCallback
      )
    );
  };
  /**
   * Get Questionnaire Sections
   */
  useEffect(() => {
    setQuestionnaireSectionData((prev) => ({
      ...prev,
      isLoading: true,
    }));
    const questionnaireSections = handleGetQuestionnaireSectionData();
    setQuestionnaireSectionData((prev) => ({
      ...prev,
      sections: questionnaireSections,
      activeSectionIndex: 0,
      isLoading: false,
      errorSectionIndexes: [],
    }));
  }, [questionnairesInitialValues]);
  /**
   * Effect on mount
   */
  useEffect(() => {
    if (questionnaireId) {
      setQuestionnaireSectionData((prev) => ({
        ...prev,
        isLoading: true,
      }));
      //sets loading false on request completion
      const handleOnRequestSuccess = () => {
        setQuestionnaireSectionData((prev) => ({
          ...prev,
          isLoading: false,
        }));
      };
      handleGetAllQuestionnaireData(handleOnRequestSuccess);
    }
  }, [questionnaireId]);
  return (
    <Grid item xs={12} sx={{ display: 'flex', height: '100%' }}>
      {isUpdateClientQuestionnaireLoading && (
        <LoadingSpinner isLoadingSpinner={isUpdateClientQuestionnaireLoading} />
      )}
      {!isGetClientQuestionnairesLoading &&
      (!questionnaireSectionsData?.sections?.length ||
        (questionnaireSectionsData?.sections?.length === 1 &&
          !questionnaireSectionsData?.sections?.[0]?.sectionQuestions?.length)) ? (
        <Grid item xs={12} align="center">
          <MainCard>
            <NoData height={250} />
          </MainCard>
        </Grid>
      ) : (
        <Formik
          initialValues={initialQuestionnaireValues}
          enableReinitialize
          onSubmit={handleQuestionnaireFormSubmit}
        >
          {(formik) => (
            <Form
              style={{
                display: 'flex',
                flexGrow: 1,
              }}
            >
              <Grid
                container
                spacing={gridSpacing}
                sx={{ flexGrow: 1, flexFlow: 'column', height: '100%' }}
              >
                <Grid item xs={12} sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
                  <QuestionSection
                    activeQuestionSectionData={activeQuestionSectionData}
                    sectionIndex={questionnaireSectionsData?.activeSectionIndex}
                    questionnaireSectionsData={questionnaireSectionsData}
                    setQuestionnaireSectionData={setQuestionnaireSectionData}
                    handleAnswerFormikSubmission={formik?.handleSubmit}
                    questionAnswerFormik={{
                      setFieldValue: formik?.setFieldValue,
                      setFieldTouched: formik?.setFieldTouched,
                      handleBlur: formik.handleBlur,
                      values: formik?.values,
                    }}
                    questionnaireDetail={{ ...(leadDetails?.data || {}) }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Grid>
  );
};

export default QuestionnaireForm;
