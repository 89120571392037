import React from 'react';
import ActionIconButtonCard from './ActionIconButtonCard';

import { ReactComponent as GoalsObjectivesIcon } from 'assets/images/icons/goals_objectives.svg';

const GoalsObjectivesActionCard = (props) => {
  const { selectedTabButton, onSelectTabButton, count } = props;
  return (
    <ActionIconButtonCard
      actionLabel="Goals and Objectives"
      actionType="goals"
      actionIcon={GoalsObjectivesIcon}
      isSelected={selectedTabButton === 'goals'}
      handleSelectActionCard={onSelectTabButton}
      count={count}
    />
  );
};
export default GoalsObjectivesActionCard;
