import { createReducer } from '@reduxjs/toolkit';

const organizationSettingsInitialState = {
  //get Organization Settings
  isGetOrganizationSettingsLoading: false,
  organizationSettingsSuccessData: {},
  organizationSettingsFailedData: {},
};
/**
 * Organization Setting Reducer
 */
export const organizationSettingsReducer = createReducer(organizationSettingsInitialState, {
  //get Organization Settings
  getOrganizationSettingRequest: (state) => {
    state.isGetOrganizationSettingsLoading = true;
    state.organizationSettingsSuccessData = {};
    state.organizationSettingsFailedData = {};
  },
  getOrganizationSettingRequestSuccess: (state, action) => {
    state.isGetOrganizationSettingsLoading = false;
    state.organizationSettingsSuccessData = action.payload;
    state.organizationSettingsFailedData = {};
  },
  getOrganizationSettingRequestFailed: (state, action) => {
    state.isGetOrganizationSettingsLoading = false;
    state.organizationSettingsSuccessData = {};
    state.organizationSettingsFailedData = action.payload;
  },
});
