import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  // create
  saveRequestLoading: false,
  saveData: {},
  saveFailed: {},

  // get all
  getRequestLoading: false,
  getListData: null,
  getListDataFailed: null,
};

export const clientExpenseReducer = createReducer(initialState, {
  //save
  postClientExpenseRequest: (state) => {
    state.saveRequestLoading = true;
    state.saveData = {};
    state.saveFailed = {};
  },
  postClientExpenseSuccess: (state, action) => {
    state.saveRequestLoading = false;
    state.saveData = action.payload;
    state.saveFailed = {};
  },
  postClientExpenseFailed: (state, action) => {
    state.saveRequestLoading = false;
    state.saveData = {};
    state.saveFailed = action.payload;
  },

  //get all
  getClientExpenseRequest: (state) => {
    state.getRequestLoading = true;
    state.getListData = null;
    state.getListDataFailed = {};
  },
  getClientExpenseSuccess: (state, action) => {
    state.getRequestLoading = false;
    state.getListData = action.payload;
    state.getListDataFailed = {};
  },
  getClientExpenseFailed: (state, action) => {
    state.getRequestLoading = false;
    state.getListData = null;
    state.getListDataFailed = action.payload;
  },
});
