import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@mui/material';
import ChartCard from 'ui-component/cards/ChartCard';
import PieChartMain from 'ui-component/Chart/PieChartMain';

import TotalIncomeActionCard from '../../financialInfo/cards/TotalIncomeActionCard';
import TotalExpensesActionCard from '../../financialInfo/cards/TotalExpensesActionCard';
import TotalAssetsActionCard from '../../financialInfo/cards/TotalAssetsActionCard';
import TotalLiabilitiesActionCard from '../../financialInfo/cards/TotalLiabilitiesActionCard';
import GoalsObjectivesActionCard from '../../financialInfo/cards/GoalsObjectivesActionCard';
import FamilyAndLifetimeActionCard from '../../financialInfo/cards/FamilyAndLifetimeActionCard';

import { requestGetFinancialPositionSummary } from 'redux/Actions/financialPositionsAction';

const COLORS1 = ['#b5b5b5', '#eda5a6', '#01f0c7'];
const COLORS2 = ['#01f0c7', '#3abeff'];
const COLORS3 = ['#925ccb', '#0088FE'];
const COLORS4 = ['#12c2e9', '#f64f59'];

const FinancialInformationDashboard = () => {
  /**
   * Uses react router dom
   */
  const navigate = useNavigate();
  /**
   * Redux integration
   */
  const dispatch = useDispatch();

  //client reducer
  const clientReducer = useSelector((state) => state.clientReducer);
  const { selectedClientDetail } = clientReducer;

  const financialPositionsReducer = useSelector((state) => state.financialPositionsReducer);

  const { financialPositionGetSummaryRequestSuccessData } = financialPositionsReducer;

  useEffect(() => {
    if (selectedClientDetail?.activeFinancialPosition) {
      const activeFinancialPosition = selectedClientDetail.activeFinancialPosition;
      dispatch(requestGetFinancialPositionSummary(activeFinancialPosition));
    }
  }, [selectedClientDetail]);

  /**
   *  @enum [INCOME, EXPENSES, ASSETS, LIABILITIES, GOALS_OBJECTIVES, FAMILY_PROTECTIONS]
   *
   */
  const [selectedTabButton, setSelectedTabButton] = useState('');

  const handleSelectTabBtn = (type) => {
    setSelectedTabButton(type);
    navigate(`/main/financial-info/${type}`);
  };

  return (
    <Grid container spacing={1} item xs={12}>
      <Grid item xs={12} md={6}>
        <GoalsObjectivesActionCard
          selectedTabButton={selectedTabButton}
          onSelectTabButton={handleSelectTabBtn}
          count={selectedClientDetail?.goals ? selectedClientDetail?.goals.length : 0}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FamilyAndLifetimeActionCard
          selectedTabButton={selectedTabButton}
          onSelectTabButton={handleSelectTabBtn}
        />
      </Grid>
      <Grid container item spacing={1} xs={12} sm={6} md={3} lg={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TotalIncomeActionCard
            selectedTabButton={selectedTabButton}
            onSelectTabButton={handleSelectTabBtn}
            value={
              financialPositionGetSummaryRequestSuccessData?.incomeDetails?.totalNetIncomeValue
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ChartCard title={'Incomes Values by Types'}>
            <PieChartMain
              data={
                financialPositionGetSummaryRequestSuccessData?.incomeDetails?.totalChartArr
                  ? financialPositionGetSummaryRequestSuccessData.incomeDetails.totalChartArr
                  : []
              }
              COLORS={COLORS1}
              title={'Income values by type'}
            />
          </ChartCard>
        </Grid>
      </Grid>
      <Grid container item spacing={1} xs={12} sm={6} md={3} lg={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TotalExpensesActionCard
            selectedTabButton={selectedTabButton}
            onSelectTabButton={handleSelectTabBtn}
            value={financialPositionGetSummaryRequestSuccessData?.totalExpenses}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ChartCard title={'Expenses Values by Types'}>
            <PieChartMain
              data={
                financialPositionGetSummaryRequestSuccessData?.totalExpenseArr
                  ? financialPositionGetSummaryRequestSuccessData.totalExpenseArr
                  : []
              }
              COLORS={COLORS2}
              title={'Income values by type'}
            />
          </ChartCard>
        </Grid>
      </Grid>
      <Grid container item spacing={1} xs={12} sm={6} md={3} lg={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TotalAssetsActionCard
            selectedTabButton={selectedTabButton}
            onSelectTabButton={handleSelectTabBtn}
            value={financialPositionGetSummaryRequestSuccessData?.totalAssets}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ChartCard title={'Assets Values by Types'}>
            <PieChartMain
              data={
                financialPositionGetSummaryRequestSuccessData?.totalAssetArr
                  ? financialPositionGetSummaryRequestSuccessData.totalAssetArr
                  : []
              }
              COLORS={COLORS3}
              title={'Income values by type'}
            />
          </ChartCard>
        </Grid>
      </Grid>
      <Grid container item spacing={1} xs={12} sm={6} md={3} lg={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TotalLiabilitiesActionCard
            selectedTabButton={selectedTabButton}
            onSelectTabButton={handleSelectTabBtn}
            value={financialPositionGetSummaryRequestSuccessData?.totalLiabilities}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ChartCard title={'Liabilities Values by Types'}>
            <PieChartMain
              data={
                financialPositionGetSummaryRequestSuccessData?.totalLiabilityArr
                  ? financialPositionGetSummaryRequestSuccessData.totalLiabilityArr
                  : []
              }
              COLORS={COLORS4}
              title={'Income values by type'}
            />
          </ChartCard>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FinancialInformationDashboard;
