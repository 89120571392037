import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

import { useFinancialInfoStyle } from '../FinancialInfoStyle';
import MainCard from 'ui-component/cards/MainCard';
import { currencyNumberFormat } from 'utils/FormatUtils';

const ActionButtonCard = (props) => {
  const {
    isSelected,
    actionLabel,
    actionType,
    actionTypeStatistic,
    disabled,
    handleSelectActionCard,
  } = props;
  const { classes: financialInfoClasses } = useFinancialInfoStyle();
  return (
    <MainCard
      elevation={2}
      border
      content
      disabled={!!disabled}
      sx={{
        margin: 0,
        padding: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: '100%',
        cursor: !disabled && !isSelected ? 'pointer' : 'default',
      }}
      className={
        isSelected
          ? financialInfoClasses?.selectedActionCardWrapper
          : financialInfoClasses?.defaultActionCardWrapper
      }
      contentClass={financialInfoClasses?.actionCardContendWrapper}
      onClick={() => {
        handleSelectActionCard && handleSelectActionCard(actionType);
      }}
    >
      <Box
        sx={(theme) => ({
          paddingX: 2.25,
          paddingY: 1,
        })}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              align="left"
              sx={(theme) => ({
                color: isSelected ? '#fff' : '#215273',
                fontWeight: 500,
                fontSize: '1.2rem',
              })}
            >
              {actionLabel}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Typography
              align="right"
              sx={(theme) => ({
                fontSize: '1.3rem',
                fontWeight: 700,
                color: isSelected
                  ? '#fff'
                  : actionType === 'INCOME'
                  ? '#0D7223'
                  : actionType === 'EXPENSES'
                  ? '#FF5A3B'
                  : actionType === 'ASSETS'
                  ? '#7C007C'
                  : actionType === 'LIABILITIES' && '#FFBF00',
              })}
            >
              {currencyNumberFormat(actionTypeStatistic)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  );
};
export default ActionButtonCard;
