import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //admin email templates
  isGetAllAdminEmailTemplatesLoading: false,
  adminEmailTemplatesData: null,
  adminEmailTemplatesFailedData: null,
};

/**
 * Client Invitation reducer
 */
export const emailTemplateReducer = createReducer(initialState, {
  //admin email template
  getAllAdminEmailTemplatesRequest: (state) => {
    state.isGetAllAdminEmailTemplatesLoading = true;
    state.adminEmailTemplatesData = null;
    state.adminEmailTemplatesFailedData = null;
  },
  getAllAdminEmailTemplatesRequestSuccess: (state, action) => {
    state.isGetAllAdminEmailTemplatesLoading = false;
    state.adminEmailTemplatesData = action.payload;
    state.adminEmailTemplatesFailedData = null;
  },
  getAllAdminEmailTemplatesRequestFailed: (state, action) => {
    state.isGetAllAdminEmailTemplatesLoading = false;
    state.adminEmailTemplatesData = null;
    state.adminEmailTemplatesFailedData = action.payload;
  },
});
