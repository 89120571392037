import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import Dashboard from 'views/dashboard/Dashboard';
import AnswerQuestionnaire from 'views/questionnaires/AnswerQuestionnaire';

const AdvisorDetails = Loadable(lazy(() => import('views/advisorDetails/AdvisorDetails')));

const DocumentsAndFiles = Loadable(lazy(() => import('views/documentsAndFiles/DocumentsAndFiles')));

const FinancialInfo = Loadable(lazy(() => import('views/financialInfo/FinancialInfo')));

const MyProfile = Loadable(lazy(() => import('views/myProfile/MyProfile')));

const ClientQuestionnaires = Loadable(lazy(() => import('views/questionnaires/ClientQuestionnaires')))

const Settings = Loadable(lazy(() => import('views/settings/Settings')));

// ==============================|| client financial ||============================== //

const CreateExpense = Loadable(
  lazy(() => import('views/financialInfo/expenses/create/CreateExpense'))
);

const CreateLiability = Loadable(
  lazy(() => import('views/financialInfo/liabilities/create/CreateLiability'))
);

const CreateAsset = Loadable(lazy(() => import('views/financialInfo/assets/create/CreateAsset')));

const CreateIncome = Loadable(lazy(() => import('views/financialInfo/income/create/CreateIncome')));

const CreateGoalsAndObjectives = Loadable(
  lazy(() => import('views/financialInfo/goalsAndObjectives/create/CreateGoalsAndObjectives'))
);

const CreateFamilyAndLifestyleProtection = Loadable(
  lazy(() => import('views/financialInfo/protection/create/CreateFamilyAndLifestyleProtection'))
);

// ==============================|| client payments ||============================== //

const Payments = Loadable(lazy(() => import('views/payments/Payments')));

const MainRoutes = {
  path: '/main',
  element: <MainLayout />,
  children: [
    {
      path: 'dashboard',
      element: <Dashboard />,
    },
    {
      path: 'contact-your-advisor',
      element: <AdvisorDetails />,
    },
    {
      path: 'documents-and-files',
      element: <DocumentsAndFiles />,
    },
    {
      path: 'financial-info',
      element: <FinancialInfo />,
      children: [
        {
          path: 'incomes',
        },
        {
          path: 'expenses',
        },
        {
          path: 'assets',
        },
        {
          path: 'liabilities',
        },
        {
          path: 'goals',
        },
        {
          path: 'protection',
        },
      ],
    },
    {
      path: 'my-profile',
      element: <MyProfile />,
    },
    {
      path: 'questionnaires',
      element: <ClientQuestionnaires />,
    },
    {
      path: 'questionnaires/:questionnaireId',
      element: <AnswerQuestionnaire />,
    },
    {
      path: 'settings',
      element: <Settings />,
    },
    // client Expense
    {
      path: 'client/createExpense',
      element: <CreateExpense />,
    },

    // client Liability
    {
      path: 'client/createLiability',
      element: <CreateLiability />,
    },

    // client assets
    {
      path: 'client/CreateAsset',
      element: <CreateAsset />,
    },

    // client income
    {
      path: 'client/createIncome',
      element: <CreateIncome />,
    },

    // client goals and Objectives
    {
      path: 'client/createGoalsAndObjectives',
      element: <CreateGoalsAndObjectives />,
    },

    {
      path: 'client/createProtection',
      element: <CreateFamilyAndLifestyleProtection />,
    },

    //payments
    {
      path: 'payments',
      element: <Payments />,
    },
  ],
};

export default MainRoutes;
