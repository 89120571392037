// assets
import { ReactComponent as AdvisorDetailsIcon } from 'assets/images/icons/briefcase.svg';
import { ReactComponent as DocumentsAndFilesIcon } from 'assets/images/icons/folder.svg';
import { ReactComponent as FinancialInfoIcon } from 'assets/images/icons/financial_info.svg';
import { ReactComponent as MyProfileIcon } from 'assets/images/icons/user.svg';
import { ReactComponent as IconSettings } from 'assets/images/icons/settings.svg';
import { ReactComponent as IconPayments } from 'assets/images/icons/payments-outline.svg';
import { ReactComponent as QuestionnaireOutlinedIcon } from 'assets/images/icons/questionnaire-outlined.svg';

// constant
const icons = {
  AdvisorDetailsIcon,
  DocumentsAndFilesIcon,
  FinancialInfoIcon,
  MyProfileIcon,
  IconSettings,
  IconPayments,
  QuestionnaireOutlinedIcon,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  title: null,
  type: 'group',
  children: [
    {
      id: 'advisorDetails',
      title: 'Contact your advisor',
      type: 'item',
      url: '/main/contact-your-advisor',
      icon: icons.AdvisorDetailsIcon,
      breadcrumbs: false,
    },
    {
      id: 'documentsAndFiles',
      title: 'Documents & Files',
      type: 'item',
      url: '/main/documents-and-files',
      icon: icons.DocumentsAndFilesIcon,
      breadcrumbs: false,
    },
    {
      id: 'financialInfo',
      title: 'Financial Info',
      type: 'item',
      url: '/main/financial-info/incomes',
      icon: icons.FinancialInfoIcon,
      breadcrumbs: false,
    },
    {
      id: 'profileDetails',
      title: 'My Profile',
      type: 'item',
      url: '/main/my-profile',
      icon: icons.MyProfileIcon,
      breadcrumbs: false,
    },
    {
      id: 'questionnaires',
      title: 'Questionnaires',
      type: 'item',
      url: '/main/questionnaires',
      icon: icons.QuestionnaireOutlinedIcon,
      iconViewBox: '0 0 256 256',
      breadcrumbs: false,
      chip: {
        label: '',
        customLabel: true,
        // Store?.getState?.()?.clientQuestionnairesReducer?.filterQuestionnaireRequestSuccessData?.filter?.(
        //   (data) =>
        //     data.status !== 'Submitted' &&
        //     data.status !== 'Reviewed' &&
        //     data.questionnaireTitle !== 'Main Questionnaire' &&
        //     data.questionnaireTemplate
        // )?.length,
        color: 'error',
      },
    },
    {
      id: 'paymentDetails',
      title: 'Payments',
      type: 'item',
      url: '/main/payments',
      icon: icons.IconPayments,
      breadcrumbs: false,
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: '/main/settings',
      icon: icons.IconSettings,
      breadcrumbs: false,
    },
  ],
};

export default utilities;
