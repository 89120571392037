import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from 'common/material/CommonModal';
import { Grid, SvgIcon } from '@mui/material';

import { ReactComponent as MessageSuccessImage } from 'assets/images/successModals/messageSent.svg';

const SuccessMessageContent = () => {
  return (
    <Grid container>
      <SvgIcon
        viewBox="0 0 314 234"
        component={MessageSuccessImage}
        sx={{ width: '100%', height: '100%' }}
      />
    </Grid>
  );
};
const MessageSentModal = (props) => {
  const { isOpened, handleModalClose } = props;
  return (
    <CommonModal
      open={isOpened}
      handleDialogOpened={handleModalClose}
      dialogContent={<SuccessMessageContent />}
      dialogProps={{
        sx: {
          '& .MuiDialogContent-root': {
            zIndex: (theme) => theme.zIndex.modal + 1,
          },
        },
      }}
    />
  );
};

MessageSentModal.propTypes = {
  isOpened: PropTypes.bool,
  handleModalClose: PropTypes.func,
};

export default MessageSentModal;
