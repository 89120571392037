import { makeStyles } from 'tss-react/mui';

export const useFinancialInfoStyle = makeStyles()((theme) => ({
  mainContainerWrapper: {
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
  selectedActionCardWrapper: {
    // backgroundColor: 'linear-gradient(to right, #00f260, #0575e6)',
    // background: 'linear-gradient(to right, #24c6dc, #215273)',
    // background: 'linear-gradient(to right, #b993d6, #8ca6db)',
    background: 'linear-gradient(to right, #1488cc, #2b32b2)',
    height: '100%',
    padding: '0 !important',
    alignItems: 'center',
    display: 'flex',
  },
  defaultActionCardWrapper: {
    height: '100%',
    padding: '0 !important',
    alignItems: 'center',
    display: 'flex',
  },
  defaultTabBtn: {
    backgroundColor: theme.palette.green?.['80'],
    color: '#2EBA88',
    fontWeight: 700,
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: theme.palette.green?.['80'],
      color: '#2EBA88',
    },
  },
  selectedTabBtn: {
    backgroundColor: `${'#2EBA88'} !important`,
    color: `${theme.palette.white} !important`,
    fontWeight: 700,
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: theme.palette.green?.['80'],
      color: '#2EBA88',
    },
  },
  actionCardContendWrapper: {
    height: '100%',
    width: '100%',
    padding: '0 !important',
    display: 'flex',
    minWidth: 'fit-content',
  },
  // goals and objective importance CHIPS
  veryImportantObjectiveChip: {
    color: theme.palette.red[700],
    borderColor: theme.palette.red[700],
  },
  importantObjectiveChip: {
    color: theme.palette.yellow[400],
    borderColor: theme.palette.yellow[400],
  },
  notImportantObjectiveChip: {
    color: theme.palette.green[750],
    borderColor: theme.palette.green[750],
  },
}));
