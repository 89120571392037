import React from 'react';
import { Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import BackButton from 'ui-component/buttons/BackButton';
import { useCommonStyles } from 'styles/styles';

const FormTopContainer = (props) => {
  const { title } = props;

  const { classes: commonClasses } = useCommonStyles();

  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12} justifyContent={'space-between'}>
        <Typography className={commonClasses.mainTitle}>{title}</Typography>
        <BackButton />
      </Grid>
    </Grid>
  );
};

export default FormTopContainer;
