/**
 * Number to Currency String format
 * @param {*} value
 * @param {*} locale
 * @param {*} style
 * @param {*} currency
 * @returns {string} currency string
 */
export const currencyNumberFormat = (
  value,
  locale = 'en-AU',
  style = 'currency',
  currency = 'AUD'
) => {
  const numericValue = +value || 0;
  const formattedCurrency = new Intl.NumberFormat(locale, {
    style,
    currency,
  }).format(numericValue);

  return formattedCurrency;
};

/**
 * Get Short pass code
 * @returns { string } short passcode
 */
export const getShortPassCode = () => {
  let length = 8,
    charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@.+%',
    retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

/**
 * Get Camel Casing value from Normal Casing string
 * @param {*} text Normal Case
 * @returns {string} camelCase
 */
export const getCamelCaseString = (text, delimiter = ' ', replacement = '') => {
  const camelCase = (textValue) => {
    let camelCaseText = textValue;
    if (typeof textValue === 'string') {
      let wordArray = textValue.split(delimiter);
      camelCaseText = wordArray
        .map((value, index) => {
          if (index !== 0 && value?.length) {
            let firstCharacter = value?.[0];
            let capitalizedFirstLetter =
              (typeof firstCharacter === 'string' && firstCharacter.toUpperCase()) || '';
            let lowerCaseRest = value.substring(1, value?.length);
            return capitalizedFirstLetter + lowerCaseRest;
          } else if (value?.length && index === 0) {
            return (value?.toLowerCase && value.toLowerCase()) || '';
          } else {
            return value;
          }
        })
        .join(replacement);
    }
    return camelCaseText;
  };
  let camelCaseText = camelCase(text);
  return camelCaseText;
};
