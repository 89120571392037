import React from 'react';
import { Box } from '@mui/material';
import { useLottie } from 'lottie-react';

import * as noDataAnimationData from 'assets/animation/no-data-found.json';

const NoData = (props) => {
  const { height } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noDataAnimationData,
  };
  const style = {
    height: 250,
  };

  const { View } = useLottie(defaultOptions, style);

  return <Box style={{ height: height ? height : 250 }}>{View}</Box>;
};

export default NoData;
