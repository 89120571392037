import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Collapse, Grid, Grow, Paper } from '@mui/material';

import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { ErrorMessage, useFormikContext } from 'formik';
import { format, isDate, isValid } from 'date-fns';

import MultiSelectCellView from '../MultiSelectCellView';
import MultiSelectEditField from '../MultiSelectEditField';
import TextError from 'common/formik/TextError';

const TableAnswer = (props) => {
  const { questionnaireIndex, questionType } = props;

  /**
   * USes formik context
   */
  const { setFieldValue, values: questionnaire, errors } = useFormikContext();

  //_states
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [columns, setColumns] = useState([]);

  //_question data memo
  const questionData = useMemo(
    () => questionnaire?.[questionnaireIndex] || [],
    [questionnaire, questionnaireIndex]
  );
  //_answer row memo
  const answerRows = useMemo(() => {
    let rows = [];
    if (
      Array.isArray(
        questionData?.answer?.[questionType === 'CLIENT' ? 'client' : 'partner']
          ?.multipleTabularAnswers?.[1]
      )
    ) {
      rows =
        questionData?.answer?.[questionType === 'CLIENT' ? 'client' : 'partner']
          ?.multipleTabularAnswers?.[1];
    }
    return rows;
  }, [questionData, questionType]);
  /**
   * Effect on answer rows
   */
  useEffect(() => {
    if (Array.isArray(answerRows)) {
      setRows(answerRows);
    }
  }, [answerRows]);

  /**
   * render multi select view mode
   * @param {*} params
   * @returns
   */
  const renderMultiSelectView = (params) => {
    return <MultiSelectCellView {...params} />;
  };

  /**
   * render multi select edit mode
   * @param {*} params
   * @returns
   */
  const renderMultiSelectEdit = (params) => {
    return <MultiSelectEditField {...params} />;
  };
  //_table column memo
  const tableColumns = useMemo(() => {
    let columns = [];
    if (
      Array.isArray(
        questionData?.answer?.[questionType === 'CLIENT' ? 'client' : 'partner']
          ?.multipleTabularAnswers?.[0]
      )
    ) {
      columns =
        questionData?.answer?.[questionType === 'CLIENT' ? 'client' : 'partner']
          ?.multipleTabularAnswers?.[0];
      columns = columns.map((column) => {
        let hasValueFormatter = false;
        let formatterFunction = null;
        // let valueGetter = null;
        let renderCell = null;
        let renderEditCell = null;
        if (column?.type === 'date') {
          hasValueFormatter = true;
          formatterFunction = (params) => {
            if (
              !params?.value ||
              !isValid(new Date(params?.value)) ||
              !isDate(new Date(params?.value))
            ) {
              return '';
            }

            const valueFormatted = format(new Date(params?.value), 'yyyy-MM-dd');
            return valueFormatted;
          };
        }
        if (column?.type === 'dateTime') {
          hasValueFormatter = true;
          formatterFunction = (params) => {
            if (
              !params?.value ||
              !isValid(new Date(params?.value)) ||
              !isDate(new Date(params?.value))
            ) {
              return '';
            }

            const valueFormatted = format(new Date(params?.value), 'yyyy-MM-dd hh:mm aa');
            return valueFormatted;
          };
        }
        if (column?.type === 'multiSelect') {
          renderCell = renderMultiSelectView;
          renderEditCell = renderMultiSelectEdit;
        }
        return {
          ...column,
          flex: 0,
          width: 150,
          height: 'auto',
          editable: true,
          ...(column?.type === 'singleSelect'
            ? {
                valueOptions: column?.valueOptions?.map((option) => ({
                  ...option,
                  label: option?.title,
                  value: option?.value,
                })),
              }
            : column.type === 'multiSelect'
            ? {
                flex: 0,
                width: 200,
                type: 'multiSelect',
                valueOptions: column?.valueOptions?.map((option) => ({
                  ...option,
                  label: option?.title,
                  value: option?.value,
                })),
                renderCell,
                renderEditCell,
              }
            : {}),
          ...(hasValueFormatter
            ? {
                valueFormatter: formatterFunction,
              }
            : {}),
        };
      });
    }
    return [...columns];
  }, [questionData, questionType]);
  /**
   * Effect on table columns
   */
  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    };
    setColumns([...tableColumns, actionColumn]);
  }, [tableColumns, rowModesModel]);
  /**
   * Handle set new row question
   */
  const handleSetNewAnswerRowQuestionnaire = useCallback(
    (updatedAnswerRows = []) => {
      let answerObj = {
        ...(questionData || {}),
      };
      if (questionType === 'CLIENT') {
        answerObj = {
          ...answerObj,
          answer: {
            ...(answerObj?.answer || {}),
            client: {
              ...(answerObj?.answer.client || {}),
              multipleTabularAnswers: [tableColumns, updatedAnswerRows],
            },
          },
        };
      } else {
        answerObj = {
          ...answerObj,
          answer: {
            ...(answerObj?.answer || {}),
            partner: {
              ...(answerObj?.answer.partner || {}),
              multipleTabularAnswers: [tableColumns, updatedAnswerRows],
            },
          },
        };
      }
      setFieldValue && setFieldValue(`[${questionnaireIndex}]`, answerObj, true);
    },
    [setFieldValue, questionnaireIndex, tableColumns, questionData, questionType]
  );
  /**
   * Handle add new row
   */
  const handleAddAnswerRow = useCallback(() => {
    const currentAnswerRows = rows || [];
    const columnKeys = tableColumns.map((column) => column.field);
    const rowObj = {
      isNew: true,
      id: (currentAnswerRows?.length || 0) + 1,
    };
    columnKeys.map((key) => (rowObj[key] = ''));
    let updatedAnswerRows = [
      ...currentAnswerRows,
      {
        ...rowObj,
      },
    ];
    setRows(updatedAnswerRows);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [rowObj.id]: { mode: GridRowModes.Edit, fieldToFocus: columnKeys?.[0] || '' },
    }));
  }, [rows, tableColumns]);
  /**
   * Table's edit toolbar
   * @param {*} props
   * @returns
   */
  const EditToolbar = (props) => {
    const { handleAddAnswerRow } = props;
    /**
     * On add new record click
     */
    const handleClick = () => {
      handleAddAnswerRow && handleAddAnswerRow();
    };

    return (
      <GridToolbarContainer sx={{ justifyContent: 'flex-end', padding: '1rem 1rem 0 0 ' }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      </GridToolbarContainer>
    );
  };
  /**
   * Handle edit click
   * @param {*} id
   * @returns
   */
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  /**
   * Handle save click
   * @param {*} id
   * @returns
   */
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  /**
   * handle on delete click
   */
  const handleDeleteClick = useCallback(
    (id) => () => {
      const updatedRows = [...(answerRows || [])].filter((row) => (row.id || row._id) !== id);
      setRows(updatedRows);
      handleSetNewAnswerRowQuestionnaire(updatedRows);
    },
    [answerRows, handleSetNewAnswerRowQuestionnaire]
  );
  /**
   * Handle on cancel click
   */
  const handleCancelClick = useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => (row.id || row._id) === id);
      if (editedRow?.isNew) {
        setRows((prevRows) => prevRows.filter((row) => (row.id || row._id) !== id));
      }
    },
    [rowModesModel, rows]
  );
  /**
   * handle row edit start
   */
  const handleRowEditStart = useCallback((prams, event) => {
    event.defaultMuiPrevented = true;
  }, []);
  /**
   * handle row edit stop
   */
  const handleRowEditStop = useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);
  /**
   * Process row update
   */
  const processRowUpdate = useCallback(
    (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      const updatedRows = rows?.map?.((row) => (row.id === newRow.id ? updatedRow : row)) ||[];
      setRows(updatedRows);
      handleSetNewAnswerRowQuestionnaire(updatedRows);
      return updatedRow;
    },
    [rows, setRows, handleSetNewAnswerRowQuestionnaire]
  );
  return (
    <Collapse
      unmountOnExit
      sx={{ width: '100%' }}
      in={questionData?.fieldType === 'multipleTabular'}
    >
      <Grow in={questionData?.fieldType === 'multipleTabular'}>
        <Grid item xs={12}>
          <Paper sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12}>
                <Box
                  sx={{
                    height: '100%',
                    flex: 1,
                    width: '100%',
                  }}
                >
                  <DataGrid
                    autoHeight={true}
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    getRowId={(row) => row?.id || row?._id}
                    checkboxSelection={false}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.white,
                      flexGrow: 1,
                      ...(errors?.[questionnaireIndex]?.answer?.[
                        questionType === 'CLIENT' ? 'client' : 'partner'
                      ]?.multipleTabularAnswers
                        ? {
                            borderColor: theme?.palette?.error.main,
                          }
                        : {}),
                    })}
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{
                      Toolbar: EditToolbar,
                    }}
                    componentsProps={{
                      toolbar: { handleAddAnswerRow },
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 10,
                      },
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                  />
                </Box>
              </Grid>
              <Grid item xs="auto">
                <ErrorMessage
                  component={TextError}
                  name={`[${questionnaireIndex}].answer.[${
                    questionType === 'CLIENT' ? 'client' : 'partner'
                  }].multipleTabularAnswers`}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grow>
    </Collapse>
  );
};

export default TableAnswer;
