import React from 'react';
import ActionButtonCard from './ActionButtonCard';

const TotalExpensesActionCard = (props) => {
  const { selectedTabButton, onSelectTabButton, value } = props;
  return (
    <ActionButtonCard
      actionLabel="Total Expenses"
      actionType="expenses"
      actionTypeStatistic={value}
      isSelected={selectedTabButton === 'expenses'}
      handleSelectActionCard={onSelectTabButton}
    />
  );
};
export default TotalExpensesActionCard;
