import { Box } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import DropDownAutoCom from 'common/material/DropDownAutoCom';
import { memo, useMemo, useRef } from 'react';

const MultiSelectEditField = (props) => {
  const { id, value, field, colDef } = props;
  //_refs
  const apiRef = useGridApiContext();
  const autoComppleteRef = useRef();

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  /**
   * Field Option data
   */
  const selectOptions = useMemo(() => {
    let { valueOptions } = colDef;
    return valueOptions;
  }, [colDef]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
      <DropDownAutoCom
        ref={autoComppleteRef}
        dropDownValue={value || []}
        setDropDownValue={handleChange}
        optionData={selectOptions}
        multiple
        dense
        labelKey="label"
        valueKey="value"
        ChipProps={{
          color: 'primary',
          size: 'small',
          variant: 'outlined',
          sx: {
            marginLeft: '0.5rem !important',
          },
        }}
      />
    </Box>
  );
};

export default memo(MultiSelectEditField);
