import { Card, CardContent, CardHeader, Fade, Grid, Typography } from '@mui/material';

import { gridSpacing } from 'store/constant';
import { useCommonStyles } from 'styles/styles';

import BooleanAnswer from './answerComponents/BooleanAnswer';
import TextAnswer from './answerComponents/TextAnswer';
import BooleanAndTextAnswer from './answerComponents/BooleanAndTextAnswer';
import TableAnswer from './answerComponents/TableAnswer';
import MultiFormatAnswer from './answerComponents/MultiFormatAnswer';
import SignatureAnswer from './answerComponents/SignatureAnswer';

const QuestionnaireQuestionAndAnswer = (props) => {
  const {
    sectionData,
    sectionIndex,
    questionData,
    questionnaireIndex,
    questionType,
    handleFieldBlur,
    ...otherProps
  } = props;
  /**
   * Uses common classes
   */
  const {
    classes: {},
    theme,
  } = useCommonStyles();

  return (
    <Card
      elevation={0}
      sx={{
        marginTop: questionnaireIndex && '1rem',
        marginLeft: questionType === 'CLIENT' ? '1rem' : '2rem',
        padding: '0 !important',
      }}
      {...otherProps}
    >
      <CardHeader
        title={
          <Typography
            variant="h5"
            sx={{
              color: theme.palette?.text?.primary,
            }}
          >
            {questionData?.question}

            <Fade in={!!questionData?.isRequired}>
              <Typography
                component="span"
                color="error"
                sx={{
                  padding: '0 0.5rem',
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: 'min(1rem, 0.875rem)',
                }}
              >
                *
              </Typography>
            </Fade>
          </Typography>
        }
        subheader={
          <Typography
            variant="caption"
            component={
              questionData?.questionCaption?.startsWith &&
              (questionData.questionCaption.startsWith('https://') ||
                questionData.questionCaption.startsWith('http://'))
                ? 'a'
                : 'span'
            }
            href={
              questionData?.questionCaption?.startsWith &&
              (questionData.questionCaption.startsWith('https://') ||
                questionData.questionCaption.startsWith('http://'))
                ? questionData.questionCaption
                : ''
            }
          >
            {questionData?.questionCaption}
          </Typography>
        }
      />
      <CardContent sx={{ padding: '1rem 2.5rem', display: 'flex' }}>
        <Grid container spacing={gridSpacing}>
          <BooleanAnswer
            questionData={questionData}
            questionnaireIndex={questionnaireIndex}
            questionType={questionType}
            sectionIndex={sectionIndex}
            handleFieldBlur={handleFieldBlur}
          />
          <TextAnswer
            questionData={questionData}
            questionnaireIndex={questionnaireIndex}
            questionType={questionType}
          />
          <BooleanAndTextAnswer
            questionData={questionData}
            questionnaireIndex={questionnaireIndex}
            questionType={questionType}
            sectionIndex={sectionIndex}
            handleFieldBlur={handleFieldBlur}
          />
          <TableAnswer
            questionData={questionData}
            questionnaireIndex={questionnaireIndex}
            questionType={questionType}
            sectionIndex={sectionIndex}
            handleFieldBlur={handleFieldBlur}
          />
          <MultiFormatAnswer
            questionData={questionData}
            questionnaireIndex={questionnaireIndex}
            questionType={questionType}
            sectionIndex={sectionIndex}
            handleFieldBlur={handleFieldBlur}
          />
          <SignatureAnswer
            questionData={questionData}
            questionnaireIndex={questionnaireIndex}
            questionType={questionType}
            sectionIndex={sectionIndex}
            handleFieldBlur={handleFieldBlur}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default QuestionnaireQuestionAndAnswer;
