import { memo, useEffect, useRef, useState } from 'react';

import { Chip, Stack } from '@mui/material';

const MultiSelectCellView = (props) => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeftState, setScrollLeftState] = useState(null);
  const [mouseMoved, setStateMouseMoved] = useState(0);

  const itemsContainer = useRef();

  // functions
  function handleMouseDown(e) {
    setIsDown(true);

    e.preventDefault();

    if (e.pageX === undefined) {
      setStartX(e.touches[0].pageX - itemsContainer.current?.offsetLeft);
    } else {
      setStartX(e.pageX - itemsContainer.current?.offsetLeft);
    }

    setScrollLeftState(itemsContainer.current?.scrollLeft);
    setStateMouseMoved(0);
  }

  function handleMouseMove(e) {
    if (!isDown) {
      return;
    }
    const currentMousePositionInsideContainer =
      e.pageX === undefined
        ? e.touches[0].pageX - itemsContainer.current?.offsetLeft
        : e.pageX - itemsContainer.current?.offsetLeft;
    setStateMouseMoved(currentMousePositionInsideContainer - startX);
  }

  useEffect(() => {
    if (typeof itemsContainer.current?.scrollLeft !== 'undefined') {
      itemsContainer.current.scrollLeft = scrollLeftState - mouseMoved;
    }
  }, [scrollLeftState, mouseMoved]);

  return Array.isArray(props?.value) ? (
    <Stack
      direction="row"
      display="flex"
      spacing={1}
      overflow="auto"
      ref={itemsContainer}
      // MOUSE EVENTS
      onMouseDown={(e) => handleMouseDown(e)}
      onMouseUp={() => setIsDown(false)}
      onMouseLeave={() => setIsDown(false)}
      onMouseMove={(e) => handleMouseMove(e)}
      // TOUCH EVENTS
      onTouchStart={(e) => handleMouseDown(e)}
      onTouchEnd={() => setIsDown(false)}
      onTouchCancel={() => setIsDown(false)}
      onTouchMove={(e) => handleMouseMove(e)}
    >
      {props.value.map((selectedOption, optionIndex) => (
        <Chip
          key={`${optionIndex}-selection-tag`}
          label={selectedOption?.label || selectedOption?.title}
          variant="outlined"
          size="small"
          color="primary"
        />
      ))}
    </Stack>
  ) : (
    ''
  );
};

export default memo(MultiSelectCellView);
