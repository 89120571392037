import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
//actions
import { handleNotification } from './handleNotificationAction';
import defaultQuestionnairesData from 'data/defaultQuestionnairesData.json';
/**
 * Get Client Questionnaires
 * @param {*} handleGetClientQuestionnaireSuccessCallback
 * @param {*} handleGetClientQuestionnaireFailedCallback
 * @returns {async function}
 */
export const requestGetClientQuestionnaire =
  (
    requestDetails,
    handleGetClientQuestionnaireSuccessCallback = null,
    handleGetClientQuestionnaireFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getClientQuestionnaireRequest' });

    const { questionnaireId } = requestDetails;
    const clientQuestionnaireRequestData = {
      url: API_URL.client.clientQuestionnaire.GET_CLIENT_QUESTIONNAIRES.replace(
        '{questionnaireId}',
        questionnaireId
      ),
      method: 'GET',
    };
    const requestGetClientQuestionnaireSuccess = (response) => {
      dispatch({ type: 'getClientQuestionnaireRequestSuccess', payload: response?.data });
      handleGetClientQuestionnaireSuccessCallback &&
        handleGetClientQuestionnaireSuccessCallback(response?.data);
    };
    const requestGetClientQuestionnaireFailed = (error) => {
      dispatch({ type: 'getClientQuestionnaireRequestFailed', payload: defaultQuestionnairesData });
      handleGetClientQuestionnaireFailedCallback &&
        handleGetClientQuestionnaireFailedCallback(error);
    };

    http_request(
      clientQuestionnaireRequestData,
      requestGetClientQuestionnaireSuccess,
      requestGetClientQuestionnaireFailed
    );
  };

/**
 * Update Client Questionnaires
 * @param {*} handleUpdateClientQuestionnaireSuccessCallback
 * @param {*} handleUpdateClientQuestionnaireFailedCallback
 * @returns {async function}
 */
export const requestUpdateClientQuestionnaire =
  (
    updateRequestData,
    handleUpdateClientQuestionnaireSuccessCallback = null,
    handleUpdateClientQuestionnaireFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'updateClientQuestionnaireRequest' });

    const { requestBody, questionnaireId } = updateRequestData;
    const clientQuestionnaireRequestData = {
      url: API_URL.client.clientQuestionnaire.PATCH_UPDATE_QUESTIONNAIRE.replace(
        '{questionnaireId}',
        questionnaireId
      ),
      method: 'PATCH',
      data: requestBody,
    };

    const requestUpdateClientQuestionnaireSuccess = (response) => {
      dispatch({ type: 'updateClientQuestionnaireRequestSuccess', payload: response?.data });
      const successMessage = `Client Questionnaire is Updated Successfully !`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleUpdateClientQuestionnaireSuccessCallback &&
        handleUpdateClientQuestionnaireSuccessCallback(response?.data);
    };
    const requestUpdateClientQuestionnaireFailed = (error) => {
      dispatch({ type: 'updateClientQuestionnaireRequestFailed', payload: error });
      let message = error?.response?.data?.name || 'Client Questionnaire Update is Failed !';

      Store.dispatch(handleNotification(message, 'error'));
      handleUpdateClientQuestionnaireFailedCallback &&
        handleUpdateClientQuestionnaireFailedCallback(error);
    };

    http_request(
      clientQuestionnaireRequestData,
      requestUpdateClientQuestionnaireSuccess,
      requestUpdateClientQuestionnaireFailed
    );
  };

/**
 * Delete Questionnaire Question
 * @param {*} updateRequestData
 * @param {*} handleDeleteRequestSuccessCallback
 * @param {*} handleDeleteRequestFailedCallback
 * @returns
 */
export const requestDeleteQuestionnaireQuestion =
  (
    deleteRequestData,
    handleDeleteRequestSuccessCallback = null,
    handleDeleteRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'deleteQuestionnaireQuestionRequest' });

    const { questionnaireDetailId, questionnaireRootId } = deleteRequestData;

    const clientQuestionnaireRequestData = {
      url: API_URL.client.clientQuestionnaire.DELETE_CLIENT_QUESTIONNAIRE_QUESTION_BY_ID.replace(
        '{questionnaireDataId}',
        questionnaireRootId
      ).replace('{questionId}', questionnaireDetailId),
      method: 'DELETE',
    };
    const requestUpdateClientQuestionnaireSuccess = (response) => {
      dispatch({ type: 'deleteQuestionnaireQuestionRequestSuccess', payload: response?.data });
      const successMessage = `Question is Deleted Successfully !`;
      Store.dispatch(handleNotification(successMessage, 'success'));
      handleDeleteRequestSuccessCallback && handleDeleteRequestSuccessCallback(response?.data);
    };
    const requestUpdateClientQuestionnaireFailed = (error) => {
      dispatch({ type: 'deleteQuestionnaireQuestionRequestFailed', payload: error });
      let message = error?.response?.data?.name || 'Question Delete is Failed !';

      Store.dispatch(handleNotification(message, 'error'));
      handleDeleteRequestFailedCallback && handleDeleteRequestFailedCallback(error);
    };

    http_request(
      clientQuestionnaireRequestData,
      requestUpdateClientQuestionnaireSuccess,
      requestUpdateClientQuestionnaireFailed
    );
  };

/**
 * Request search client questionnaires
 * @param {*} requestDetails
 * @param {*} handleFilterRequestSuccessCallback
 * @param {*} handleFilterRequestFailedCallback
 * @returns
 */
export const requestFilterClientQuestionnaires =
  (
    requestDetails,
    handleFilterRequestSuccessCallback = null,
    handleFilterRequestFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postFilterClientQuestionnairesRequest' });

    const { clientId, filterRequestData } = requestDetails;

    const clientQuestionnaireRequestData = {
      url: API_URL.client.clientQuestionnaire.POST_FILTER_CLIENT_QUESTIONNAIRES,
      method: 'POST',
      data: { ...filterRequestData, clientId },
    };
    const requestGetClientQuestionnaireSuccess = (response) => {
      dispatch({ type: 'postFilterClientQuestionnairesRequestSuccess', payload: response?.data });
      handleFilterRequestSuccessCallback && handleFilterRequestSuccessCallback(response?.data);
    };
    const requestGetClientQuestionnaireFailed = (error) => {
      dispatch({ type: 'postFilterClientQuestionnairesRequestFailed', payload: error });
      handleFilterRequestFailedCallback && handleFilterRequestFailedCallback(error);
    };

    http_request(
      clientQuestionnaireRequestData,
      requestGetClientQuestionnaireSuccess,
      requestGetClientQuestionnaireFailed
    );
  };

/**
 * Get Client Status
 * @param {*} handleGetStatusQuestionnaireSuccessCallback
 * @param {*} handleGetStatusQuestionnaireFailedCallback
 * @returns {async function}
 */
export const requestGetStatusQuestionnaire =
  (
    requestDetails,
    handleGetStatusQuestionnaireSuccessCallback = null,
    handleGetStatusQuestionnaireFailedCallback = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: 'getStatusQuestionnaireRequest' });

    const { clientId } = requestDetails;
    const clientQuestionnaireRequestData = {
      url: API_URL.client.clientQuestionnaire.GET_STATUS_QUESTIONNAIRE.replace(
        '{clientId}',
        clientId
      ),
      method: 'GET',
    };
    const requestGetClientQuestionnaireSuccess = (response) => {
      dispatch({ type: 'getStatusQuestionnaireRequestSuccess', payload: response?.data });

      handleGetStatusQuestionnaireSuccessCallback?.(response?.data);
    };
    const requestGetClientQuestionnaireFailed = (error) => {
      dispatch({ type: 'getStatusQuestionnaireRequestFailed', payload: defaultQuestionnairesData });

      handleGetStatusQuestionnaireFailedCallback?.(error);
    };

    http_request(
      clientQuestionnaireRequestData,
      requestGetClientQuestionnaireSuccess,
      requestGetClientQuestionnaireFailed
    );
  };
