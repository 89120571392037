import React from 'react';
import PropTypes from 'prop-types';
import CommonModal from 'common/material/CommonModal';
import { Grid, SvgIcon } from '@mui/material';

import { ReactComponent as meetingScheduledImage } from 'assets/images/successModals/meetingScheduled.svg';

const SuccessMeetingContent = () => {
  return (
    <Grid container>
      <SvgIcon
        viewBox="0 0 342 263"
        component={meetingScheduledImage}
        sx={{ width: '100%', height: '100%' }}
      />
    </Grid>
  );
};
const MeetingScheduledModal = (props) => {
  const { isOpened, handleModalClose } = props;
  return (
    <CommonModal
      open={isOpened}
      handleDialogOpened={handleModalClose}
      dialogContent={<SuccessMeetingContent />}
      dialogProps={{
        sx: {
          '& .MuiDialogContent-root': {
            zIndex: (theme) => theme.zIndex.modal + 1,
          },
        },
      }}
    />
  );
};

MeetingScheduledModal.propTypes = {
  isOpened: PropTypes.bool,
  handleModalClose: PropTypes.func,
};

export default MeetingScheduledModal;
