import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isFinancialPositionGetSummaryRequestLoading: false,
  financialPositionGetSummaryRequestSuccessData: {},
  financialPositionGetSummaryRequestFailedData: {},
};

export const financialPositionsReducer = createReducer(initialState, {
  //get financial position summary
  getFinancialPositionSummaryRequest: (state) => {
    state.isFinancialPositionGetSummaryRequestLoading = true;
    state.financialPositionGetSummaryRequestSuccessData = {};
    state.financialPositionGetSummaryRequestFailedData = {};
  },
  getFinancialPositionSummarySuccess: (state, action) => {
    state.isFinancialPositionGetSummaryRequestLoading = false;
    state.financialPositionGetSummaryRequestSuccessData = action.payload;
    state.financialPositionGetSummaryRequestFailedData = {};
  },
  getFinancialPositionSummaryFailed: (state, action) => {
    state.isFinancialPositionGetSummaryRequestLoading = false;
    state.financialPositionGetSummaryRequestSuccessData = {};
    state.financialPositionGetSummaryRequestFailedData = action.payload;
  },
});
