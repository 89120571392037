import { Grid } from '@mui/material';

import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import FormTopContainer from 'ui-component/dashboard/FormTopContainer';
import QuestionnaireForm from './components/QuestionnaireForm';

const AnswerQuestionnaire = () => {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12}>
        <MainCard>
          <FormTopContainer title={'Questionnaire Answers'} />
        </MainCard>
      </Grid>
      <Grid container item xs={12}>
        <QuestionnaireForm />
      </Grid>
    </Grid>
  );
};

export default AnswerQuestionnaire;
