export default function componentStyleOverrides(theme) {
  const bgColor = theme.colors?.grey50;
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: '4px',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px',
        },
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          // color: theme.darkTextPrimary,
          color: '#ffffff',
          paddingTop: '10px',
          paddingBottom: '10px',
          borderRadius: `0 ${theme.customization.borderRadius}px ${theme.customization.borderRadius}px 0 !important`,
          fontWeight: 701,

          '&.Mui-selected': {
            // color: theme.menuSelected,
            color: theme.colors?.primaryMain,
            backgroundColor: '#ffffff',
            fontWeight: 701,
            '&:hover': {
              backgroundColor: '#ffffff',
              fontWeight: 701,
            },
            '& .MuiListItemIcon-root': {
              // color: theme.menuSelected,
              color: theme.colors?.primaryMain,
              fontWeight: 701,
            },
          },
          '&:hover': {
            backgroundColor: '#ffffff',
            color: theme.colors?.primaryMain,
            '& .MuiListItemIcon-root': {
              color: theme.colors?.primaryMain,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // color: theme.darkTextPrimary,
          color: '#ffffff',
          minWidth: '36px',
          '&:hover': {
            color: theme.colors?.primaryMain,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState, theme: muiTheme }) => ({
          backgroundColor:
            (ownerState?.disabled && muiTheme.palette?.grey?.[300] + ' !important') || undefined,
        }),
        input: {
          color: theme.textDark,
          paddingInline: '0.5rem !important',
          paddingBlock: '0.875rem 0.875rem !important',
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 10,
          // fontSize: '1rem !important',
          fontWeight: 500,
          color: theme.color?.primaryMain,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors?.grey400,
            '& .Mui-disabled': {
              backgroundColor: theme.colors?.grey300 + ' !important',
            },
          },
          '&:hover $notchedOutline': {
            borderColor: theme.colors?.primaryLight,
          },
          '&.MuiInputBase-multiline': {
            padding: 1,
          },
          '& .Mui-disabled': {
            backgroundColor: theme.colors?.grey300 + ' !important',
          },
        },
        input: {
          fontWeight: 500,
          background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
            '& .Mui-disabled': {
              backgroundColor: theme.colors?.grey300 + ' !important',
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: '4px',
        },
        valueLabel: {
          color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          minWidth: '2.125rem !important',
          textAlignLast: 'left !important',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: theme.colors?.blue150,
          },
          '&.Mui-selected': {
            backgroundColor: theme.colors?.blue175,
            ':hover': {
              backgroundColor: theme.colors?.blue175,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: '0 0.5px 1px 0.125px grey',
          borderRadius: `0 0 ${theme?.customization?.borderRadius}px ${theme?.customization?.borderRadius}px`,
          width: '96%',
          marginInline: '2%',
        },
        option: {
          ':hover': {
            backgroundColor: theme.colors?.blue150 + ' !important',
          },
          '&[aria-selected="true"]': {
            backgroundColor: theme.colors?.blue175 + ' !important',
            ':hover': {
              backgroundColor: theme.colors?.blue175,
            }
          },
        } 
      },
    },
  };
}
