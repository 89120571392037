import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import { useSelector } from 'react-redux';

const ClientSentFilesCard = (props) => {
  //file upload reducer
  const filesUploadReducer = useSelector((state) => state.filesUploadReducer);
  const uploadedSentFilesRequestSuccessData =
    filesUploadReducer?.uploadedSentFilesRequestSuccessData;
  /**
   * Format statistic
   * @param {*} value
   * @returns
   */
  const formatStatistic = (value) => {
    return (value.toString && value.toString().padStart(2, '0')) || '00';
  };
  return (
    <MainCard content={false} sx={{ height: '100%', paddingY: 0.5 }}>
      <Box sx={{ paddingX: 2 }} display="flex" height="100%">
        <Grid container alignItems="center">
          <Grid item xs="auto">
            <Typography
              variant="h1"
              sx={(theme) => ({
                color: theme?.palette?.grey?.[750],
                fontSize: '1.25rem',
                fontWeight: 500,
              })}
            >
              Sent Files
            </Typography>
          </Grid>
          <Grid item xs>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item xs="auto">
                <Grid container justifyContent="center">
                  <Typography
                    variant="h1"
                    sx={(theme) => ({ color: theme.palette?.blue?.[600] })}
                    noWrap
                  >
                    {formatStatistic(uploadedSentFilesRequestSuccessData?.length || 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  );
};
export default ClientSentFilesCard;
