import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useCommonStyles } from 'styles/styles';

const TextError = (props) => {
  const { classes: commonClasses } = useCommonStyles();

  return (
    <Typography variant="body2" className={commonClasses.errorMessage}>
      {props.children}
    </Typography>
  );
};

export default TextError;
