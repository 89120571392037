import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    //Create New family and lifestyle protections
    saveFamilyAndLifestyleProtectionRequestLoading: false,
    saveFamilyAndLifestyleProtectionData: {},
    saveFamilyAndLifestyleProtectionFailed: {},

    //Get all protections
    isGetAllProtectionsRequestLoading: false,
    getAllProtectionsRequestSuccessData: {},
    getAllProtectionsRequestFailedData: {},

    //update protections
    isUpdateProtectionRequestLoading: false,
    updateProtectionRequestSuccessData: {},
    updateProtectionRequestFailedData: {},

    //find protection details
    isGetProtectionDetailFindRequestLoading: false,
    findProtectionRequestSuccessData: {},
    findProtectionRequestFailedData: {},

    //get all fund owners
    isGetAllSuperannuationFundOwnersRequestLoading: false,
    getAllSuperannuationFundOwnersRequestSuccessData: {},
    getAllSuperannuationFundOwnersRequestFailedData: {},

    //delete family and lifestyle protection
    isDeleteFamilyAndLifestyleProtectionRequestLoading: false,
    deleteFamilyAndLifestyleProtectionRequestSuccessData: {},
    deleteFamilyAndLifestyleProtectionRequestFailedData: {},

};

export const familyAndLifestyleProtectionsReducer = createReducer(initialState, {
    //save family and lifestyle protections
    postSaveFamilyAndLifestyleProtectionRequest: (state) => {
        state.saveFamilyAndLifestyleProtectionRequestLoading = true;
        state.saveFamilyAndLifestyleProtectionData = {};
        state.saveFamilyAndLifestyleProtectionFailed = {};
    },
    postSaveFamilyAndLifestyleProtectionSuccess: (state, action) => {
        state.saveFamilyAndLifestyleProtectionRequestLoading = false;
        state.saveFamilyAndLifestyleProtectionData = action.payload;
        state.saveFamilyAndLifestyleProtectionFailed = {};
    },
    postSaveFamilyAndLifestyleProtectionFailed: (state, action) => {
        state.saveFamilyAndLifestyleProtectionRequestLoading = false;
        state.saveFamilyAndLifestyleProtectionData = {};
        state.saveFamilyAndLifestyleProtectionFailed = action.payload;
    },

    ///get all protections
    getAllProtectionsRequest: (state) => {
        state.isGetAllProtectionsRequestLoading = true;
        state.getAllProtectionsRequestSuccessData = {};
        state.getAllProtectionsRequestFailedData = {};
    },
    getAllProtectionsRequestSuccess: (state, action) => {
        state.isGetAllProtectionsRequestLoading = false;
        state.getAllProtectionsRequestSuccessData = action.payload;
        state.getAllProtectionsRequestFailedData = {};
    },
    getAllProtectionsRequestFailed: (state, action) => {
        state.isGetAllProtectionsRequestLoading = false;
        state.getAllProtectionsRequestSuccessData = {};
        state.getAllProtectionsRequestFailedData = action.payload;
    },

    //update protection
    updateProtectionRequest: (state) => {
        state.isUpdateProtectionRequestLoading = true;
        state.updateProtectionRequestSuccessData = {};
        state.updateProtectionRequestFailedData = {};
    },
    updateProtectionRequestSuccess: (state, action) => {
        state.isUpdateProtectionRequestLoading = false;
        state.updateProtectionRequestSuccessData = action.payload
        state.updateProtectionRequestFailedData = {}
    },
    updateProtectionRequestFailed: (state, action) => {
        state.isUpdateProtectionRequestLoading = false;
        state.updateProtectionRequestSuccessData = {};
        state.updateProtectionRequestFailedData = action.payload;
    },

    //find protection
    findProtectionRequest: (state) => {
        state.isGetProtectionDetailFindRequestLoading = true;
        state.findProtectionRequestSuccessData = {};
        state.findProtectionRequestFailedData = {};
    },
    findProtectionRequestSuccess: (state, action) => {
        state.isGetProtectionDetailFindRequestLoading = false;
        state.findProtectionRequestSuccessData = action.payload;
        state.findProtectionRequestFailedData = {};
    },
    findProtectionRequestFailed: (state, action) => {
        state.isGetProtectionDetailFindRequestLoading = false;
        state.findProtectionRequestSuccessData = {};
        state.findProtectionRequestFailedData = action.payload;
    },

    ///get all superannuation fund owners
    getAllSuperannuationFundOwnersRequest: (state) => {
        state.isGetAllSuperannuationFundOwnersRequestLoading = true;
        state.getAllSuperannuationFundOwnersRequestSuccessData = {};
        state.getAllSuperannuationFundOwnersRequestFailedData = {};
    },
    getAllSuperannuationFundOwnersRequestSuccess: (state, action) => {
        state.isGetAllSuperannuationFundOwnersRequestLoading = false;
        state.getAllSuperannuationFundOwnersRequestSuccessData = action.payload;
        state.getAllSuperannuationFundOwnersRequestFailedData = {};
    },
    getAllSuperannuationFundOwnersRequestFailed: (state, action) => {
        state.isGetAllSuperannuationFundOwnersRequestLoading = false;
        state.getAllSuperannuationFundOwnersRequestSuccessData = {};
        state.getAllSuperannuationFundOwnersRequestFailedData = action.payload;
    },

    //delete family and lifestyle protection details
    deleteFamilyAndLifestyleProtectionRequest: (state) => {
        state.isDeleteFamilyAndLifestyleProtectionRequestLoading = true
        state.deleteFamilyAndLifestyleProtectionRequestSuccessData = {}
        state.deleteFamilyAndLifestyleProtectionRequestFailedData = {}
    },
    deleteFamilyAndLifestyleProtectionRequestSuccess: (state, action) => {
        state.isDeleteFamilyAndLifestyleProtectionRequestLoading = false
        state.deleteFamilyAndLifestyleProtectionRequestSuccessData = action?.payload
        state.deleteFamilyAndLifestyleProtectionRequestFailedData = {}
    },
    deleteFamilyAndLifestyleProtectionRequestFailed: (state, action) => {
        state.isDeleteFamilyAndLifestyleProtectionRequestLoading = false
        state.deleteFamilyAndLifestyleProtectionRequestSuccessData = {}
        state.deleteFamilyAndLifestyleProtectionRequestFailedData = action?.payload
    },
});
