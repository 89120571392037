import React from 'react';
import ActionButtonCard from './ActionButtonCard';

const TotalIncomeActionCard = (props) => {
  const { selectedTabButton, onSelectTabButton, value } = props;
  return (
    <ActionButtonCard
      actionLabel="Total Income"
      actionType="incomes"
      actionTypeStatistic={value}
      isSelected={selectedTabButton === 'incomes'}
      handleSelectActionCard={onSelectTabButton}
    />
  );
};
export default TotalIncomeActionCard;
