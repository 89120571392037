import { createReducer } from '@reduxjs/toolkit';

export const initialState = {
  GOAL_MODAL: {
    modalName: 'GOAL_MODAL',
    modalStatus: false,
  },
  ADD_CLIENT_FILE_MODAL: {
    modalName: 'ADD_CLIENT_FILE_MODAL',
    modalStatus: false,
  },
  ADD_CLIENT_TASK_MODAL: {
    modalName: 'ADD_CLIENT_TASK_MODAL',
    modalStatus: false,
  },
  SEND_CLIENT_EMAIL_MODAL: {
    modalName: 'SEND_CLIENT_EMAIL_MODAL',
    modalStatus: false,
  },
  ADD_TASK_RECURRENCE_RULE_MODAL: {
    modalName: 'ADD_TASK_RECURRENCE_RULE_MODAL',
    modalStatus: false,
  },
  VIDEO_NAME_EDIT_MODAL: {
    modalName: 'VIDEO_NAME_EDIT_MODAL',
    modalStatus: false,
  },
  VIDEO_UPLOAD_MODAL: {
    modalName: 'VIDEO_UPLOAD_MODAL',
    modalStatus: false,
  },
  FILE_UPLOAD_MODAL: {
    modalName: 'FILE_UPLOAD_MODAL',
    modalStatus: false,
  },
  DOCUMENT_PREVIEW_MODAL: {
    modalName: 'DOCUMENT_PREVIEW_MODAL',
    modalStatus: false,
    modalData: null,
  },
};

export const modalReducer = createReducer(initialState, {
  HANDLE_MODAL: (state, action) => {
    if (action.payload.modalName === 'GOAL_MODAL') {
      state = {
        ...state,
        GOAL_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'ADD_CLIENT_FILE_MODAL') {
      state = {
        ...state,
        ADD_CLIENT_FILE_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'ADD_CLIENT_TASK_MODAL') {
      state = {
        ...state,
        ADD_CLIENT_TASK_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'SEND_CLIENT_EMAIL_MODAL') {
      state = {
        ...state,
        SEND_CLIENT_EMAIL_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'ADD_TASK_REMINDER_MODAL') {
      state = {
        ...state,
        ADD_TASK_REMINDER_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'VIDEO_NAME_EDIT_MODAL') {
      state = {
        ...state,
        VIDEO_NAME_EDIT_MODAL: action.payload,
      };
    }
    if (action.payload.modalName === 'VIDEO_UPLOAD_MODAL') {
      state = {
        ...state,
        VIDEO_UPLOAD_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'FILE_UPLOAD_MODAL') {
      state = {
        ...state,
        FILE_UPLOAD_MODAL: action.payload,
      };
    }
    if (action.payload?.modalName === 'DOCUMENT_PREVIEW_MODAL') {
      state = {
        ...state,
        DOCUMENT_PREVIEW_MODAL: action.payload,
      };
    }
    return state;
  },
});
