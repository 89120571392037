import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    //settings data request
    settingsRequestLoading: false,
    settingsData: {},
    settingsFailed: {},

    passwordUpdateRequestLoading: false,
    passwordUpdateRequestSuccessData: {},
    passwordUpdateRequestFailedData: {},
};

export const settingsReducer = createReducer(initialState, {
    //signup
    getSettingsRequestRequest: (state) => {
        state.settingsRequestLoading = true;
        state.settingsData = {};
        state.settingsFailed = {};
    },
    getSettingsRequestSuccess: (state, action) => {
        state.settingsRequestLoading = false;
        state.settingsData = action.payload;
        state.settingsFailed = {};
    },
    getSettingsRequestFailed: (state, action) => {
        state.settingsRequestLoading = false;
        state.settingsData = {};
        state.settingsFailed = action.payload;
    },

    //reset settings data
    resetSettingsData: (state, action) => {
        state = initialState;
    },


    putUpdatePasswordRequest: (state) => {
        state.passwordUpdateRequestLoading = true;
        state.passwordUpdateRequestSuccessData = {};
        state.passwordUpdateRequestFailedData = {};
    },
    putUpdatePasswordRequestSuccess: (state, action) => {
        state.passwordUpdateRequestLoading = false;
        state.passwordUpdateRequestSuccessData = action.payload;
        state.passwordUpdateRequestFailedData = {};
    },
    putUpdatePasswordRequestFailed: (state, action) => {
        state.passwordUpdateRequestLoading = false;
        state.passwordUpdateRequestSuccessData = {};
        state.passwordUpdateRequestFailedData = action.payload;
    },
});
