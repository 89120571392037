import { Divider, Grid, Skeleton, Stack } from '@mui/material';

import { gridSpacing } from 'store/constant';

const QuestionSectionContentSkeleton = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        marginTop: '2rem',
        maxWidth: '100%',
        overflowX: 'scroll'
      }}
    >
      <Stack direction="row" spacing={gridSpacing} alignItems="center" sx={{ userSelect: 'none', paddingRight: '1rem' }}>
        <Skeleton variant="text" sx={{ minWidth: '24ch' }} />
        <Divider
          variant="fullWidth"
          orientation="horizontal"
          flexItem
          sx={{
            flex: 1,
            alignSelf: 'center',
            borderWidth: '0.1rem',
            borderRadius: '0.1rem',
          }}
        />
        <Skeleton variant="circular" animation="wave" sx={{ width: '2.5rem', height: '2.5rem' }} />
      </Stack>

      <Stack
        direction="row"
        spacing={gridSpacing}
        alignItems="center"
        sx={{ userSelect: 'none', marginBlock: '1rem', paddingLeft: '2rem' }}
      >
        <Skeleton variant="text" sx={{ minWidth: '30ch' }} />
      </Stack>

      <Stack
        direction="row"
        spacing={gridSpacing}
        alignItems="center"
        flexWrap="nowrap"
        sx={{ userSelect: 'none', marginBlock: '1rem', paddingLeft: '2rem', }}
      >
        <Skeleton variant="circular" animation="wave" sx={{ width: '1rem', height: '1rem' }} />
        <Skeleton variant="text" sx={{ minWidth: '10ch' }} />
        <Skeleton
          variant="circular"
          animation="wave"
          sx={{ width: '1rem', height: '1rem', paddingLeft: '1rem' }}
        />
        <Skeleton variant="text" sx={{ minWidth: '10ch' }} />
      </Stack>

      <Stack
        direction="row"
        spacing={gridSpacing}
        alignItems="center"
        sx={{ userSelect: 'none', marginTop: '2rem', paddingRight: '1rem' }}
      >
        <Skeleton variant="text" sx={{ minWidth: '32ch' }} />
        <Divider
          variant="fullWidth"
          orientation="horizontal"
          flexItem
          sx={{
            flex: 1,
            alignSelf: 'center',
            borderWidth: '0.1rem',
            borderRadius: '0.1rem',
          }}
        />
        <Skeleton variant="circular" animation="wave" sx={{ width: '2.5rem', height: '2.5rem' }} />
      </Stack>

      <Stack
        direction="row"
        spacing={gridSpacing}
        alignItems="center"
        sx={{ userSelect: 'none', marginBlock: '1rem', paddingLeft: '2rem' }}
      >
        <Skeleton variant="text" sx={{ minWidth: '30ch' }} />
      </Stack>
      <Stack
        direction="row"
        spacing={gridSpacing}
        alignItems="center"
        flexWrap="nowrap"
        sx={{ userSelect: 'none', marginBlock: '1rem', paddingLeft: '2rem' }}
      >
        <Skeleton variant="circular" animation="wave" sx={{ width: '1rem', height: '1rem' }} />
        <Skeleton variant="text" sx={{ minWidth: '10ch' }} />
        <Skeleton
          variant="circular"
          animation="wave"
          sx={{ width: '1rem', height: '1rem'}}
        />
        <Skeleton variant="text" sx={{ minWidth: '12ch' }} />
      </Stack>
    </Grid>
  );
};

export default QuestionSectionContentSkeleton;
