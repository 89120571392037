import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Box, Grid, SvgIcon, Typography } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';

import AdvisorAddressIcon from 'assets/images/icons/location_marker_green.svg';
import AdvisorEmailIcon from 'assets/images/icons/email_round_green.svg';
import AdvisorWebsiteIcon from 'assets/images/icons/web_round_green.svg';
import { requestGetOrganizationSetting } from 'redux/Actions/organizationSettingsAction';
import defaultOrganizationLogo from 'assets/logo/souffle_logo.gif';

const ClientAdvisorFirmCard = () => {
  const dispatch = useDispatch();
  // client detail store
  const clientReducer = useSelector((state) => state.clientReducer);
  const { selectedClientDetail } = clientReducer;
  //organization store
  const organizationSettingsReducer = useSelector((state) => state.organizationSettingsReducer);

  const { organizationSettingsSuccessData } = organizationSettingsReducer;

  React.useEffect(() => {
    !(
      organizationSettingsSuccessData?.data &&
      Array.isArray(organizationSettingsSuccessData.data) &&
      organizationSettingsSuccessData.data.length > 0
    ) &&
      selectedClientDetail?._id &&
      dispatch(requestGetOrganizationSetting());
  }, [selectedClientDetail]);

  const handleOrganizationSettingsDetails = (fieldNames) => {
    if (
      organizationSettingsSuccessData?.data &&
      Array.isArray(organizationSettingsSuccessData.data) &&
      organizationSettingsSuccessData.data.length > 0 &&
      organizationSettingsSuccessData.data[0][fieldNames]
    ) {
      return organizationSettingsSuccessData.data[0][fieldNames];
    } else {
      return 'Not Provided';
    }
  };

  return (
    <MainCard content={false} sx={{ height: '100%', paddingY: 1 }}>
      <Box sx={{ paddingX: 2.125 }}>
        <Grid container pb={0.25}>
          <Typography
            sx={(theme) => ({
              color: theme?.palette?.grey?.[750],
              fontSize: '1.25rem',
              fontWeight: 500,
            })}
          >
            Your Advisor Firm
          </Typography>
        </Grid>
        <Grid container columnSpacing={4}>
          <Grid item xs="auto">
            <Box sx={{ paddingX: 0.125 }}>
              <Avatar
                variant="square"
                sx={(theme) => ({
                  color: '#fff',
                  width: theme.typography.pxToRem(158),
                  height: '7rem',
                  backgroundColor: theme.palette.grey[75],
                  boxShadow: '0px 1px 1px 0px #00000040 inset',
                  borderRadius: '5px',
                })}
              >
                <img
                  alt="Advisor Website"
                  src={
                    handleOrganizationSettingsDetails('organizationPictureUrl') !== 'Not Provided'
                      ? handleOrganizationSettingsDetails('organizationPictureUrl')
                      : defaultOrganizationLogo
                  }
                  style={{
                    width: '100%',
                  }}
                />
              </Avatar>
            </Box>
          </Grid>
          <Grid item xs>
            <Box sx={{ paddingX: 0.125 }}>
              <Grid
                container
                justifyContent="flex-start"
                justifyItems="flex-start"
                alignItems="center"
                textAlign="center"
                textOverflow="ellipsis"
                rowSpacing={1}
              >
                <Grid container item xs={12}>
                  <Typography
                    align="left"
                    sx={(theme) => ({
                      color: theme.palette.blue?.[825],
                      fontSize: '1rem',
                      fontWeight: 500,
                    })}
                  >
                    {handleOrganizationSettingsDetails('companyName')}
                  </Typography>
                </Grid>
                <Grid container item xs={12} rowSpacing={1}>
                  <Grid container item xs={12} columnSpacing={1}>
                    <Grid item xs="auto">
                      <img alt="Advisor Address" src={AdvisorAddressIcon} />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        align="left"
                        sx={(theme) => ({
                          color: theme.palette.grey[750],
                          fontWeight: 400,
                        })}
                      >
                        {handleOrganizationSettingsDetails('address')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} columnSpacing={1}>
                    <Grid item xs="auto">
                      <img alt="Advisor Email" src={AdvisorEmailIcon} />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        align="left"
                        sx={(theme) => ({
                          color: theme.palette.grey[750],
                          fontWeight: 400,
                        })}
                      >
                        {handleOrganizationSettingsDetails('emailAddress')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} columnSpacing={1}>
                    <Grid item xs="auto">
                      <img alt="Advisor Website" src={AdvisorWebsiteIcon} />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        align="left"
                        sx={(theme) => ({
                          color: theme.palette.grey[750],
                          fontWeight: 400,
                        })}
                      >
                        {handleOrganizationSettingsDetails('websiteURL')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  );
};

export default ClientAdvisorFirmCard;
