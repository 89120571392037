import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField, Box } from '@mui/material';
const FormikDatePicker = (props) => {
  const { name, label, views, value, setFieldValue, error, ...restProps } = props;
  return (
    <Box width={'100%'} marginTop={1}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat={'dd/MM/yyyy'}
          {...restProps}
          name={name}
          value={value ?? null}
          onChange={(val) => {
            setFieldValue(name, val);
            restProps?.InputProps?.onBlur && restProps.InputProps.onBlur();
          }}
          views={views}
          error={error}
          helperText="Incorrect entry."
          renderInput={(params) => (
            <TextField {...params} label={label} error={error} helperText={error} fullWidth />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

FormikDatePicker.defaultProps = {
  views: ['year', 'month', 'day'],
};

export default FormikDatePicker;
