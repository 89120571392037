import React from 'react';
import ActionButtonCard from './ActionButtonCard';

const TotalLiabilitiesActionCard = (props) => {
  const { selectedTabButton, onSelectTabButton, value } = props;
  return (
    <ActionButtonCard
      actionLabel="Total Liabilities"
      actionType="liabilities"
      actionTypeStatistic={value}
      isSelected={selectedTabButton === 'liabilities'}
      handleSelectActionCard={onSelectTabButton}
    />
  );
};
export default TotalLiabilitiesActionCard;
