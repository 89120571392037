import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';
/**
 * Get Financial Position Summary
 * @param {*} financialPositionId
 * @param {*} handleSuccessCallback
 * @param {*} handleErrorCallback
 * @returns
 */
export const requestGetFinancialPositionSummary =
  (financialPositionId, handleSuccessCallback, handleErrorCallback) =>
  async (dispatch, getState) => {
    dispatch({
      type: 'getFinancialPositionSummaryRequest',
    });

    const requestData = {
      url: API_URL.client.financial.financialPosition.GET_FINANCIAL_POSITION_SUMMARY.replace(
        '{financialPositionId}',
        financialPositionId
      ),
      method: 'GET',
    };

    const successCallback = (response) => {
      dispatch({ type: 'getFinancialPositionSummarySuccess', payload: response.data });
      handleSuccessCallback && handleSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'getFinancialPositionSummaryFailed', payload: error });
      const message =
        error?.response?.data?.name || 'The attempt to obtain the financial position has failed';
      Store.dispatch(handleNotification(message, 'error'));
      handleErrorCallback && handleErrorCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };
