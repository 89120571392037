import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Fixed Circular Loading Spinner
 * @param {object} props
 * @returns
 */
const LoadingSpinner = (props) => {
  const { isLoadingSpinner, isAbsolutePositioned } = props;

  return (
    <Backdrop
      sx={(theme) => ({
        color: theme.palette.background.paper,
        zIndex: theme.zIndex.snackbar - 1,
        [isAbsolutePositioned && 'position']: 'absolute',
      })}
      open={isLoadingSpinner}
    >
      <CircularProgress size="5rem" color="primary" thickness={4} />
    </Backdrop>
  );
};
LoadingSpinner.defaultProps = {
  isLoadingSpinner: false,
  isAbsolutePositioned: false,
};

LoadingSpinner.propTypes = {
  isLoadingSpinner: PropTypes.bool.isRequired,
  isAbsolutePositioned: PropTypes.bool,
};

export default LoadingSpinner;
