import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Client Invitation Action Request
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestPostAnEmail =
  (requestBody, handlePostEmailSuccessCallback = null, handlePostEmailFailedCallback = null) =>
    async (dispatch, getState) => {
      dispatch({ type: 'postEmailRequest' });

      const requestData = {
        url: API_URL.email.POST_SEND_EMAIL,
        method: 'POST',
        data: requestBody,
      };
      const successCallback = (response) => {
        dispatch({ type: 'postEmailRequestSuccess', payload: response.data });
        Store.dispatch(
          handleNotification(`E-Mail sent to ${requestBody?.toAddress} !`, 'success')
        );
        handlePostEmailSuccessCallback && handlePostEmailSuccessCallback();
      };
      const errorCallback = (error) => {
        dispatch({ type: 'postEmailRequestFailed', payload: error });
        let message = 'Email is Failed to Send !';
        if (error?.response?.status === 500) {
          message = error?.response?.data?.message || 'Please Check Details !';
        }
        Store.dispatch(handleNotification(message, 'error'));
        handlePostEmailFailedCallback && handlePostEmailFailedCallback();
      };
      http_request(requestData, successCallback, errorCallback);
    };

/**
 * Handle request get all emails sent by user
 * @param {*} requestSuccessCallback 
 * @param {*} requestFailedCallback 
 * @returns 
 */
export const requestGetAllEmailsSentByUser = (requestSuccessCallback = null, requestFailedCallback = null) => async (dispatch, getState) => {
  dispatch({ type: 'getAllEmailsSentRequest' });

  const requestData = {
    url: API_URL.email.GET_SENT_EMAILS,
    method: 'GET',
  };
  const successCallback = (response) => {
    dispatch({ type: 'getAllEmailsSentRequestSuccess', payload: response.data });
    requestSuccessCallback && requestSuccessCallback(response?.data);
  };
  const errorCallback = (error) => {
    dispatch({ type: 'getAllEmailsSentRequestFailed', payload: error });
    requestFailedCallback && requestFailedCallback(error);
  };
  http_request(requestData, successCallback, errorCallback);
}