import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { format, isDate, isValid } from 'date-fns';
import { isEmpty, isNil } from 'ramda';

import CommonModal from 'common/material/CommonModal';
import InputField from 'common/material/InputField';
import DropDown from 'common/material/Dropdown';
import MessageSentModal from './MessageSentModal';
import MeetingScheduledModal from './MeetingScheduledModal';
import SyncFusionEditor from 'common/SyncFusionEditor';
import LoadingSpinner from 'ui-component/LoadingSpinner';
import { useCommonStyles } from 'styles/styles';

import { handleNotification } from 'redux/Actions/handleNotificationAction';
import { requestGetAllEmailsSentByUser, requestPostAnEmail } from 'redux/Actions/emailAction';
import { requestGetAllTeamMembers } from 'redux/Actions/teamMembersActions';

import { SendRounded } from '@mui/icons-material';

const MessageForm = (props) => {
  const { handleGetAllEmailsSentByUser, readAndWriteAccess, handleClose } = props;
  /**
   * Uses common classes
   */
  const {
    classes: { saveBtn },
  } = useCommonStyles();
  /**
   * Redux Integrations
   */
  const dispatch = useDispatch();
  //email store
  const emailPostsReducer = useSelector((state) => state?.emailPostsReducer);
  const isEmailPosting = emailPostsReducer?.isEmailPosting;
  //team member store
  const teamMembersReducer = useSelector((state) => state.teamMembersReducer);
  const isTeamMembersListLoading = teamMembersReducer?.isTeamMembersListLoading;
  const teamMembersListData = teamMembersReducer?.teamMembersListData;
  //client store
  const clientReducer = useSelector((state) => state?.clientReducer);
  const selectedClientDetail = clientReducer?.selectedClientDetail;

  const [isMessageSentOpened, setIsMessageSentOpened] = useState(false);
  const [advisorsContactData, setAdvisorsContactData] = useState([]);
  const [meetingContactValue, setMeetingContactValue] = useState(null);
  const [emailFormData, setEmailFormData] = useState({
    to: null,
    subject: '',
    message: '',
    errors: {},
  });

  const handleOpenMessageSentModal = () => {
    setIsMessageSentOpened(true);
  };
  const handleCloseMessageSentModal = () => {
    setIsMessageSentOpened(false);
    handleClose && handleClose();
  };
  /**
   * Handle Change email form data
   * @param {*} fieldValue
   * @param {*} fieldName
   */
  const handleEmailFormValueChange = (fieldValue, fieldName, error = null) => {
    setEmailFormData((prev) => ({
      ...prev,
      [fieldName]: fieldValue,
      errors: {
        ...prev.errors,
        [fieldName]: error,
      },
    }));
  };
  /**
   * Handle change Email message
   * @param {*} data
   */
  const handleChangeEmailMessage = (data) => {
    let [editorContent, changeEventName, isEditorInteracted] = [
      data?.value,
      data?.name,
      data?.isInteracted,
    ];
    handleEmailFormValueChange(editorContent, 'message', !editorContent && 'Required !');
  };
  /**
   * Get validation status of the form fields
   * @returns {object} validationData
   */
  const handleValidateEmailForm = () => {
    let [isFormError, errorMessage] = [false, ''];

    if (!emailFormData?.to?.emailAddress) {
      isFormError = true;
      errorMessage = 'Sender Email Required !';
      handleEmailFormValueChange(null, 'to', 'Required !');
    }
    if (!emailFormData?.subject) {
      isFormError = true;
      errorMessage = 'Email Subject is Required !';
      handleEmailFormValueChange('', 'subject', 'Required !');
    }
    if (!emailFormData?.message) {
      isFormError = true;
      errorMessage = 'Email Message is Required !';
      handleEmailFormValueChange('', 'message', 'Required !');
    }
    return {
      isFormError,
      errorMessage,
    };
  };
  const handleSendEmail = (event) => {
    const { isFormError, errorMessage } = handleValidateEmailForm();

    if (!isFormError) {
      const emailSendRequestData = {
        toAddress: emailFormData?.to?.emailAddress,
        subject: emailFormData?.subject,
        body: emailFormData?.message,
        emailType: 'message',
      };
      const handleEmailSentSuccessCallback = () => {
        handleOpenMessageSentModal();
        handleGetAllEmailsSentByUser && handleGetAllEmailsSentByUser();
      };
      dispatch(requestPostAnEmail(emailSendRequestData, handleEmailSentSuccessCallback));
    } else {
      dispatch(handleNotification(errorMessage, 'error'));
    }
  };

  const validateEmail = (text) => {
    let isValid = typeof text === 'string' && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text);
    return isValid;
  };
  /**
   * Effect on Team members details
   */
  useEffect(() => {
    if (
      Array.isArray(teamMembersListData) &&
      !isEmpty(teamMembersListData) &&
      !isNil(teamMembersListData)
    ) {
      const tempTeamMembers = teamMembersListData.map((singleTeamMember) => {
        const teamMemberName =
          (singleTeamMember?.surName &&
            `${singleTeamMember?.firstName} ${singleTeamMember?.surName}`) ||
          singleTeamMember?.firstName;
        const teamMemberEmail = singleTeamMember?.emailAddress;
        return {
          ...singleTeamMember,
          contactTitle: `${teamMemberName} - ${teamMemberEmail}`,
        };
      });
      setAdvisorsContactData(tempTeamMembers);
    } else {
      setAdvisorsContactData([]);
    }
  }, [isTeamMembersListLoading, teamMembersListData]);
  /**
   * Effect on Client Details or team member details
   */
  useEffect(() => {
    if (
      !isEmpty(advisorsContactData) &&
      !isEmpty(selectedClientDetail) &&
      !isNil(advisorsContactData) &&
      !isNil(selectedClientDetail)
    ) {
      let clientAdvisorId = selectedClientDetail?.adviceRelationship?.advisorId;
      let foundAdvisorDetail = advisorsContactData.find(
        (singleTeamMember) => singleTeamMember?._id === clientAdvisorId
      );
      if (foundAdvisorDetail) {
        handleEmailFormValueChange(foundAdvisorDetail, 'to', false);
      } else {
        handleEmailFormValueChange(null, 'to', false);
      }

      const clientName = `${selectedClientDetail?.title ? selectedClientDetail?.title + ' ' : ''}${
        selectedClientDetail?.firstName || ''
      }${selectedClientDetail?.surName ? ' ' + selectedClientDetail?.surName : ''}`;
      if (clientName) {
        handleEmailFormValueChange(
          `<p>From( Client ): ${clientName}(<span style='text-decoration: underline; color: midnightblue;'>${selectedClientDetail?.primaryEmailAddress}</span>)</p>`,
          'message',
          null
        );
      }
    } else {
      handleEmailFormValueChange(null, 'to', false);
    }
  }, [selectedClientDetail, advisorsContactData]);

  return (
    <>
      <MessageSentModal
        isOpened={isMessageSentOpened}
        handleModalClose={handleCloseMessageSentModal}
      />
      <Grid item xs={12}>
        <Autocomplete
          value={emailFormData?.to}
          onChange={(event, newValue) => {
            handleEmailFormValueChange(newValue || null, 'to', !newValue && 'Required !');
          }}
          getOptionLabel={(option) => option?.contactTitle}
          inputValue={meetingContactValue}
          onInputChange={(event, newInputValue) => {
            setMeetingContactValue(newInputValue);
          }}
          id="meetingContactToEmail"
          options={advisorsContactData}
          sx={(theme) => ({
            width: '100%',
            '& .MuiAutocomplete-input': {
              padding: '0.325rem 0 !important',
            },
          })}
          blurOnSelect
          renderInput={({ InputLabelProps, ...params }) => (
            <InputField
              size="small"
              {...params}
              label="To"
              InputLabelProps={{
                shrink: !!(
                  meetingContactValue ||
                  emailFormData?.to ||
                  document.activeElement?.id === 'meetingContactToEmail'
                ),
                ...InputLabelProps,
              }}
              error={emailFormData?.errors?.to}
              helperText={emailFormData?.errors?.to || ''}
            />
          )}
          disabled={!readAndWriteAccess}
          size="medium"
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          isFormControlField
          inputLabel="Subject"
          placeholder={'Subject here'}
          onChange={(event) =>
            handleEmailFormValueChange(
              event?.target?.value,
              'subject',
              !event?.target?.value && 'Required !'
            )
          }
          disabled={!readAndWriteAccess}
          error={emailFormData?.errors?.subject}
          helperText={emailFormData?.errors?.subject || ''}
          onBlur={(event) =>
            handleEmailFormValueChange(
              event?.target?.value,
              'subject',
              !event?.target?.value && 'Required !'
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <SyncFusionEditor
          label="Message"
          editorId="send-email-meeting-detail-editor"
          onEditorChange={handleChangeEmailMessage}
          editorValue={emailFormData?.message}
          error={emailFormData?.errors?.message}
          disabled={!readAndWriteAccess}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <LoadingButton
            className={saveBtn}
            id={'calculate-btn'}
            endIcon={<SendRounded />}
            loading={isEmailPosting}
            loadingPosition="end"
            variant="contained"
            onClick={handleSendEmail}
            disabled={!readAndWriteAccess}
          >
            Send Email
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

const MeetingForm = (props) => {
  const { handleGetAllEmailsSentByUser, readAndWriteAccess, handleClose } = props;
  /**
   * Uses common classes
   */
  const {
    classes: { saveBtn },
  } = useCommonStyles();
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  //email reducer
  const emailPostsReducer = useSelector((state) => state?.emailPostsReducer);
  const isEmailPosting = emailPostsReducer?.isEmailPosting;
  //team member store
  const teamMembersReducer = useSelector((state) => state.teamMembersReducer);
  const isTeamMembersListLoading = teamMembersReducer?.isTeamMembersListLoading;
  const teamMembersListData = teamMembersReducer?.teamMembersListData;
  //client store
  const clientReducer = useSelector((state) => state?.clientReducer);
  const selectedClientDetail = clientReducer?.selectedClientDetail;

  const [filterDateValue, setFilterDateValue] = useState();
  const [isAnotherDateTime, setIsAnotherDateTime] = useState();
  const [isMeetingScheduledOpened, setIsMeetingScheduledOpened] = useState(false);
  const [advisorsContactData, setAdvisorsContactData] = useState([]);
  const [meetingContactValue, setMeetingContactValue] = useState(null);
  const [emailFormData, setEmailFormData] = useState({
    date: null,
    time: null,
    to: null,
    subject: '',
    message: '',
    errors: {},
    meetingType: '',
  });

  /**
   * Handle Change email form data
   * @param {*} fieldValue
   * @param {*} fieldName
   */
  const handleEmailFormValueChange = (fieldValue, fieldName, error = null) => {
    setEmailFormData((prev) => ({
      ...prev,
      [fieldName]: fieldValue,
      errors: {
        ...prev.errors,
        [fieldName]: error,
      },
    }));
  };
  /**
   * Handle change Email message
   * @param {*} data
   */
  const handleChangeEmailMessage = (data) => {
    let [editorContent, changeEventName, isEditorInteracted] = [
      data?.value,
      data?.name,
      data?.isInteracted,
    ];
    handleEmailFormValueChange(editorContent, 'message', !editorContent && 'Required !');
  };

  const handleOpenMeetingScheduledModal = () => {
    setIsMeetingScheduledOpened(true);
  };
  const handleCloseMeetingScheduledModal = () => {
    setIsMeetingScheduledOpened(false);
    handleClose && handleClose();
  };
  const handleChangeAnotherDateTime = (event, checked) => {
    setIsAnotherDateTime(checked);
  };
  /**
   * Get Form validation status
   * @returns {object} validationData
   */
  const handleValidateEmailForm = () => {
    let [isFormError, errorMessage] = [false, ''];

    if (!emailFormData?.to?.emailAddress) {
      isFormError = true;
      errorMessage = 'Sender Email Required !';
      handleEmailFormValueChange(null, 'to', 'Required !');
    }
    if (!emailFormData?.meetingType) {
      isFormError = true;
      errorMessage = 'Meeting Type is Required !';
      handleEmailFormValueChange('', 'meetingType', 'Required !');
    }
    if (!emailFormData?.date) {
      isFormError = true;
      errorMessage = 'Meeting Date is Required !';
      handleEmailFormValueChange('', 'date', 'Required !');
    }
    if (!emailFormData?.time) {
      isFormError = true;
      errorMessage = 'Meeting Time is Required !';
      handleEmailFormValueChange('', 'time', 'Required !');
    }
    if (!emailFormData?.message) {
      isFormError = true;
      errorMessage = 'Email Message is Required !';
      handleEmailFormValueChange('', 'message', 'Required !');
    }
    return {
      isFormError,
      errorMessage,
    };
  };
  /**
   * Click on send
   * @param {*} event
   */
  const handleSendMeetingEmail = (event) => {
    const { isFormError, errorMessage } = handleValidateEmailForm();

    if (!isFormError) {
      let messageText = emailFormData?.message;
      if (
        isAnotherDateTime &&
        filterDateValue &&
        isDate(filterDateValue) &&
        isValid(filterDateValue)
      ) {
        const timeText = `${format(filterDateValue, 'yyyy-MM-dd')} ${format(
          filterDateValue,
          'hh:mm aa'
        )}`;

        const anotherTimeText = `<br/><hr style='width:100%;height:0.5px;background-color:grey;color:grey'/><p style='color:grey !important;font-size:0.75rem;font-weight:500;'>If you are unable to join at the mentioned time, I suggest scheduling an alternative meeting for ${timeText}.</p>`;
        messageText = messageText + anotherTimeText;
      }
      const emailSendRequestData = {
        toAddress: emailFormData?.to?.emailAddress,
        subject: `${emailFormData?.meetingType} Scheduled at ${format(
          emailFormData?.date,
          'yyyy-MM-dd'
        )} ${format(emailFormData?.time, 'hh:mm aa')}`,
        body: messageText,
        emailType: 'meetingRequest',
      };
      const handleEmailSentSuccessCallback = () => {
        handleGetAllEmailsSentByUser && handleGetAllEmailsSentByUser();
        handleOpenMeetingScheduledModal();
      };
      dispatch(requestPostAnEmail(emailSendRequestData, handleEmailSentSuccessCallback));
    } else {
      dispatch(handleNotification(errorMessage, 'error'));
    }
  };

  const validateEmail = (text) => {
    let isValid = typeof text === 'string' && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text);
    return isValid;
  };
  /**
   * Effect on Team members details
   */
  useEffect(() => {
    if (
      Array.isArray(teamMembersListData) &&
      !isEmpty(teamMembersListData) &&
      !isNil(teamMembersListData)
    ) {
      const tempTeamMembers = teamMembersListData.map((singleTeamMember) => {
        const teamMemberName =
          (singleTeamMember?.surName &&
            `${singleTeamMember?.firstName} ${singleTeamMember?.surName}`) ||
          singleTeamMember?.firstName;
        const teamMemberEmail = singleTeamMember?.emailAddress;
        return {
          ...singleTeamMember,
          contactTitle: `${teamMemberName} - ${teamMemberEmail}`,
        };
      });
      setAdvisorsContactData(tempTeamMembers);
    } else {
      setAdvisorsContactData([]);
    }
  }, [isTeamMembersListLoading, teamMembersListData]);

  /**
   * Effect on Client Details or team member contacts details
   */
  useEffect(() => {
    if (
      !isEmpty(advisorsContactData) &&
      !isEmpty(selectedClientDetail) &&
      !isNil(advisorsContactData) &&
      !isNil(selectedClientDetail)
    ) {
      let clientAdvisorId = selectedClientDetail?.adviceRelationship?.advisorId;
      let foundAdvisorDetail = advisorsContactData.find(
        (singleTeamMember) => singleTeamMember?._id === clientAdvisorId
      );
      if (foundAdvisorDetail) {
        handleEmailFormValueChange(foundAdvisorDetail, 'to', false);
      } else {
        handleEmailFormValueChange(null, 'to', false);
      }

      const clientName = `${selectedClientDetail?.title ? selectedClientDetail?.title + ' ' : ''}${
        selectedClientDetail?.firstName || ''
      }${selectedClientDetail?.surName ? ' ' + selectedClientDetail?.surName : ''}`;
      if (clientName) {
        handleEmailFormValueChange(
          `<p>From( Client ): ${clientName}(<span style='text-decoration: underline; color: midnightblue;'>${selectedClientDetail?.primaryEmailAddress}</span>)</p>`,
          'message',
          null
        );
      }
    } else {
      handleEmailFormValueChange(null, 'to', false);
    }
  }, [selectedClientDetail, advisorsContactData]);

  return (
    <>
      <MeetingScheduledModal
        isOpened={isMeetingScheduledOpened}
        handleModalClose={handleCloseMeetingScheduledModal}
      />
      <Grid item xs={12}>
        <Autocomplete
          value={emailFormData?.to}
          onChange={(event, newValue) => {
            handleEmailFormValueChange(newValue || null, 'to', !newValue && 'Required !');
          }}
          getOptionLabel={(option) => option?.contactTitle}
          inputValue={meetingContactValue}
          onInputChange={(event, newInputValue) => {
            setMeetingContactValue(newInputValue);
          }}
          id="meetingContactToEmail"
          options={advisorsContactData}
          sx={(theme) => ({
            width: '100%',
            '& .MuiAutocomplete-input': {
              padding: '0.325rem 0 !important',
            },
          })}
          blurOnSelect
          renderInput={({ InputLabelProps, ...params }) => (
            <InputField
              size="small"
              {...params}
              label="To"
              InputLabelProps={{
                shrink: !!(
                  meetingContactValue ||
                  emailFormData?.to ||
                  document.activeElement?.id === 'meetingContactToEmail'
                ),
                ...InputLabelProps,
              }}
              error={emailFormData?.errors?.to}
              helperText={emailFormData?.errors?.to || ''}
            />
          )}
          disabled={!readAndWriteAccess}
          size="medium"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <InputLabel shrink variant="standard">
            Date
          </InputLabel>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            value={emailFormData?.date}
            onChange={(newValue) => {
              handleEmailFormValueChange(
                newValue || null,
                'date',
                (!newValue && 'Required !') ||
                  (newValue && (!isDate(newValue) || !isValid(newValue)) && 'Invalid Date !')
              );
            }}
            renderInput={(params) => (
              <InputField
                {...params}
                // InputLabelProps={{ shrink: true }}
                variant="outlined"
                helperText={emailFormData?.errors?.date}
                error={!!emailFormData?.errors?.date}
              />
            )}
            sx={{
              mt: '1.25rem',
            }}
            disabled={!readAndWriteAccess}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <InputLabel shrink variant="standard">
            Time
          </InputLabel>
          <TimePicker
            value={emailFormData?.time}
            onChange={(newValue) => {
              handleEmailFormValueChange(
                newValue || null,
                'time',
                (!newValue && 'Required !') ||
                  (newValue && (!isDate(newValue) || !isValid(newValue)) && 'Invalid Time !')
              );
            }}
            renderInput={(params) => (
              <InputField
                {...params}
                // InputLabelProps={{ shrink: true }}
                variant="outlined"
                helperText={emailFormData?.errors?.time}
                error={!!emailFormData?.errors?.time}
              />
            )}
            sx={{
              mt: '1.25rem',
            }}
            disabled={!readAndWriteAccess}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              sx={(theme) => ({
                color: theme.palette.green?.['400'],
                '&.Mui-checked': {
                  color: theme.palette.green?.['400'],
                },
              })}
              checked={isAnotherDateTime}
              onChange={handleChangeAnotherDateTime}
            />
          }
          label={
            <Typography
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.green?.['400'],
              })}
            >
              Suggest Another Date Time
            </Typography>
          }
          disabled={!readAndWriteAccess}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <InputLabel shrink variant="standard">
            Date
          </InputLabel>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            value={(isAnotherDateTime && filterDateValue) || ''}
            onChange={(newValue) => {
              setFilterDateValue(newValue);
            }}
            disabled={!isAnotherDateTime || !readAndWriteAccess}
            renderInput={(params) => (
              <InputField
                {...params}
                // InputLabelProps={{ shrink: true }}
                variant="outlined"
                helperText={(isAnotherDateTime && !filterDateValue && 'Required !') || ' '}
                error={!!(isAnotherDateTime && !filterDateValue)}
              />
            )}
            sx={{
              mt: '1.25rem',
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <InputLabel shrink variant="standard">
            Time
          </InputLabel>
          <TimePicker
            value={(isAnotherDateTime && filterDateValue) || ''}
            onChange={(newValue) => {
              setFilterDateValue(newValue);
            }}
            disabled={!isAnotherDateTime || !readAndWriteAccess}
            renderInput={(params) => (
              <InputField
                {...params}
                // InputLabelProps={{ shrink: true }}
                variant="outlined"
                helperText={(isAnotherDateTime && !filterDateValue && 'Required !') || ' '}
                error={!!(isAnotherDateTime && !filterDateValue)}
              />
            )}
            sx={{
              mt: '1.25rem',
            }}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12}>
        <DropDown
          isShrinkLabel
          optionData={[
            // { value: 'Hangouts', name: 'Hangouts' },
            // { value: 'Teams', name: 'Teams' },
            { value: 'Phone Call', name: 'Phone Call' },
            { value: 'Face to Face Meeting', name: 'Face to Face Meeting' },
            { value: 'Online Meeting', name: 'Online Meeting' },
          ]}
          placeholder="Select Message Type"
          formLabel="Meeting Type"
          value={emailFormData?.meetingType}
          onChange={(event) =>
            handleEmailFormValueChange(
              event?.target?.value || null,
              'meetingType',
              !event?.target?.value && 'Required !'
            )
          }
          disabled={!readAndWriteAccess}
          error={!!emailFormData?.errors?.meetingType}
          helperText={emailFormData?.errors?.meetingType}
        />
      </Grid>
      <Grid item xs={12}>
        <SyncFusionEditor
          label="Message"
          editorId="send-email-message-detail-editor"
          onEditorChange={handleChangeEmailMessage}
          editorValue={emailFormData?.message}
          error={emailFormData?.errors?.message}
          disabled={!readAndWriteAccess}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <LoadingButton
            className={saveBtn}
            id={'calculate-btn'}
            endIcon={<SendRounded />}
            loading={isEmailPosting}
            loadingPosition="end"
            variant="contained"
            onClick={handleSendMeetingEmail}
            disabled={!readAndWriteAccess}
          >
            Send Email
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};
const ContactAdvisorForm = (props) => {
  const { readAndWriteAccess, handleClose } = props;

  const {
    classes: { activeButton, inactiveButton },
  } = useCommonStyles();
  /**
   * Redux integration
   */
  const dispatch = useDispatch();
  //team members store
  const teamMembersReducer = useSelector((state) => state?.teamMembersReducer);
  const isTeamMembersListLoading = teamMembersReducer?.isTeamMembersListLoading;

  const [tabButtonState, setTabButtonState] = useState({
    isMessage: true,
    isRequestMeeting: false,
  });

  const handleSelectTabBtn = (tabType) => {
    setTabButtonState((prev) => ({
      isMessage: tabType === 'MESSAGE',
      isRequestMeeting: tabType === 'MEETING',
    }));
  };

  /**
   * Handle get all emails sent by user
   */
  const handleGetAllEmailsSentByUser = () => {
    dispatch(requestGetAllEmailsSentByUser());
  };

  return (
    <Box p={2.25}>
      {!!isTeamMembersListLoading && (
        <LoadingSpinner isLoadingSpinner={!!isTeamMembersListLoading} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            id="messageTabBtn"
            onClick={(e) => handleSelectTabBtn('MESSAGE')}
            className={tabButtonState?.isRequestMeeting ? inactiveButton : activeButton}
            sx={{
              width: '100%',
            }}
          >
            Message
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            id="meetingTabBtn"
            onClick={(e) => handleSelectTabBtn('MEETING')}
            className={tabButtonState?.isRequestMeeting ? activeButton : inactiveButton}
            sx={{
              width: '100%',
            }}
          >
            Meeting
          </Button>
        </Grid>
        {tabButtonState?.isMessage && (
          <MessageForm
            handleGetAllEmailsSentByUser={handleGetAllEmailsSentByUser}
            readAndWriteAccess={readAndWriteAccess}
            handleClose={handleClose}
          />
        )}
        {tabButtonState?.isRequestMeeting && (
          <MeetingForm
            handleGetAllEmailsSentByUser={handleGetAllEmailsSentByUser}
            readAndWriteAccess={readAndWriteAccess}
            handleClose={handleClose}
          />
        )}
      </Grid>
    </Box>
  );
};

const ContactAdvisorModal = (props) => {
  const { isOpened, handleClose, readAndWriteAccess } = props;
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  /**
   * Effect on Team Members
   */
  useEffect(() => {
    dispatch(requestGetAllTeamMembers());
  }, []);

  return (
    <CommonModal
      open={isOpened}
      handleDialogOpened={handleClose}
      dialogTitle={'Contact Advisor'}
      dialogContent={
        <ContactAdvisorForm readAndWriteAccess={readAndWriteAccess} handleClose={handleClose} />
      }
    />
  );
};

ContactAdvisorModal.propTypes = {
  isOpened: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ContactAdvisorModal;
