import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Save Client Action Request
 * @param {object} requestBody
 * @param {Function} handleSaveClientSuccessCallback
 * @param {Function} handleSaveClientFailedCallback
 * @returns
 */
export const requestSaveClient =
  (requestBody, handleSaveClientSuccessCallback, handleSaveClientFailedCallback) =>
    async (dispatch, getState) => {
      dispatch({ type: 'postSaveClientRequest' });

      const requestData = {
        url: API_URL.client.clientList.POST_CREATE_NEW_CLIENT,
        method: 'POST',
        data: requestBody,
      };
      const successCallback = (response) => {
        dispatch({ type: 'postSaveClientSuccess', payload: response.data });
        const successMessage =
          response.data?.message || `Client ${response.data?.firstName} is Saved Successfully !`;
        Store.dispatch(handleNotification(successMessage, 'success'));
        handleSaveClientSuccessCallback && handleSaveClientSuccessCallback(response);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'postSaveClientFailed', payload: error });
        let message = 'Client Save is Failed !';
        if (error?.response?.status === 500) {
          message = error?.response?.data?.message || 'Please Check Your Network Connection !';
        }
        Store.dispatch(handleNotification(message, 'error'));
        handleSaveClientFailedCallback && handleSaveClientFailedCallback(error);
      };
      http_request(requestData, successCallback, errorCallback);
    };
/**
 * Request Client List
 * @param {Function} handleClientListSuccessCallback
 * @param {Function} handleClientListFailedCallback
 * @returns
 */
export const requestClientList =
  (handleClientListSuccessCallback, handleClientListFailedCallback) =>
    async (dispatch, getState) => {
      dispatch({ type: 'getAllClientsRequest' });

      const requestData = {
        url: API_URL.client.clientList.GET_ALL_CLIENTS,
        method: 'GET',
      };
      const successCallback = (response) => {
        dispatch({ type: 'getAllClientsSuccess', payload: response.data });

        handleClientListSuccessCallback && handleClientListSuccessCallback(response.data);
      };
      const errorCallback = (error) => {
        dispatch({ type: 'getAllClientsFailed', payload: error });

        console.log(error);
        handleClientListFailedCallback && handleClientListFailedCallback();
      };
      http_request(requestData, successCallback, errorCallback);
    };

/**
 * Handle Request Find Client Detail by Id
 * @param {object} requestBody
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns void
 */
export const requestClientDetail =
  (requestBody, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) =>
    async (dispatch, getState) => {
      dispatch({ type: 'getClientByIdRequest' });

      const requestData = {
        url: API_URL.client.clientProfile.GET_FIND_CLIENT_BY_CLIENT_ID.replace(
          '{clientId}',
          requestBody?.clientId
        ),
        method: 'GET',
      };
      const successCallback = (response) => {
        handleRequestSuccessCallback && handleRequestSuccessCallback(response);
        dispatch({ type: 'getClientByIdRequestSuccess', payload: response.data });
      };
      const errorCallback = (error) => {
        handleRequestFailedCallback && handleRequestFailedCallback(error);
        dispatch({ type: 'getClientByIdRequestFailed', payload: error });
      };
      http_request(requestData, successCallback, errorCallback);
    };

/**
 * Update Patient Profile Detail
 * @param {object} clientUpdaterRequestData {requestBody, clientDetail}
 * @param {Function} handleRequestSuccessCallback
 * @param {Function} handleRequestFailedCallback
 * @returns
 */
export const requestUpdateClient =
  (
    clientUpdaterRequestData,
    handleRequestSuccessCallback = null,
    handleRequestFailedCallback = null
  ) =>
    async (dispatch, getState) => {
      dispatch({ type: 'patchUpdateClientRequest' });
      let { requestBody, clientDetail } = clientUpdaterRequestData;
      let clientId = clientDetail?.clientId;
      const requestData = {
        url: API_URL.client.clientProfile.PATCH_UPDATE_CLIENT_PROFILE_BY_ID.replace(
          '{clientId}',
          clientId
        ),
        method: 'PATCH',
        data: requestBody,
      };
      const successCallback = (response) => {
        dispatch({
          type: 'patchUpdateClientRequestSuccess',
          payload: response.data,
        });
        const successMessage =
          response.data?.message || `Client ${response.data?.firstName} is Updated Successfully !`;
        Store.dispatch(handleNotification(successMessage, 'success'));
        handleRequestSuccessCallback && handleRequestSuccessCallback();
      };
      const errorCallback = (error) => {
        dispatch({ type: 'patchUpdateClientRequestFailed', payload: error });
        let message = 'Client Update is Failed !';
        if (error?.response?.status === 500) {
          message = error?.response?.data?.message || 'Please Check Your Network Connection !';
        }
        Store.dispatch(handleNotification(message, 'error'));
        console.log(error);
        handleRequestFailedCallback && handleRequestFailedCallback();
      };
      http_request(requestData, successCallback, errorCallback);
    };
