import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //send email
  isEmailPosting: false,
  emailPostSuccessData: {},
  emailPostFailedData: {},

  //get all emails sent
  isGetAllEmailsSentLoading: false,
  emailsSentByUserSuccessData: {},
  emailSentByUserFailedData: {},
};

/**
 * Client Invitation reducer
 */
export const emailPostsReducer = createReducer(initialState, {
  // email posting request reducer
  postEmailRequest: (state) => {
    state.isEmailPosting = true;
    state.emailPostSuccessData = {};
    state.emailPostFailedData = {};
  },
  postEmailRequestSuccess: (state, action) => {
    state.isEmailPosting = false;
    state.emailPostSuccessData = action.payload;
    state.emailPostFailedData = {};
  },
  postEmailRequestFailed: (state, action) => {
    state.isEmailPosting = false;
    state.emailPostSuccessData = {};
    state.emailPostFailedData = action.payload;
  },

  //get all emails sent by user
  getAllEmailsSentRequest: (state) => {
    state.isGetAllEmailsSentLoading = true;
    state.emailsSentByUserSuccessData = {};
    state.emailSentByUserFailedData = {};
  },
  getAllEmailsSentRequestSuccess: (state, action) => {
    state.isGetAllEmailsSentLoading = false;
    state.emailsSentByUserSuccessData = action.payload;
    state.emailSentByUserFailedData = {};
  },
  getAllEmailsSentRequestFailed: (state, action) => {
    state.isGetAllEmailsSentLoading = false;
    state.emailsSentByUserSuccessData = {};
    state.emailSentByUserFailedData = action.payload;
  },
});
